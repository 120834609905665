import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { translate } from 'react-admin';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import { fade } from '@material-ui/core/styles/colorManipulator';
import withStyles from '@material-ui/core/styles/withStyles';
import compose from 'recompose/compose';
import { FirestoreDateField } from '../../fields';


const style = theme => ({
    listItem: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    successButton: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.green.medium,
        '&:hover': {
            backgroundColor: fade(theme.palette.green.dark, 0.99),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
    dangerButton: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            backgroundColor: fade(theme.palette.error.dark, 0.99),
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
    warningButton: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.orange.light,
        '&:hover': {
            backgroundColor: fade(theme.palette.orange.dark, 0.99),
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
    ctaButton: {
        minWidth: '200px',
    },
});

export const CtaButtons = (props) => {
    const {
        classes, lastRetrievalDate, sendRetrievalMessage, pickUp, cancel, onHold, translate,
    } = props;
    return (
        <List>
            <ListItem className={classes.listItem}>
                <Tooltip title={translate('resources.messages.data.lastRetrievalSent')} placement="top">
                    <ListItemText>
                        <FirestoreDateField
                            record={{ lastSent: lastRetrievalDate }}
                            source="lastSent"
                            locales="nb-NO"
                        />
                    </ListItemText>
                </Tooltip>
                <Button
                    variant="contained"
                    className={classnames(classes.successButton, classes.ctaButton)}
                    onClick={sendRetrievalMessage}
                >
                    {translate('resources.orders.actions.send_retrieval_msg')}
                </Button>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Button
                    variant="contained"
                    className={classnames(classes.successButton, classes.ctaButton)}
                    onClick={pickUp}
                >
                    {translate('resources.orders.actions.picked_up')}
                </Button>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Button
                    variant="contained"
                    className={classnames(classes.warningButton, classes.ctaButton)}
                    onClick={onHold}
                >
                    {translate('resources.orders.actions.on_hold')}
                </Button>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Button
                    variant="contained"
                    className={classnames(classes.dangerButton, classes.ctaButton)}
                    onClick={cancel}
                >
                    {translate('resources.orders.actions.cancel_order')}
                </Button>
            </ListItem>
        </List>
    );
};

CtaButtons.propTypes = {
    classes: PropTypes.object,
    lastRetrievalDate: PropTypes.object,
    sendRetrievalMessage: PropTypes.func,
    pickUp: PropTypes.func,
    cancel: PropTypes.func,
    onHold: PropTypes.func,
    translate: PropTypes.func,
};

const enhance = compose(
    withStyles(style),
    translate,
);

export default enhance(CtaButtons);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, propTypes, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { codeVerifyStart, passwordChangeStart } from './newPasswordActions';
import { formTextInput, SubmitButton } from '../input';
import { getIsLoading } from '../../store/selectors';
import {
    getEmailAddress, getErrorMessage, getIsCodeVerified, getIsPasswordChanged,
} from './newPasswordSelectors';
import { ROUTES } from '../../framework/routes';


const styles = () => ({
    input: {
        marginTop: '1em',
        marginBottom: '1em',
    },
});

const LoginLink = ({ classes, translate }) => (
    <CardActions style={{ justifyContent: 'center' }}>
        <Link to={{ pathname: ROUTES.LOGIN }} className={classes.link}>
            {translate('resources.auth.actions.signIn')}
        </Link>
    </CardActions>
);

const newPassword = (auth, dispatch, { redirectTo }) => dispatch(passwordChangeStart(auth, redirectTo));

class NewPasswordForm extends Component {
    componentDidMount() {
        this.props.codeVerifyStart(this.props.actionCode);
    }

    renderInvalidUrlMessage = () => (<div>Invalid url, please use the link provided in the email.</div>)

    renderSuccessMessage = () => {
        const { classes, translate } = this.props;
        return (
            <div>
                <div>{translate('resources.auth.notification.new_password')}</div>
                <LoginLink {...{ classes, translate }} />
            </div>
        );
    }

    renderPasswordChangeForm = () => {
        const {
            classes, isLoading, handleSubmit, translate, emailAddress,
        } = this.props;
        return (
            <form onSubmit={handleSubmit(newPassword)}>
                <Typography variant="title" gutterBottom>
                    {translate('resources.auth.data.reset_password')}
                </Typography>
                <div>{emailAddress}</div>
                <div className={classes.input}>
                    <Field
                        id="password"
                        name="password"
                        component={formTextInput}
                        label={translate('ra.auth.password')}
                        type="password"
                        disabled={isLoading}
                    />
                </div>
                <SubmitButton {...{ isLoading, classes }} title={translate('resources.auth.actions.change_password')} />
            </form>
        );
    }

    render() {
        const {
            codeVerified, passwordChanged, verificationErrorMessage,
        } = this.props;

        if (!codeVerified && verificationErrorMessage) {
            return this.renderInvalidUrlMessage();
        }

        if (codeVerified && passwordChanged) {
            return this.renderSuccessMessage();
        }

        return this.renderPasswordChangeForm();
    }
}

NewPasswordForm.propTypes = {
    ...propTypes,
    redirectTo: PropTypes.string,
    codeVerifyStart: PropTypes.func,
    isLoading: PropTypes.bool,
    code: PropTypes.object,
    password: PropTypes.object,
};

const mapStateToProps = state => ({
    isLoading: getIsLoading(state),
    codeVerified: getIsCodeVerified(state),
    emailAddress: getEmailAddress(state),
    verificationErrorMessage: getErrorMessage(state),
    passwordChanged: getIsPasswordChanged(state),
});

const enhance = compose(
    withStyles(styles),
    translate,
    connect(mapStateToProps, { codeVerifyStart }),
    reduxForm({
        form: 'newPassword',
        validate: (values, props) => {
            const errors = {};
            const { translate } = props;

            if (!values.password) {
                errors.password = translate('ra.validation.required');
            }

            return errors;
        },
    }),
);

export default enhance(NewPasswordForm);

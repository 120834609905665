export const TOGGLE_ARCHIVE = 'TOGGLE_ARCHIVE';

export const getIsArchiveEnabled = state => state.archive;

export const archive = (state = false, action) => {
    switch (action.type) {
    case 'TOGGLE_ARCHIVE':
        return !state;
    default:
        return state;
    }
};

import { RESOURCES } from 'omni-shared/resources';
import { buildResourceDataGetter } from '../../store/selectors/adminSelectors';

export const getCompanyId = state => state.company.id;

export const getCompanyName = (state) => {
    const companyId = getCompanyId(state);
    const getResourceData = buildResourceDataGetter(state);
    const resourceData = getResourceData(RESOURCES.COMPANIES, companyId);
    return resourceData.name;
};

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
    crudGetList as crudGetListAction, Responsive, Title, translate,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import compose from 'recompose/compose';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import get from 'lodash/get';
import identity from 'lodash/identity';
import sum from 'lodash/sum';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker, defaultInputRanges } from 'react-date-range';
import { nb } from 'react-date-range/dist/locale';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { endOfMonth, startOfMonth } from 'date-fns';
import { buildResourceDataGetter, getIsLoggedIn } from '../../store/selectors/adminSelectors';
import { getLastRefresh } from '../../store/selectors';

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
};


const optionsTemplate = {
    chart: {
        type: 'area',
    },
    title: {
        text: '',
    },
    xAxis: {
        title: {
            text: 'Time period',
        },
        // categories: ['02-15', '02-16', '02-17', '02-18', '02-19', '02-20', '02-21'],
        categories: [],
    },
    yAxis: {
        title: {
            text: 'Count',
        },
    },
    series: [
    ],
};

function jsonCopy(src) {
    return JSON.parse(JSON.stringify(src));
}

const INTERVALS = {
    DAILY: 'daily',
    MONTHLY: 'monthly',
};

const TYPES = {
    SMS: 'sms',
    EMAIL: 'email',
};

// TODO: wrong resources structure. Causes error when switching to a company that does not have any statistics -
// old resources are not overwritten and displayed (when logged in as admin)
class Dashboard extends Component {
    state = {
        startDate: startOfMonth(new Date()),
        endDate: endOfMonth(new Date()),
        selectedTab: 0,
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.lastRefresh !== this.props.lastRefresh) {
            this.fetchData();
        }
    }

    componentDidMount() {
        // TODO: framework is trying to load the dashboard when user isnt authenticated, causing permissions error
        const { crudGetListAction, loggedIn } = this.props;
        if (!loggedIn) return;
        this.fetchData();
    }

    fetchData() {
        const { crudGetListAction } = this.props;

        crudGetListAction('counters/email/daily');
        crudGetListAction('counters/email/monthly');
        crudGetListAction('counters/sms/daily');
        crudGetListAction('counters/sms/monthly');
    }

    getCategoryData = coll => cat => (coll[cat] ? get(coll, `${cat}.data`) : 0)

    constructOptions = (emailData, smsData, intervalType, customCategories = null) => {
        const options = jsonCopy(optionsTemplate);
        const categories = customCategories || [...new Set([...Object.keys(emailData), ...Object.keys(smsData)])];
        options.xAxis.categories = categories;
        options.title.text = startCase(intervalType);


        const smsCats = categories.map(this.getCategoryData(smsData));
        const smsForPeriod = sum(smsCats);

        options.series.push({
            name: `${startCase(TYPES.SMS)} (${smsForPeriod})`,
            data: smsCats,
        });

        const emailCats = categories.map(this.getCategoryData(emailData));
        const emailForPeriod = sum(emailCats);

        options.series.push({
            name: `${startCase(TYPES.EMAIL)} (${emailForPeriod})`,
            data: emailCats,
        });
        return options;
    }

    enumerateDates = (start, end, converter = identity) => {
        const dates = [];

        const currDate = moment(start).startOf('day');
        const lastDate = moment(end).startOf('day');

        do {
            dates.push(converter(currDate.clone().toDate()));
        } while (currDate.add(1, 'days').diff(lastDate) <= 0);

        return dates;
    }

    handleSelect = ({ startDate, endDate }) => {
        this.setState({ startDate, endDate });
    }

    handleTabChange = (event, value) => {
        this.setState({ selectedTab: value });
    };

    render() {
        const {
            translate, emailsDaily, emailsMonthly, smsDaily, smsMonthly,
        } = this.props;
        const { startDate, endDate, selectedTab } = this.state;
        const renderDaily = (isEmpty(emailsDaily) && isEmpty(smsDaily)) === false;
        const renderMonthly = (isEmpty(emailsMonthly) && isEmpty(smsMonthly)) === false;

        const categories = this.enumerateDates(
            startDate,
            endDate,
            date => moment(date).format('YYYY-MM-DD'),
        );

        const daily = this.constructOptions(emailsDaily, smsDaily, INTERVALS.DAILY, categories);
        const monthly = this.constructOptions(emailsMonthly, smsMonthly, INTERVALS.MONTHLY);

        return (
            <Fragment>
                <Card>
                    <Title title="Dashboard" />
                    <CardHeader title={translate('resources.dashboard.welcome')} />
                    <CardContent>{translate('resources.dashboard.description')}</CardContent>
                </Card>
                <Responsive
                    medium={(
                        <div style={styles.flex}>
                            <div style={styles.leftCol}>
                                <Card style={styles.singleCol}>
                                    <CardHeader title="Counters" />
                                    <Tabs
                                        value={selectedTab}
                                        onChange={this.handleTabChange}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        scrollable
                                        scrollButtons="auto"
                                    >
                                        <Tab label="Daily" />
                                        <Tab label="Monthly" />
                                    </Tabs>
                                    <CardContent>
                                        {
                                            selectedTab === 0
                                            && (
                                                <Daily
                                                    daily={daily}
                                                    handleSelect={this.handleSelect}
                                                />
                                            )
                                        }
                                        {
                                            selectedTab === 1
                                            && (
                                                <HighchartsReact
                                                    highcharts={Highcharts}
                                                    options={monthly}
                                                />
                                            )}
                                    </CardContent>
                                </Card>
                            </div>
                            <div style={styles.rightCol} />
                        </div>
                    )}
                />
            </Fragment>
        );
    }
}

class Daily extends Component {
    state = {
        selectionRange: {
            startDate: startOfMonth(new Date()),
            endDate: endOfMonth(new Date()),
            key: 'selection',
        },
    };

    onChange = (ranges) => {
        this.setState({ selectionRange: ranges.selection });
        this.props.handleSelect(ranges.selection);
    }

    render() {
        const { daily } = this.props;
        return (
            <Fragment>
                <ExpansionPanel style={{ marginBottom: '15px' }}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>
                            Select Dates
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <DateRangePicker
                            ranges={[this.state.selectionRange]}
                            onChange={this.onChange}
                            maxDate={new Date()}
                            locale={nb}
                            inputRanges={[defaultInputRanges[0]]}
                            months={2}
                            direction="horizontal"
                            showDateDisplay={false}
                        />

                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={daily}
                />
            </Fragment>
        );
    }
}

const mapDispatchToProps = {
    crudGetListAction,
};

const mapStateToProps = (state) => {
    const getStateProp = buildResourceDataGetter(state);
    return {
        emailsDaily: getStateProp('counters/email/daily'),
        smsDaily: getStateProp('counters/sms/daily'),
        emailsMonthly: getStateProp('counters/email/monthly'),
        smsMonthly: getStateProp('counters/sms/monthly'),
        loggedIn: getIsLoggedIn(state),
        lastRefresh: getLastRefresh(state),
    };
};

const enhance = compose(
    translate,
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);

export default enhance(Dashboard);

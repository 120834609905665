const configs = {
    development: {
        apiKey: 'AIzaSyAksFEXMPbCgKrpo5M7HBv5ihyxLWLhAI8',
        authDomain: 'omnicloud-dev.firebaseapp.com',
        databaseURL: 'https://omnicloud-dev.firebaseio.com',
        projectId: 'omnicloud-dev',
        storageBucket: 'omnicloud-dev.appspot.com',
        messagingSenderId: '870427323677',
    },
    production: {
        apiKey: 'AIzaSyAnMTNWNUSGcG5H3Muh4p2kiSQxopBTZb0',
        authDomain: 'omnicloud-prod.firebaseapp.com',
        databaseURL: 'https://omnicloud-prod.firebaseio.com',
        projectId: 'omnicloud-prod',
        storageBucket: 'omnicloud-prod.appspot.com',
        messagingSenderId: '656898023708',
    },
};


function withConfig(init) {
    const appEnv = process.env.REACT_APP_ENV;
    const config = configs[appEnv];
    if (!config) {
        throw new Error(`Config for environment '${appEnv}' is not defined`);
    }
    return init(config);
}

export default withConfig;

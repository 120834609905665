import { combineReducers } from 'redux';
import { EMAIL_VERIFY_FAILURE, EMAIL_VERIFY_SUCCESS } from './emailVerifyActions';

const verified = (state = false, action) => {
    switch (action.type) {
    case EMAIL_VERIFY_SUCCESS:
        return true;
    case EMAIL_VERIFY_FAILURE:
        return false;
    default:
        return state;
    }
};

const error = (state = false, action) => {
    switch (action.type) {
    case EMAIL_VERIFY_FAILURE:
        return true;
    case EMAIL_VERIFY_SUCCESS:
        return null;
    default:
        return state;
    }
};

export default combineReducers({
    verified,
    error,
});

import { EditButton, List, translate } from 'react-admin';
import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import get from 'lodash/get';

const cardStyle = {
    width: 300,
    minHeight: 100,
    margin: '0.5em',
    display: 'inline-block',
    verticalAlign: 'top',
};
const SettingsCards = translate(({
    ids, data, basePath, translate, features,
}) => (
    <div style={{ margin: '1em' }}>
        {ids
            .filter(id => typeof id === 'string' && get(features, id))
            .map(id => (
                <Card key={id} style={cardStyle}>
                    <CardHeader
                        title={translate(`resources.settings.data.${id}`)}
                    />
                    <CardActions style={{ textAlign: 'right' }}>
                        <EditButton resource="settings" basePath={basePath} record={data[id]} />
                    </CardActions>
                </Card>
            ))}
    </div>
));

SettingsCards.propTypes = {
    translate: PropTypes.func,
};

SettingsCards.defaultProps = {
    data: {},
    ids: [],
};

const SettingsList = ({
    translate, features, dispatch, ...props
}) => (
    <List
        title={translate('resources.settings.name')}
        {...props}
        sort={{ field: 'name', order: 'ASC' }}
        exporter={false}
        pagination={null}
    >
        <SettingsCards features={features} />
    </List>
);

SettingsList.propTypes = {
    translate: PropTypes.func,
};

const mapStateToProps = state => ({
    features: state.permissions.features,
});

const enhance = compose(
    translate,
    connect(mapStateToProps),
);

export default enhance(SettingsList);

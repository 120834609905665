import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import { OMNI_ORDER_STATUSES } from 'omni-shared/constants';

const rowStyle = (record, index, defaultStyle = {}) => {
    const sharedStyles = {
        ...defaultStyle,
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    };
    if (record.status === OMNI_ORDER_STATUSES.NEW) {
        return {
            ...sharedStyles,
            borderLeftColor: red[500],

        };
    }
    if (record.status === OMNI_ORDER_STATUSES.LOCKED) {
        return {
            ...sharedStyles,
            borderLeftColor: blue[500],
        };
    }
    if (record.status === OMNI_ORDER_STATUSES.ON_HOLD) {
        return {
            ...sharedStyles,
            borderLeftColor: grey[500],
        };
    }
    if (record.status === OMNI_ORDER_STATUSES.CONFIRMED) {
        return {
            ...sharedStyles,
            borderLeftColor: green[500],
        };
    }
    return defaultStyle;
};

export default rowStyle;

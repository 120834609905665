import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    translate,
    crudGetOne as crudGetOneAction,
    crudGetManyReference as crudGetManyReferenceAction,
    nameRelatedTo,
} from 'react-admin';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import { RESOURCES } from 'omni-shared/resources';

import { submit } from 'redux-form';
import PropTypes from 'prop-types';
import { UppercaseHeadline } from '../../orders/utilities';
import Items from './Items';
import Customer from './Customer';
import OrderDetails from './OrderDetails';
import { getExchangeOrder, getExchangeOrderItems } from './ordersSelector';
import { registerItem } from '../exchangeActions';
import RegisterHistory from './RegisterHistory';
import { getLastRefresh } from '../../../store/selectors';
import OrderAmounts from './OrderAmounts';
import CardTitle from './CardTitle';
import Dialog from './Dialog';

const editStyles = {
    flex: { display: 'flex' },
    column: { flexDirection: 'column' },
    right: { alignItems: 'flex-end' },
    end: { justifyContent: 'flex-end' },
    card: { marginBottom: '2em' },
    cardActions: {
        justifyContent: 'space-between',
        padding: '8px 24px',
    },
    historyContainer: { maxHeight: '500px', overflowY: 'auto' },
    noWrap: { whiteSpace: 'noWrap' },
    exchangeValid: {
        color: 'white',
        backgroundColor: '#2ECC40',
        padding: '10px',
        marginLeft: '1rem',
        borderRadius: '6px',
    },
    exchangeInValid: {
        color: 'white',
        backgroundColor: '#FF4136',
        padding: '10px',
        marginLeft: '1rem',
        borderRadius: '6px',
    },
    mt5: {
        marginTop: '5px',
    },
    mt10: {
        marginTop: '10px',
    },
    mt20: {
        marginTop: '20px',
    },
    paddedLine: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    rowCell: {
        padding: '5px 12px',
        '&:last-child': {
            padding: '5px 12px',
        },
    },
};


const FORM_NAME = 'exchange-dialog-form';

class Order extends Component {
    state = {
        error: false,
        showDialog: false,
        sku: null,
        lastComponentRefresh: new Date(),
    };

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        const refreshButtonClicked = prevProps.lastRefresh !== this.props.lastRefresh;
        const refreshIntervalExpired = prevState.lastComponentRefresh - this.state.lastComponentRefresh !== 0;

        if (refreshButtonClicked || refreshIntervalExpired) {
            this.fetchData();
        }
    }

    fetchData = () => {
        const { exchangeId, crudGetOneAction, crudGetManyReferenceAction } = this.props;

        crudGetOneAction(RESOURCES.EXCHANGE, exchangeId);
        crudGetManyReferenceAction(
            RESOURCES.EXCHANGE_ORDERS_ITEMS,
            'relatedTo',
            exchangeId,
            nameRelatedTo(RESOURCES.EXCHANGE_ORDERS_ITEMS, exchangeId, RESOURCES.EXCHANGE, 'relatedTo'),
        );
    }

    handleRegisterClick = (sku) => {
        this.setState({ showDialog: true, sku });
    }

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleSaveClick = () => {
        const { submit } = this.props;

        // Trigger a submit of our custom quick create form
        // This is needed because our modal action buttons are oustide the form
        submit(FORM_NAME);
    };

    handleSubmit = (values) => {
        const { handleCloseClick } = this;
        const { registerItem, exchangeId } = this.props;
        const { sku } = this.state;
        registerItem({
            data: { sku, ...values },
            parentId: exchangeId,
            successCallback: handleCloseClick,
        });
    }

    render() {
        const {
            classes, translate, exchange, exchangeItems,
        } = this.props;
        const { showDialog } = this.state;
        if (!exchange || !exchange.increment_id) return translate('utilities.loading');
        return (
            <Card className={classes.card}>
                <CardHeader
                    title={(<CardTitle exchange={exchange} translate={translate} classes={classes} />)}
                />
                <CardContent>
                    <OrderDetails exchange={exchange} translate={translate} classes={classes} />
                    <Customer exchange={exchange} translate={translate} classes={classes} />
                    <Items
                        items={exchange.items}
                        itemsDb={exchangeItems}
                        registerClick={this.handleRegisterClick}
                        translate={translate}
                        classes={classes}
                    />
                    <OrderAmounts classes={classes} translate={translate} exchange={exchange} />
                    <div className={classes.mt20}>
                        <UppercaseHeadline
                            text={translate('resources.exchange.tabs.history')}
                        />
                        <RegisterHistory exchangeId={exchange.increment_id} translate={translate} />
                    </div>
                </CardContent>
                <Dialog
                    handleCloseClick={this.handleCloseClick}
                    handleSaveClick={this.handleSaveClick}
                    handleSubmit={this.handleSubmit}
                    formName={FORM_NAME}
                    title={translate('resources.exchange.tabs.registerItem')}
                    isOpen={showDialog}
                    translate={translate}
                />
            </Card>
        );
    }
}

Order.propTypes = {
    translate: PropTypes.func,
    classes: PropTypes.object,
    crudGetOneAction: PropTypes.func,
    crudGetManyReferenceAction: PropTypes.func,
    submit: PropTypes.func,
    registerItem: PropTypes.func,
    exchange: PropTypes.object,
    exchangeItems: PropTypes.array,
    exchangeId: PropTypes.node,
};

const mapStateToProps = (state, route) => ({
    exchangeId: route.id,
    exchange: getExchangeOrder(state, route.id),
    exchangeItems: getExchangeOrderItems(state, route.id),
    lastRefresh: getLastRefresh(state),
});

const enhance = compose(
    translate,
    withStyles(editStyles),
    connect(mapStateToProps, {
        crudGetOneAction, crudGetManyReferenceAction, submit, registerItem,
    }),
);

export default enhance(Order);

import { put, takeEvery } from 'redux-saga/effects';
import { USER_LOGOUT } from 'react-admin';
import { renderApp } from '../../store/actions/uiActions';

export function* handleLogout() {
    try {
    /*
        This helps to handle the case where unauthenticated user tries to access inner app pages:
        authProvider's authGetPermissions rejects promise and USER_LOGOUT fires resetting the app state to default
        (where renderApp = false) and AuthWrapper shows loading screen.
        */
        yield put(renderApp());
    } catch (e) {
        console.log(e);
    }
}

export default function* () {
    yield takeEvery(USER_LOGOUT, handleLogout);
}

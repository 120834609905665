import { combineReducers } from 'redux';
import * as actions from './newPasswordActions';

const initialState = {
    verified: false,
    emailAddress: null,
    errorMessage: null,
};

const code = (state = initialState, { type, payload }) => {
    switch (type) {
    case actions.CODE_VERIFY_SUCCESS:
        return { verified: true, errorMessage: null, emailAddress: payload };
    case actions.CODE_VERIFY_FAILURE:
        return { ...state, verified: false, errorMessage: payload };
    default:
        return state;
    }
};


const password = (state = { changed: false }, { type, payload }) => {
    switch (type) {
    case actions.PASSWORD_CHANGE_SUCCESS:
        return { changed: true };
    default:
        return state;
    }
};

export default combineReducers({ code, password });

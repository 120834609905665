import React, { Fragment } from 'react';
import { crudGetOne as crudGetOneAction, translate } from 'react-admin';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import CircularProgress from '@material-ui/core/CircularProgress';
import { submit } from 'redux-form';
import withStyles from '@material-ui/core/styles/withStyles';
import OrderItems from './OrderItems';
import Buttons from './Buttons';
import { omniOrderConfirm, omniOrderRestart, omniOrderToggleLock } from '../omniOrdersActions';
import { arePermissionsElevated } from '../../../utils/authorization';
import { getIsLoading, getPermissions } from '../../../store/selectors';
import OwnerButtons from './OwnerButtons';
import { getCompanyId } from '../../../components/companies';
import Dialog from './ShipmentSubmitDialog';

const styles = {
    buttonsWrapper: {
        display: 'flex', alignItems: 'center', justifyContent: 'flex-end', margin: '5px',
    },
    spinnerWrapper: {
        width: '30px', marginLeft: '10px',
    },
};

class OrderView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checkboxesChecked: false,
            isLoading: false,
            dialogOpen: false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            (prevProps.isLoading === true && prevState.isLoading === true && this.props.isLoading === false)
        ) {
            this.setState({ isLoading: false });
        }
    }

    lockOrder = () => {
        const { record, omniOrderToggleLock } = this.props;

        omniOrderToggleLock({ id: record.id, firestoreDocId: record.firestoreDocId });
        this.setState({ isLoading: true });
    }

    restartOrder = () => {
        const { record, omniOrderRestart } = this.props;

        omniOrderRestart({ id: record.id, firestoreDocId: record.firestoreDocId });
        this.setState({ isLoading: true });
    }

    confirmOrder = (values) => {
        const { record, omniOrderConfirm } = this.props;
        const { checkboxesChecked } = this.state;

        const itemIds = checkboxesChecked
            ? record.items.map(({ itemId }) => itemId)
            : record.items
                .filter(({ availableInWarehouse }) => availableInWarehouse === false)
                .map(({ itemId }) => itemId);

        omniOrderConfirm({
            id: record.id,
            firestoreDocId: record.firestoreDocId,
            itemIds,
            ...values,
        });
        this.closeDialog();
        this.setState({ isLoading: true });
    }

    toggleCheckboxes = (event, value) => this.setState(() => ({ checkboxesChecked: value }))

    openDialog = () => this.setState(() => ({ dialogOpen: true }))

    closeDialog = () => this.setState(() => ({ dialogOpen: false }))

    renderOrderItems = () => {
        const { checkboxesChecked, isLoading } = this.state;
        const { items } = this.props.record;

        if (items) {
            return (
                <OrderItems
                    {...this.props}
                    record={this.props.record}
                    onChange={this.toggleCheckboxes}
                    checked={checkboxesChecked}
                    isLoading={isLoading}
                />
            );
        }
        return null;
    }

    renderButtons = () => {
        const { isLoading } = this.state;
        const {
            translate, record, arePermissionsElevated, classes,
        } = this.props;

        return (
            <div className={classes.buttonsWrapper}>
                <div className={classes.spinnerWrapper}>
                    {isLoading ? <CircularProgress size={25} /> : null}
                </div>
                {
                    arePermissionsElevated

                        ? (
                            <OwnerButtons
                                record={record}
                                translate={translate}
                                buttonsDisabled={isLoading}
                                onRestartOrder={this.restartOrder}
                            />
                        )

                        : (
                            <Buttons
                                record={record}
                                onLockOrder={this.lockOrder}
                                onConfirmOrder={this.openDialog}
                                buttonsDisabled={isLoading}
                                translate={translate}
                            />
                        )
                }
            </div>
        );
    }

    renderDialog = () => (
        <Dialog
            handleCloseClick={this.closeDialog}
            handleSaveClick={this.handleSaveClick}
            handleSubmit={this.confirmOrder}
            formName={`shipment-submit-dialog-form${this.props.record.orderId}`}
            title={this.props.translate('resources.omni_orders.data.confirm')}
            isOpen={this.state.dialogOpen}
            translate={this.props.translate}
        />
    )

    handleSaveClick = () => {
        const { submit } = this.props;

        submit(`shipment-submit-dialog-form${this.props.record.orderId}`);
    };

    render() {
        return (
            <Fragment>
                {this.renderOrderItems()}
                {this.renderButtons()}
                {this.renderDialog()}
            </Fragment>
        );
    }
}

OrderView.propTypes = {
    translate: PropTypes.func,
    record: PropTypes.object,
    crudGetOneAction: PropTypes.func,
    omniOrderToggleLock: PropTypes.func,
    omniOrderConfirm: PropTypes.func,
    omniOrderRestart: PropTypes.func,
    submit: PropTypes.func,
    arePermissionsElevated: PropTypes.bool,
    companyId: PropTypes.node,
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    arePermissionsElevated: arePermissionsElevated(getPermissions(state)),
    isLoading: getIsLoading(state),
    companyId: getCompanyId(state),
});

const enhance = compose(
    withStyles(styles),
    translate,
    connect(mapStateToProps, {
        crudGetOneAction,
        omniOrderToggleLock,
        omniOrderConfirm,
        omniOrderRestart,
        submit,
    }),
);

export default enhance(OrderView);

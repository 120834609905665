import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import _get from 'lodash/get';


const attribute = (name, attr) => attr && (
    <div>
        {[name, ': ', attr].join('')}
    </div>
);

const newAttributtes = rec => (
    <Fragment>
        {rec.customAttributes.map((attr = {}) => (
            <div>
                <Typography>{[attr.label, attr.value].join(': ')}</Typography>
            </div>
        ))}
    </Fragment>
);


const oldAttributes = rec => (
    <div>
        {attribute('Farge', _get(rec.customAttributes, 'color'))}
        {attribute('Størrelse', _get(rec.customAttributes, 'size'))}
    </div>
);

export const OrderItemAttributesField = ({
    record = {},
}) => (Array.isArray(record.customAttributes) ? newAttributtes(record) : oldAttributes(record));

import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { List, Responsive, translate } from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import exporter from './exporter';
import { ListActions } from '../../listActions';
import ArchiveSwitch from './ArchiveSwitch';
import { CommandFilter } from './Filters';
import { gridLarge } from './gridLarge';
import { gridMedium } from './gridMedium';

const styles = {
    truncateText100: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '100px',
    },
    alignActions: {
        alignItems: 'center',
    },
};


const OrdersList = ({
    refreshView, dispatch, archiveEnabled, ...props
}) => {
    const { translate, classes } = props;
    return (
        <Responsive
            medium={(
                <List
                    {...props}
                    title={translate('resources.orders.name', { smart_count: 2 })}
                    sort={{ field: 'createdAt', order: 'ASC' }}
                    filters={<CommandFilter translate={translate} archiveEnabled={archiveEnabled} />}
                    actions={<ListActions className={classes.alignActions} CustomActionComponent={ArchiveSwitch} />}
                    exporter={exporter(translate)}
                >
                    {gridMedium(translate, classes)}
                </List>
            )}
            large={(
                <List
                    {...props}
                    title={translate('resources.orders.name', { smart_count: 2 })}
                    sort={{ field: 'createdAt', order: 'ASC' }}
                    filters={<CommandFilter translate={translate} archiveEnabled={archiveEnabled} />}
                    actions={<ListActions className={classes.alignActions} CustomActionComponent={ArchiveSwitch} />}
                    exporter={exporter(translate)}
                >
                    {gridLarge(translate)}
                </List>
            )}
        />

    );
};

OrdersList.propTypes = {
    translate: PropTypes.func,
    classes: PropTypes.object,
    refreshView: PropTypes.func,
    dispatch: PropTypes.func,
    archiveEnabled: PropTypes.bool,
};

const mapStateToProps = state => ({
    archiveEnabled: state.archive,
});


const enhance = compose(
    translate,
    withStyles(styles),
    connect(mapStateToProps),
);

export default enhance(OrdersList);

import React from 'react';
import TextField from '@material-ui/core/TextField';
// see http://redux-form.com/6.4.3/examples/material-ui/


const formTextInput = ({
    meta: { touched, pristine, error } = {}, // eslint-disable-line react/prop-types
    input: { ...inputProps }, // eslint-disable-line react/prop-types
    autoFocus,
    ...props
}) => (
    <TextField
        error={!!(touched && !pristine && error)}
        helperText={touched && !pristine && error}
        {...inputProps}
        {...props}
        fullWidth
        autoFocus={autoFocus}
    />
);
export default formTextInput;

import PropTypes from 'prop-types';
import React from 'react';
import { getFormattedTimePart } from '../../../utils/functions';


const getCountdownRenderer = completedFieldValue => ({
    minutes, seconds, completed,
}) => {
    if (completed) {
        return completedFieldValue;
    }

    return (
        <span>
            {getFormattedTimePart(minutes)}
            :
            {getFormattedTimePart(seconds)}
        </span>
    );
};

getCountdownRenderer.propTypes = {
    minutes: PropTypes.string,
    seconds: PropTypes.string,
    completed: PropTypes.bool,
    completedFieldValue: PropTypes.node,
};

export default getCountdownRenderer;

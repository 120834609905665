import { STATUSES } from 'omni-shared/constants';
import { Filter, SearchInput, SelectInput } from 'react-admin';
import PropTypes from 'prop-types';
import React from 'react';

export const CommandFilter = ({
    translate, archiveEnabled, ...props
}) => {
    const archiveFilters = [
        { id: STATUSES.CANCELLED, name: translate('resources.orders.data.cancelled') },
        { id: STATUSES.PICKEDUP, name: translate('resources.orders.data.pickedup') },
    ];
    const activeFilters = [
        { id: STATUSES.NEW, name: translate('resources.orders.data.new') },
        { id: STATUSES.PICKING, name: translate('resources.orders.data.picking') },
        { id: STATUSES.DELIVERED_PARTLY, name: translate('resources.orders.data.deliveredPartly') },
        { id: STATUSES.WAITING_PICKUP, name: translate('resources.orders.data.waitingForPickUp') },
        { id: STATUSES.ON_HOLD, name: translate('resources.orders.data.onHold') },
    ];

    return (
        <Filter {...props}>
            <SearchInput label={translate('resources.orders.fields.phone')} source="customer.mobile" />
            <SearchInput label={translate('resources.orders.fields.email')} source="customer.email" />
            <SearchInput label={translate('resources.orders.fields.order_id')} source="externalOrderId" />
            <SelectInput
                label={translate('resources.orders.fields.status')}
                source="status"
                choices={archiveEnabled ? archiveFilters : activeFilters}
            />
        </Filter>
    );
};

CommandFilter.propTypes = {
    translate: PropTypes.func,
    archiveEnabled: PropTypes.bool,
};

import {
    email,
    translate,
    BooleanInput,
    Edit,
    TabbedForm,
    FormDataConsumer,
    FormTab,
    DisabledInput,
    SelectInput,
    TextInput,
    FileInput,
    FileField,
} from 'react-admin';
import React, { Component } from 'react';
import compose from 'recompose/compose';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { getCompanyId } from '../companies';
import { getSettings } from '../../database/database';
import SearchResultRadio from './SearchResultRadio';
import SearchButton from './SearchButton';
import countries from './countries';
import { RichTextInput } from '../input';

// // form validation
const validateEmail = email();

const between = (bounds, message) => value => (value < bounds[0] || value > bounds[1] ? message : undefined);

const StoreTitle = ({ record, translate }) => (
    <span>
        {translate('utilities.edit')}
        {' '}
        {record ? record.name : ''}
    </span>
);

const dynamicValidator = (value, validationObj, pred = false) => {
    // required validation
    if (validationObj.required(pred) === true) {
        if (!value) {
            return 'Required'; // Error msg
        }
    }

    // number validation
    if (validationObj.type === 'number') {
        if (Number.isNaN(Number(value))) {
            return 'Must be a number'; // Error msg
        }
    }

    if (typeof validationObj.test === 'function') {
        const res = validationObj.test(value);
        if (res !== undefined) {
            return res; // Error msg
        }
    }
};

const validationObj = {
    name: {
        required: showOnMap => showOnMap,
    },
    lat: {
        required: showOnMap => showOnMap,
        type: 'number',
        test: value => between([-90, 90], 'Must be between -90 and 90')(value),
    },
    lng: {
        required: showOnMap => showOnMap,
        type: 'number',
        test: value => between([-180, 180], 'Must be between -180 and 180')(value),
    },
    email: {
        required: () => false,
        type: 'email',
        test: value => email()(value),
    },

};

class StoreEditFormMainTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showAddressOptions: false,
            types: [],
        };
        const { companyId, features } = this.props;

        if (features.storefinder) {
            getSettings(companyId, 'storefinder').then((data) => {
                if (data && data.storeTypes) {
                    this.setState({
                        types: data.storeTypes,
                        showAddressOptions: false,
                    });
                }
            });
        }
    }

    render() {
        const { translate } = this.props;
        let { showAddressOptions, types } = this.state;

        return (
            <Edit title={<StoreTitle translate={translate} />} {...this.props}>
                <TabbedForm>
                    <FormTab label={translate('resources.storefinder.tabs.store')}>
                        <BooleanInput
                            source="showOnMap"
                            label={translate('resources.storefinder.fields.showOnMap')}
                        />
                        <BooleanInput
                            source="isCacEnabled"
                            label={translate('resources.storefinder.fields.isCacEnabled')}
                        />
                        <BooleanInput
                            source="isStorepickupEnabled"
                            label={translate('resources.storefinder.fields.isStorepickupEnabled')}
                        />
                        <DisabledInput source="id" />
                        <TextInput
                            source="name"
                            validate={[
                                (value, allValues) => dynamicValidator(value, validationObj.name, allValues.showOnMap),
                            ]}
                            label={translate('resources.storefinder.fields.name')}
                            required
                        />
                        <SelectInput
                            source="country"
                            choices={countries}
                            label={translate('resources.storefinder.fields.country')}
                        />
                        {types.length > 0 && (
                            <SelectInput
                                source="type"
                                choices={types}
                                label={translate('resources.storefinder.fields.type')}
                            />
                        )}
                        <TextInput source="region" label={translate('resources.storefinder.fields.region')} />
                        <TextInput source="postcode" label={translate('resources.storefinder.fields.postcode')} />
                        <TextInput source="city" label={translate('resources.storefinder.fields.city')} />
                        <TextInput source="address" label={translate('resources.storefinder.fields.address')} />
                        <FormDataConsumer>
                            {({ formData, ...rest }) => (
                                <SearchButton
                                    formData={formData}
                                    updateParent={() => this.setState({ showAddressOptions: true })}
                                    label={translate('resources.storefinder.action.verify_address')}
                                />
                            )}
                        </FormDataConsumer>
                        {showAddressOptions && <SearchResultRadio />}
                        <TextInput
                            source="position.lat"
                            label={translate('resources.storefinder.fields.latitude')}
                            validate={[
                                (value, allValues) => dynamicValidator(value, validationObj.lat, allValues.showOnMap),
                            ]}
                            required
                        />
                        <TextInput
                            source="position.lng"
                            label={translate('resources.storefinder.fields.longitude')}
                            validate={[
                                (value, allValues) => dynamicValidator(value, validationObj.lng, allValues.showOnMap),
                            ]}
                            required
                        />
                        <TextInput source="phone" label={translate('resources.storefinder.fields.phone')} />
                        <TextInput source="phoneToDisplay" label={translate('resources.storefinder.fields.phoneToDisplay')} />
                        <TextInput
                            source="email"
                            validate={[
                                validateEmail,
                                value => dynamicValidator(value, validationObj.email),
                            ]}
                            label={translate('resources.storefinder.fields.email')}
                            required
                        />
                    </FormTab>
                    <FormTab label={translate('resources.storefinder.tabs.openingHours')}>
                        <TextInput
                            source="openingHours.monday"
                            label={translate('resources.storefinder.fields.openingHours.monday')}
                        />
                        <TextInput
                            source="openingHours.tuesday"
                            label={translate('resources.storefinder.fields.openingHours.tuesday')}
                        />
                        <TextInput
                            source="openingHours.wednesday"
                            label={translate('resources.storefinder.fields.openingHours.wednesday')}
                        />
                        <TextInput
                            source="openingHours.thursday"
                            label={translate('resources.storefinder.fields.openingHours.thursday')}
                        />
                        <TextInput
                            source="openingHours.friday"
                            label={translate('resources.storefinder.fields.openingHours.friday')}
                        />
                        <TextInput
                            source="openingHours.saturday"
                            label={translate('resources.storefinder.fields.openingHours.saturday')}
                        />
                        <TextInput
                            source="openingHours.sunday"
                            label={translate('resources.storefinder.fields.openingHours.sunday')}
                        />
                        <TextInput
                            source="openingHours.additionalInformation"
                            label={translate('resources.storefinder.fields.openingHours.additionalInformation')}
                        />
                        <RichTextInput
                            source="openingHours.richText"
                            label={translate('resources.storefinder.fields.richInfo')}
                        />
                    </FormTab>

                    <FormTab label={translate('resources.storefinder.tabs.misc')}>
                        <TextInput
                            source="organizationNumber"
                            label={translate('resources.storefinder.fields.organizationNumber')}
                        />
                        <TextInput source="externalId" label={translate('resources.storefinder.fields.externalId')} />
                        <TextInput source="printerId" label={translate('resources.storefinder.fields.printerId')} />
                        <TextInput
                            source="externalUrl"
                            label={translate('resources.storefinder.fields.externalUrl')}
                        />
                        <TextInput
                            source="googleInventoryAdsId"
                            label={translate('resources.storefinder.fields.googleInventoryAdsId')}
                        />
                        <FileInput
                            source="files"
                            label={translate('resources.storefinder.fields.image')}
                            accept={['image/png', 'image/jpeg']}
                            multiple={false}
                            maxSize={1000000}
                        >
                            <FileField source="src" title="title" target="_blank" />
                        </FileInput>
                    </FormTab>
                </TabbedForm>
            </Edit>
        );
    }
}

const mapStateToProps = state => ({
    features: state.permissions.features,
    companyId: getCompanyId(state),
});

const enhance = compose(
    translate,
    reduxForm({
        form: 'storeEdit',
    }),
    connect(mapStateToProps),
);

export default enhance(StoreEditFormMainTab);

export const CONFIRMATION_TYPES = {
    ALL: 'all',
    PARTIAL: 'partial',
};

export const OMNI_ORDER_TOGGLE_LOCK = 'OMNI_ORDER_TOGGLE_LOCK';
export const OMNI_ORDER_CONFIRM = 'OMNI_ORDER_CONFIRM';
export const OMNI_ORDER_RESTART = 'OMNI_ORDER_RESTART';

export const omniOrderToggleLock = ({ id, firestoreDocId }) => ({
    type: OMNI_ORDER_TOGGLE_LOCK,
    payload: { id, firestoreDocId },
});

export const omniOrderConfirm = ({
    id, firestoreDocId, itemIds, packagesQty, totalWeight,
}) => ({
    type: OMNI_ORDER_CONFIRM,
    payload: { id, firestoreDocId, itemIds, packagesQty, totalWeight },
});

export const omniOrderRestart = ({ id, firestoreDocId }) => ({
    type: OMNI_ORDER_RESTART,
    payload: { id, firestoreDocId },
});

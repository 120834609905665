import { firestore } from 'firebase';
import get from 'lodash/get';
import { hasKey } from './validation';

export const assignTimestamp = prop =>
    data =>
        ({ ...data, [prop]: firestore.FieldValue.serverTimestamp() });

export const getDocDataWithId = doc =>
    ({ ...doc.data(), id: doc.id });

const processDoc = (doc, dest) =>
    dest.push(getDocDataWithId(doc));

export const processSnapshotOne = (snapshot) => {
    const entries = [];
    snapshot.forEach((doc) => {
        processDoc(doc, entries);
    });
    return entries;
};
export const processSnapshotMultiple = (snapshots) => {
    const data = [];
    snapshots.map((snapshot) => {
        if (snapshot.size > 0) {
            snapshot.forEach((doc) => {
                processDoc(doc, data);
            });
        }
    });
    return data;
};

// filters only 2 levels deep
export const clientFiltering = params =>
    function (item) {
        let meetsFilters = true;
        for (const key of Object.keys(params.filter)) {
            if (typeof params.filter[key] === 'object') {
                for (const subkey of Object.keys(params.filter[key])) {
                    const field = get(item, `${key}.${subkey}`) || '';
                    meetsFilters = field.toLowerCase().includes(params.filter[key][subkey].toLowerCase());
                }
            } else if (typeof params.filter[key] === 'string') {
                const field = get(item, key) || '';
                meetsFilters = field.toLowerCase().includes(params.filter[key].toLowerCase());
            }
        }
        return meetsFilters;
    };

export const firestoreDateToMillis = (date = null) => {
    const isValidFirestoreDate = date !== null
        && typeof date.toMillis === 'function'
        && hasKey('toMillis')(date);

    return isValidFirestoreDate
        ? date.toMillis()
        : (hasKey('_seconds')(date) ? date._seconds * 1000 : null);
};

export const defaultDateOptions = {
    year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric',
};

export const getDeadlineDate = (deadline) => {
    return new Date(deadline._seconds * 1000);
};

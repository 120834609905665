/*
Customizing the AppBar Content
https://marmelab.com/react-admin/Theming.html#using-a-custom-layout
*/
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import AppBar from './RaAppBar';
import { getCompanyName } from '../companies';

const styles = {
    title: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' },
    spacer: { flex: 1 },
};

const MyAppBar = ({
    classes, companyName, dispatch, ...props
}) => (
    <AppBar {...props}>
        <Typography
            variant="title"
            color="inherit"
            id="react-admin-title"
        />
        <span className={classes.spacer} />
        <Typography
            variant="title"
            color="inherit"
            className={classes.title}
        >
            {companyName}
        </Typography>
    </AppBar>
);

MyAppBar.propTypes = {
    classes: PropTypes.object,
    companyName: PropTypes.string,
    dispatch: PropTypes.func,
};

const enhance = compose(
    connect(state => ({
        companyName: getCompanyName(state),
    })),
    withStyles(styles),
);

export default enhance(MyAppBar);

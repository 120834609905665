import { call, put, takeEvery } from 'redux-saga/effects';
import {
    fetchEnd, fetchStart, showNotification, USER_LOGIN_FAILURE, userLogout,
} from 'react-admin';
import { NOTIFICATION } from 'omni-shared/constants';
import { createUserAndCompany, sendVerificationEmail } from '../../services/auth';
import { ROUTES } from '../../framework/routes';

const options = { autoHideDuration: 60 * 1000 };

export function* handleSignup({ payload }) {
    try {
        yield put(fetchStart());

        yield call(
            createUserAndCompany,
            payload,
        );
        yield call(
            sendVerificationEmail,
        );
        yield put(fetchEnd());
        yield put(userLogout(ROUTES.LOGIN));
        yield put(showNotification('resources.auth.notification.inbox_verification'));
    } catch (e) {
        // TODO: refactor this mess
        yield put({
            type: USER_LOGIN_FAILURE,
            error: e,
            meta: { auth: true },
        });
        const errorMessage = typeof e === 'string'
            ? e
            : typeof e === 'undefined' || !e.message
                ? 'ra.auth.sign_in_error'
                : e.message;
        yield put(showNotification(errorMessage, NOTIFICATION.WARNING, options));
    }
}

export default function* watchSignupAction() {
    yield takeEvery('USER_SIGNUP', handleSignup);
}

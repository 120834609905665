import React from 'react';
import PropTypes from 'prop-types';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Button from '@material-ui/core/Button';
import compose from 'recompose/compose';
import withStyles from '@material-ui/core/styles/withStyles';
import classnames from 'classnames';
import { OMNI_ORDER_STATUSES } from 'omni-shared/constants';
import AutorenewIcon from '@material-ui/icons/Autorenew';

const style = theme => ({
    warningButton: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.orange.light,
        '&:hover': {
            backgroundColor: fade(theme.palette.orange.dark, 0.99),
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
    ctaButton: {
        minWidth: '200px',
        margin: '10px',
        textTransform: 'none',
        padding: '12px 16px',
    },
});

const OwnerButtons = ({
    record,
    onRestartOrder,
    buttonsDisabled,
    translate,
    classes,
}) => {
    switch (record.status) {
        case OMNI_ORDER_STATUSES.ON_HOLD:
            return (
                <Button
                    disabled={buttonsDisabled}
                    className={classnames(classes.warningButton, classes.ctaButton)}
                    onClick={onRestartOrder}
                >
                    <AutorenewIcon />
                    <span style={{ fontWeight: 'bold' }}>
                        {translate('resources.omni_orders.data.restart').toUpperCase()}
                    </span>
                </Button>

            );
        default:
            return '';
    }
};

OwnerButtons.propTypes = {
    classes: PropTypes.object,
    record: PropTypes.object,
    translate: PropTypes.func,
};

const enhance = compose(
    withStyles(style),
);

export default enhance(OwnerButtons);

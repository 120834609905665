import React from 'react';

const AppBarTitle = ({ record, translate }) => (
    <span>
        {translate('utilities.edit')}
        {' '}
        {record ? record.name : ''}
    </span>
);

export default AppBarTitle;

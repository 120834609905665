import React from 'react';
import {
    Edit, FormTab, required, TabbedForm, TextInput, translate,
} from 'react-admin';
import compose from 'recompose/compose';
import withStyles from '@material-ui/core/styles/withStyles';
import { AppBarTitle } from '../../components/title';
import { SaveButtonToolbar } from '../../components/toolbar';


const styles = {
    title: { marginTop: '1em' },
    panel: { width: '400px' },
};

const Exchange = ({ classes, translate, ...props }) => (
    <Edit title={<AppBarTitle translate={translate} />} {...props}>
        <TabbedForm
            title={translate('resources.settings.name')}
            toolbar={<SaveButtonToolbar />}
        >
            <FormTab label={translate('resources.settings.tabs.general')}>
                <TextInput
                    validate={[required()]}
                    source="apiUrl"
                    style={{ width: '400px' }}
                />
                <TextInput
                    validate={[required()]}
                    source="accessToken"
                    style={{ width: '400px' }}
                />
            </FormTab>
        </TabbedForm>
    </Edit>
);

const enhance = compose(
    translate,
    withStyles(styles),
);

export default enhance(Exchange);

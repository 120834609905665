import Switch from '@material-ui/core/Switch';
import React from 'react';
import { connect } from 'react-redux';
import { refreshView, resetForm } from 'react-admin';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import PropTypes from 'prop-types';
import { TOGGLE_ARCHIVE } from './archiveReducer';

const ArchiveSwitch = ({
    archiveEnabled, toggleArchive, refreshView, setFilters, translate,
}) => (
    <FormGroup row>
        <FormControlLabel
            control={(
                <Switch
                    checked={!!archiveEnabled}
                    onChange={() => {
                        toggleArchive();
                        refreshView();
                        setFilters({});
                    }}
                />
            )}
            label={archiveEnabled
                ? translate('resources.orders.actions.showActive')
                : translate('resources.orders.actions.showArchived')}
        />
    </FormGroup>
);

ArchiveSwitch.propTypes = {
    archiveEnabled: PropTypes.bool,
    toggleArchive: PropTypes.func,
    refreshView: PropTypes.func,
    setFilters: PropTypes.func,
    translate: PropTypes.func,
};

const mapStateToProps = state => ({
    archiveEnabled: state.archive,
});

const mapDispatchToProps = {
    toggleArchive: data => ({
        type: TOGGLE_ARCHIVE,
        payload: data,
    }),
    resetForm,
    refreshView,
};

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveSwitch);

import React from 'react';
import PropTypes from 'prop-types';
import { DateField } from 'ra-ui-materialui';
import { firestoreDateToMillis, defaultDateOptions } from '../../utils/firebase';

/**
 * Adapts DateField component to use Firestore's date format in the context of react-admin List/Datagrid
 * @param {object} record
 * @param {string} source
 * @param {object} rest
 */
const FirestoreDateField = ({
    record, source, options, ...rest
}) => {
    const date = record[source];

    const locales = 'nb-NO';
    return (
        <DateField
            record={{ source: firestoreDateToMillis(date) }}
            source="source"
            {...{ options: { ...defaultDateOptions, ...options }, locales, ...rest }}
        />
    );
};

FirestoreDateField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.node,
    rest: PropTypes.object,
};

export default FirestoreDateField;

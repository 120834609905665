import React, { Fragment } from 'react';
import {
    BooleanInput, Edit, FormDataConsumer, FormTab, required, TabbedForm, TextInput, translate,
} from 'react-admin';
import compose from 'recompose/compose';
import withStyles from '@material-ui/core/styles/withStyles';
import get from 'lodash/get';
import { AppBarTitle } from '../../components/title';
import { SaveButtonToolbar } from '../../components/toolbar';


const styles = {
    title: { marginTop: '1em' },
    panel: { width: '400px' },
};

const Exchange = ({ classes, translate, ...props }) => (
    <Edit title={<AppBarTitle translate={translate} />} {...props}>
        <TabbedForm
            title={translate('resources.settings.name')}
            toolbar={<SaveButtonToolbar />}
        >
            <FormTab label={translate('resources.settings.tabs.general')}>
                <TextInput
                    validate={[required()]}
                    source="apiUrl"
                    label={translate('resources.settings.fields.apiUrl')}
                    style={{ width: '400px' }}
                />
                <TextInput
                    validate={[required()]}
                    source="accessToken"
                    label={translate('resources.settings.fields.accessToken')}
                    style={{ width: '400px' }}
                />
            </FormTab>
            <FormTab label={translate('resources.settings.tabs.new_order')}>
                <BooleanInput
                    source="notifications.newOrderStore.email.enabled"
                    label={translate('resources.settings.omni_orders.notify_stores')}
                />
                <FormDataConsumer>
                    {({ formData, ...rest }) => get(formData, 'notifications.newOrderStore.email.enabled')
                        && (
                            <TextInput
                                validate={[required()]}
                                source="notifications.newOrderStore.email.template"
                                label={translate('resources.settings.fields.templateId')}
                                style={{ width: '400px' }}
                                {...rest}
                            />
                        )
                    }
                </FormDataConsumer>
            </FormTab>
            <FormTab label={translate('resources.settings.tabs.overdue_orders')}>
                <BooleanInput
                    source="notifications.overdueOrders.email.enabled"
                    label={translate('resources.settings.omni_orders.notify_recipients')}
                />
                <FormDataConsumer>
                    {({ formData, ...rest }) => get(formData, 'notifications.overdueOrders.email.enabled')
                        && (
                            <Fragment>
                                <div>
                                    <TextInput
                                        validate={[required()]}
                                        source="notifications.overdueOrders.email.template"
                                        label={translate('resources.settings.fields.templateId')}
                                        style={{ width: '400px' }}
                                        {...rest}
                                    />
                                </div>
                                <div>
                                    <TextInput
                                        validate={[required()]}
                                        source="notifications.overdueOrders.email.recipients"
                                        label={translate('resources.settings.omni_orders.recipients')}
                                        style={{ width: '400px' }}
                                        {...rest}
                                    />
                                </div>
                            </Fragment>
                        )
                    }
                </FormDataConsumer>
            </FormTab>
            <FormTab label="Consignor">
                <TextInput
                    validate={[required()]}
                    source="shipping.consignor.actorId"
                    label="Actor ID"
                    style={{ width: '400px' }}
                />
                <TextInput
                    validate={[required()]}
                    source="shipping.consignor.key"
                    label="Key"
                    style={{ width: '400px' }}
                />
                <TextInput
                    validate={[required()]}
                    source="shipping.consignor.productId"
                    label="Product ID"
                    style={{ width: '400px' }}
                />
                <TextInput
                    validate={[required()]}
                    source="shipping.consignor.url"
                    label="URL"
                    style={{ width: '400px' }}
                />
            </FormTab>

        </TabbedForm>
    </Edit>
);

const enhance = compose(
    translate,
    withStyles(styles),
);

export default enhance(Exchange);

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { LoginFormCustom } from '../login';
import { SnackBarNotification } from '../notifications';
import omniTheme from '../../framework/theme';

const styles = {
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        height: '1px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: '#abaaaa',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 400,
        marginTop: '6em',
    },
    inline: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'center',
        padding: '1em 1em 2em 1em',
    },
    title: {
        fontSize: '25px',
        marginLeft: '5px',
    },
};

const sanitizeRestProps = ({
    classes,
    className,
    location,
    title,
    array,
    theme,
    staticContext,
    ...rest
}) => rest;

const SimpleLayoutContainer = ({
    classes, className, form, theme, ...rest
}) => (
    <MuiThemeProvider theme={createMuiTheme(theme)}>
        <div
            className={classnames(classes.main, className)}
            {...sanitizeRestProps(rest)}
        >
            <Card className={classes.card}>
                <CardContent>
                    <div className={classes.inline}>
                        <img src="/omnicloud.png" height="30px" alt="Omnicloud logo" />
                        <div className={classes.title}>OmniCloud</div>
                    </div>
                    {form}
                </CardContent>
            </Card>
            <SnackBarNotification />
        </div>
    </MuiThemeProvider>
);

SimpleLayoutContainer.propTypes = {
    className: PropTypes.string,
    authProvider: PropTypes.func,
    classes: PropTypes.object,
    input: PropTypes.object,
    meta: PropTypes.object,
    previousRoute: PropTypes.string,
    form: PropTypes.element,
    theme: PropTypes.object,
};

SimpleLayoutContainer.defaultProps = {
    theme: omniTheme,
    form: <LoginFormCustom />,
};

export default withStyles(styles)(SimpleLayoutContainer);

import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Button from '@material-ui/core/Button';
import { showNotification, translate } from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

class Instructions extends React.Component {
    onCopy = () => {
        this.props.showNotification('Copied', 'info');
    }

    getMarkup = id => `<link href="https://storage.googleapis.com/omnicloud-prod.appspot.com/storefinder/app/v1/style.css" rel="stylesheet">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<div id="root" data-user="${id}" data-locale="nb_NO" data-root-list-group="region"></div>
<script defer async src="https://storage.googleapis.com/omnicloud-prod.appspot.com/storefinder/app/v1/bundle.js"></script>`;

    render() {
        const { id, translate } = this.props;
        const markup = this.getMarkup(id);
        // putting data-... attributes in variables to avoid eslint errors (react/no-unescaped-entities)
        const dataCity = 'data-root-list-group="city"';
        const dataStore = 'data-root-list-group="store"';
        const dataCountries = 'data-countries="de,at,lu"';
        const dataExtraZoom = 'data-extra-zoom="3"';

        return (
            <Card>
                <CardContent>
                    {translate('resources.instructions.data.paste')}
                    <div style={{ display: 'flex' }}>
                        <pre>
                            <code>{markup}</code>
                        </pre>
                        <div>
                            <CopyToClipboard text={markup} onCopy={this.onCopy}>
                                <Button
                                    variant="raised"
                                    color="default"
                                >
                                    {translate('utilities.copy_to_clipboard')}
                                </Button>
                            </CopyToClipboard>
                        </div>
                    </div>
                    <p>{translate('resources.instructions.data.checkboxes')}</p>
                    <ul>
                        <li>Row settings &gt; Row &gt; No grid </li>
                        <li>Row settings &gt; Remove margins &gt; Right & Left</li>
                        <li>Column settings &gt; Column &gt; No Grid</li>
                    </ul>
                    <p>
                        {translate('resources.instructions.data.city')}
                        {' '}
                        <strong>{dataCity}</strong>
                    </p>
                    <p>
                        {translate('resources.instructions.data.store')}
                        {' '}
                        <strong>{dataStore}</strong>
                    </p>
                    <p>
                        {translate('resources.instructions.data.default_countries')}
.
                        {translate('resources.instructions.data.countries')}
:
                        <strong>{dataCountries}</strong>
                    </p>
                    <p>
                        {translate('resources.instructions.data.zoom')}
                        {' '}
                        <strong>{dataExtraZoom}</strong>
                    </p>

                </CardContent>
            </Card>
        );
    }
}

Instructions.propTypes = {
    id: PropTypes.string,
    translate: PropTypes.func,
    showNotification: PropTypes.func,
};

const enhance = compose(
    translate,
    connect(null, { showNotification }),
);

export default enhance(Instructions);

import { put, takeEvery, select } from 'redux-saga/effects';
import { showNotification, crudGetOne, fetchStart, fetchEnd, refreshView } from 'react-admin';
import firebase from 'firebase';
import { NOTIFICATION, OMNI_ORDER_STATUSES } from 'omni-shared/constants';
import { OMNI_ORDER_CONFIRM } from '../omniOrdersActions';
import { getCompanyId } from '../../../components/companies';

function changeStatus(payload) {
    const create = firebase.functions().httpsCallable('omniOrdersChangeStatus');
    return create(payload);
}

function* handleOrderConfirmation({ payload }) {
    try {
        yield put(fetchStart());
        const companyId = yield select(getCompanyId);
        yield (changeStatus({
            orderId: payload.id,
            omniOrderId: payload.firestoreDocId,
            nextStatus: OMNI_ORDER_STATUSES.CONFIRMED,
            itemIds: payload.itemIds,
            packagesQty: payload.packagesQty,
            totalWeight: payload.totalWeight,
            companyId,
        }));

        yield put(crudGetOne('omni_orders', payload.id));
    } catch (e) {
        console.log(e);
        yield put(showNotification('resources.omni_orders.notification.confirm_failure', NOTIFICATION.WARNING));
        yield put(refreshView());
    } finally {
        yield put(fetchEnd());
    }
}

export default function* confirmOrderSaga() {
    yield takeEvery(OMNI_ORDER_CONFIRM, handleOrderConfirmation);
}

import React from 'react';
import PropTypes from 'prop-types';
import { PaddedLine } from '../../fields';

const Customer = ({ translate, exchange, classes }) => {
    const { billing_address } = exchange; // eslint-disable-line camelcase
    return (
        <div className={classes.mt20}>
            <div>
                <strong>{translate('resources.exchange.fields.customer')}</strong>
            </div>
            <div>
                <PaddedLine>
                    {[exchange.customer_first_name, exchange.customer_last_name].join(' ')}
                </PaddedLine>
                <PaddedLine>
                    {billing_address.street.join('')}
                </PaddedLine>
                <PaddedLine>
                    {[billing_address.postcode, billing_address.city].join(' ')}
                </PaddedLine>
                <PaddedLine>
                    {billing_address.country}
                </PaddedLine>
                <PaddedLine>
                    {billing_address.phone}
                </PaddedLine>
            </div>
        </div>
    );
};

Customer.propTypes = {
    translate: PropTypes.func,
    exchange: PropTypes.object,
    classes: PropTypes.object,
};

export default Customer;

import {
    all, call, put, select, takeEvery,
} from 'redux-saga/effects';
import { showNotification } from 'react-admin';
import { push } from 'react-router-redux';
import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import { RESOURCES } from 'omni-shared/resources';
import { NOTIFICATION, MESSAGES } from 'omni-shared/constants';
import { SEND_STATUS_CHANGE_MESSAGE, sendMessage } from './ordersActions';
import { getSettings } from '../../database/database';
import { getCompanyId } from '../companies';

function* getMessageSettings(companyId, type) {
    const settings = yield call(getSettings, companyId);

    const keySettingsMap = {
        [MESSAGES.CANCELLATION]: settings.cancellationMessage,
        [MESSAGES.RETRIEVAL]: settings.retrievalMessage,
    };

    return keySettingsMap[type];
}

function getEnabledChannels(messageSettings) {
    return keys(pickBy(messageSettings, o => o.enabled));
}

function* sendMessagesToEnabledChannels(enabledChannels, { type, id }) {
    yield all(enabledChannels.map((channel) => {
        const messagePayload = {
            data: {
                type,
                channel,
            },
            parentId: id,
        };
        return put(sendMessage(messagePayload));
    }));
}

function* handleMessage({ payload }) {
    try {
        const { type } = payload;
        const companyId = yield select(getCompanyId);

        const messageSettings = yield getMessageSettings(companyId, type);

        if (!messageSettings) {
            throw new Error(`Unknown message type ${type}`);
        }

        const enabledChannels = getEnabledChannels(messageSettings);

        if (enabledChannels.length === 0) {
            yield put(showNotification('resources.messages.notification.channels_disabled', NOTIFICATION.WARNING));
            throw new Error();
        }

        yield sendMessagesToEnabledChannels(enabledChannels, payload);
        yield put(push(`/${RESOURCES.ORDERS}`));
        yield put(showNotification('resources.messages.notification.sent'));
    } catch (e) {
        console.log(e);
        yield put(showNotification('resources.messages.notification.sending_failure', NOTIFICATION.WARNING));
    }
}

export default function* statusChangeMessageSaga() {
    yield takeEvery(SEND_STATUS_CHANGE_MESSAGE, handleMessage);
}

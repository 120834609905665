/*
Different app use cases that depend on AuthWrapper:
(renderApp default value is false)
1. logs in via login form
    - onAuthStateChange is triggered
    - authUser exists
    - onAuthStateChange triggers Login saga which sets renderApp to true
2. reloads app (F5) while logged in
    - onAuthStateChange is triggered
    - authUser exists
    - onAuthStateChange triggers Login saga which sets renderApp to true
3. reloads app (F5) while logged out
    - onAuthStateChange is triggered
    - authUser is empty
    - renderApp changed to true by the AuthWrapper
4. tries to access inner pages while logged out
    - authCheckPermissions triggers LOGOUT, which in turn resets store and renderApp is set false by default
    - logout saga sets renderApp to true and AuthWrapper updates
5. signs up
    - successful firebase sign up process automatically logs the new user in and AuthWrapper triggers Login saga
    - signUp saga dispatches USER_LOGOUT action (the user needs to verify their email first)
    - Logout saga listens for USER_LOGOUT and sets renderApp to true and AuthWrapper updates
*/

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import firebase from 'firebase';
import { USER_LOGIN_SUCCESS } from 'react-admin';
import { SimpleLayoutContainer } from '../simpleLayout';
import { renderApp } from '../../store/actions/uiActions';
import { getShouldRenderApp } from '../../store/selectors';


const styles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '3em',
};

class AuthWrapper extends React.Component {
    constructor(props) {
        super(props);
        firebase.auth().onAuthStateChanged(this.handleStateChange);
    }

    handleStateChange = (authUser) => {
        const { userLoginSuccess, renderApp } = this.props;
        console.log('auth user changed', authUser);

        if (this.userIsVerified(authUser)) {
            userLoginSuccess();
        } else {
            renderApp();
        }
    }

    userIsVerified = authUser =>
        authUser && authUser.emailVerified;

    render() {
        const { children, shouldRenderApp } = this.props;

        if (shouldRenderApp) {
            return children;
        }

        return (
            <SimpleLayoutContainer form={(
                <div style={styles}>
                    <CircularProgress />
                </div>
            )}
            />
        );
    }
}

AuthWrapper.propTypes = {
    children: PropTypes.object,
    shouldRenderApp: PropTypes.bool,
    userLoginSuccess: PropTypes.func,
    renderApp: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
    return ({
        userLoginSuccess: () => {
            dispatch({ type: USER_LOGIN_SUCCESS });
        },
        renderApp: () =>
            dispatch(renderApp()),
    });
}

function mapStateToProps(state) {
    return {
        shouldRenderApp: getShouldRenderApp(state),
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(AuthWrapper);

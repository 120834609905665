import { put, takeEvery, select } from 'redux-saga/effects';
import { showNotification, crudGetOne, fetchStart, fetchEnd, refreshView } from 'react-admin';
import firebase from 'firebase';
import { NOTIFICATION, OMNI_ORDER_STATUSES } from 'omni-shared/constants';
import { OMNI_ORDER_RESTART } from '../omniOrdersActions';
import { getCompanyId } from '../../../components/companies';

function changeStatus(payload) {
    const create = firebase.functions().httpsCallable('omniOrdersChangeStatus');
    return create(payload);
}

function* handleOrderRestart({ payload }) {
    try {
        yield put(fetchStart());
        const companyId = yield select(getCompanyId);
        yield (changeStatus({
            omniOrderId: payload.firestoreDocId,
            nextStatus: OMNI_ORDER_STATUSES.NEW,
            companyId,
        }));

        yield put(crudGetOne('omni_orders', payload.id));
    } catch (e) {
        console.log(e);
        yield put(showNotification('resources.omni_orders.notification.restart_failure', NOTIFICATION.WARNING));
        yield put(refreshView());
    } finally {
        yield put(fetchEnd());
    }
}

export default function* restartOrderSaga() {
    yield takeEvery(OMNI_ORDER_RESTART, handleOrderRestart);
}

import React from 'react';
import { Create, Toolbar, translate } from 'react-admin';
import SaveWithStoreFilter from '../SaveWithStoreFilter';
import UserForm from '../UserForm';

const UserCreateToolbar = translate(({ translate, ...props }) => (
    <Toolbar {...props}>
        <SaveWithStoreFilter
            label={translate('ra.action.save')}
            redirect="edit"
            submitOnEnter={false}
        />
    </Toolbar>
));


const UserCreate = props => ((
    <Create {...props}>
        <UserForm toolbar={<UserCreateToolbar />} />
    </Create>
));


// https://marmelab.com/react-admin/Actions.html#altering-the-form-values-before-submitting

export default UserCreate;

import { SET_USER_COMPANY_ID } from './companiesActions';

const initialState = { id: null };

export default (previousState = initialState, { type, payload }) => {
    if (type === SET_USER_COMPANY_ID) {
        return payload;
    }

    return previousState;
};

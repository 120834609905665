import Button from '@material-ui/core/Button';
import React from 'react';
import { ButtonSpinner } from './ButtonSpinner';

export const ToggleButton = ({
    onClick, isLoading, title, ...rest
}) => (
    <Button
        variant="contained"
        onClick={() => onClick()}
        disabled={isLoading}
        {...rest}
    >
        {isLoading ? <ButtonSpinner />
            : title}
    </Button>
);

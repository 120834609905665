import { RESOURCES } from 'omni-shared/resources';
import omniOrdersDataProvider from './omniOrdersDataProvider';
import firestoreDataProvider from './firestoreDataProvider';

const callableResources = [
    RESOURCES.OMNI_ORDERS,
    RESOURCES.OMNI_ORDERS_ITEMS,
];

const getDataProvider = (resource) => {
    if (callableResources.includes(resource)) {
        return omniOrdersDataProvider;
    }
    return firestoreDataProvider;
};

export default store => function (type, resource, params) {
    const dataProvider = getDataProvider(resource);
    return dataProvider(store)(type, resource, params);
};

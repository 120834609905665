import { Typography } from '@material-ui/core';
import React from 'react';

export const CustomNumberField = ({ locale = 'nb-NO', value, label }) => (
    <Typography variant="title">
        {label}
        {' '}
        {value.toLocaleString(locale, { style: 'currency', currency: 'NOK' })}
    </Typography>
);

export default CustomNumberField;

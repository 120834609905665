import IconCancel from '@material-ui/core/SvgIcon/SvgIcon';
import React from 'react';
import {
    Dialog, DialogTitle, DialogActions, DialogContent,
} from '@material-ui/core';
import {
    SimpleForm,
    SaveButton,
    Button,
    LongTextInput,
    SelectInput,
} from 'react-admin';
import PropTypes from 'prop-types';

const RegisterDialog = ({
    translate, formName, handleCloseClick, handleSaveClick, isOpen, handleSubmit, title,
}) => (
    <Dialog
        fullWidth
        open={isOpen}
        onClose={handleCloseClick}
    >
        <DialogTitle>
            {title}
        </DialogTitle>
        <DialogContent>
            <SimpleForm
            // We override the redux-form name to avoid collision with the react-admin main form
                form={formName}
                // We override the redux-form onSubmit prop to handle the submission ourselves
                onSubmit={handleSubmit}
                // We want no toolbar at all as we have our modal actions
                toolbar={null}
            >
                <SelectInput
                    source="reason"
                    choices={[
                        { id: 'exchange', name: 'resources.exchange.data.exchange' },
                        { id: 'return', name: 'resources.exchange.data.return' },
                    ]}
                    label={translate('resources.exchange.fields.reason')}
                />
                <LongTextInput
                    source="comment"
                    label={translate('resources.exchange.fields.comment')}
                    options={{ rows: 2 }}
                />
            </SimpleForm>
        </DialogContent>
        <DialogActions>
            <Button
                label="ra.action.cancel"
                onClick={handleCloseClick}
            >
                <IconCancel />
            </Button>
            <SaveButton
                onClick={handleSaveClick}
            />
        </DialogActions>
    </Dialog>
);

RegisterDialog.propTypes = {
    formName: PropTypes.node,
    translate: PropTypes.func,
    handleCloseClick: PropTypes.func,
    handleSaveClick: PropTypes.func,
    isOpen: PropTypes.bool,
    handleSubmit: PropTypes.func,
    title: PropTypes.node,
};

export default RegisterDialog;

import { call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { fetchEnd, fetchStart, showNotification } from 'react-admin';
import { PASSWORD_RESET } from './passwordResetActions';
import { resetPassword } from '../../services/auth';
import { ROUTES } from '../../framework/routes';

// hide notification after one minute
const options = { autoHideDuration: 60 * 1000 };
export function* handleReset({ payload: { email } }) {
    try {
        yield put(fetchStart());
        yield call(resetPassword, email);
        yield put(showNotification('resources.auth.notification.inbox_reset', null, options));
    } catch (e) {
    // showing success notification either way - we should not disclose if the email exists or not
        yield put(showNotification('resources.auth.notification.inbox_reset', null, options));
    } finally {
        yield put(fetchEnd());
        yield put(push(ROUTES.LOGIN));
    }
}

export default function* passwordReset() {
    yield takeEvery(PASSWORD_RESET, handleReset);
}

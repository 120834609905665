import React from 'react';
import {
    Edit,
    FormTab,
    maxLength,
    showNotification,
    TabbedForm,
    translate,
} from 'react-admin';
import compose from 'recompose/compose';
import { Field } from 'redux-form';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import ApiKeyInput from './ApiKeyInput';
import { AppBarTitle } from '../../components/title';
import { SaveButtonToolbar } from '../../components/toolbar';


const CustomInput = ({ label }) => (
    <span>
        <Field
            name="api.key"
            component={translate(connect(null, { showNotification })(ApiKeyInput))}
            label={label}
        />
    </span>
);

const styles = {
    title: { marginTop: '1em' },
    panel: { width: '400px' },
};

function Settings({ classes, translate, ...props }) {
    return (
        <Edit title={<AppBarTitle translate={translate} />} {...props}>
            <TabbedForm
                title={translate('resources.settings.name')}
                toolbar={<SaveButtonToolbar />}
            >
                <FormTab label={translate('resources.settings.tabs.general')}>
                    <CustomInput
                        label={translate('resources.settings.fields.api.key')}
                        source="api.key"
                        style={{ width: '400px' }}
                        validate={[maxLength(11)]}
                    />
                </FormTab>
            </TabbedForm>

        </Edit>
    );
}

const enhance = compose(
    translate,
    withStyles(styles),
);

export default enhance(Settings);

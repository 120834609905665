import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import { firestoreDateToMillis } from '../../../utils/firebase';

const getFormattedDate = timestamp => (timestamp ? new Date(timestamp)
    .toLocaleString('nb-NO') : null);

const exporter = translate => (orders) => {
    const ordersForExport = orders.map((order) => {
        const {
            externalOrderId, status, storeName, createdAt, customer = {}, pickupDeadline, total, googleAnalytics = {},
        } = order;

        return {
            order_id: externalOrderId,
            status: translate(`resources.orders.data.${status}`),
            store_name: storeName,
            customer_email: customer.email,
            customer_mobile: customer.mobile,
            createdAt: getFormattedDate(firestoreDateToMillis(createdAt)),
            pickup_deadline: getFormattedDate(firestoreDateToMillis(pickupDeadline)),
            ga_client_id: googleAnalytics.clientId,
            total,
        };
    });
    const csv = convertToCSV({
        data: ordersForExport,
        fields: [
            'order_id',
            'store_name',
            'customer_email',
            'customer_mobile',
            'status',
            'createdAt',
            'pickup_deadline',
            'ga_client_id',
            'total',
        ],
    });
    downloadCSV(csv, 'orders');
};

export default exporter;

import React, { Component } from 'react';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { Field } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

class renderRichTextField extends Component {
    constructor(props) {
        super(props);
        const { input } = this.props;
        this.state = {
            editorState: EditorState.createWithContent(stateFromHTML(input.value)),
        };
    }

    onEditorStateChange = (editorState) => {
        const { input } = this.props;
        if (editorState.getCurrentContent().hasText()) {
            input.onChange(stateToHTML(editorState.getCurrentContent()));
        } else {
            input.onChange('');
        }
        this.setState({
            editorState,
        });
    };

    render() {
        const { editorState } = this.state;
        return (
            <Editor
                toolbar={{
                    options: ['inline', 'blockType', 'list', 'link', 'remove', 'history'],
                    inline: {
                        inDropdown: false,
                        options: ['bold', 'italic', 'underline', 'strikethrough'],
                    },
                }}
                editorState={editorState}
                wrapperClassName="rich-input-wrapper"
                onEditorStateChange={this.onEditorStateChange}
            />
        );
    }
}

renderRichTextField.propTypes = {
    input: PropTypes.object,
};

const styles = theme =>
    ({
        label: {
            marginTop: '25px',
            marginBottom: '15px',
            color: theme.palette.text.secondary,
        },
    });

const RichTextInput = ({ classes, label }) =>
    (
        <span>
            <div className={classes.label}>{label}</div>
            <Field name="openingHours.richText" component={renderRichTextField} />
        </span>
    );
RichTextInput.propTypes = {
    classes: PropTypes.object,
    label: PropTypes.node,
};

export default withStyles(styles)(RichTextInput);

export const RENDER_APP = 'RENDER_APP';
export const DO_NOT_RENDER_APP = 'DO_NOT_RENDER_APP';

export const renderApp = () => ({
    type: RENDER_APP,
});

export const stopRenderingApp = () => ({
    type: DO_NOT_RENDER_APP,
});

import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { translate } from 'react-admin';
import _isEmpty from 'lodash/isEmpty';
import { BorderlessTableCell } from '../utilities';
import { SimpleFirestoreDateField } from '../../fields';


export const OrderSummary = ({ order = {}, translate}) => {
    const { customer = {} } = order;

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <BorderlessTableCell>{translate('resources.orders.fields.store')}</BorderlessTableCell>
                    <BorderlessTableCell align="right">
                        {translate('resources.orders.fields.phone')}
                    </BorderlessTableCell>
                    <BorderlessTableCell align="right">
                        {translate('resources.orders.fields.createdAt')}
                    </BorderlessTableCell>
                    <BorderlessTableCell align="right">
                        {translate('resources.orders.fields.pickup_deadline')}
                    </BorderlessTableCell>
                    <BorderlessTableCell align="right">
                        {translate('resources.orders.fields.notice')}
                    </BorderlessTableCell>
                    <BorderlessTableCell align="right">
                        {translate('resources.orders.fields.customer_information')}
                    </BorderlessTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {_isEmpty(order)
                    ? <TableRow />
                    : (
                        <TableRow>
                            <BorderlessTableCell component="th" scope="row" className="breakWord">
                                {order.storeName}
                            </BorderlessTableCell>
                            <BorderlessTableCell component="th" scope="row">
                                {customer.mobile}
                            </BorderlessTableCell>
                            <BorderlessTableCell component="th" scope="row">
                                <SimpleFirestoreDateField date={order.createdAt} />
                            </BorderlessTableCell>
                            <BorderlessTableCell component="th" scope="row">
                                <SimpleFirestoreDateField date={order.pickupDeadline} />
                            </BorderlessTableCell>
                            <BorderlessTableCell component="th" scope="row" className="breakWord">
                                {order.notice}
                            </BorderlessTableCell>
                            <BorderlessTableCell component="th" scope="row" className="breakWord">
                                <div>{customer.name}</div>
                                <div>{customer.address}</div>
                                <div>{customer.email}</div>
                            </BorderlessTableCell>
                        </TableRow>
                    )}
            </TableBody>
        </Table>
    );
};

OrderSummary.propTypes = {
    order: PropTypes.object,
    translate: PropTypes.func,
};

export default translate(OrderSummary);

import React from 'react';
import PropTypes from 'prop-types';
import { PaddedLine, SeparatedField, SimpleCurrencyField } from '../../fields';

const DiscountDescriptionField = ({ description }) => (description ? (
    <span style={{ position: 'absolute', paddingLeft: '0.5rem' }}>
(
        {description}
)
    </span>
) : null);

const OrderAmounts = ({ classes, translate, exchange }) => (
    <div style={{ maxWidth: '250px', marginTop: '15px' }}>
        <PaddedLine classes={classes}>
            <SeparatedField title={translate('resources.exchange.fields.items_sum')}>
                <SimpleCurrencyField amount={Number(exchange.base_subtotal)} />
            </SeparatedField>
        </PaddedLine>
        <PaddedLine classes={classes}>
            <SeparatedField title={translate('resources.exchange.fields.shipping_amount')}>
                <SimpleCurrencyField amount={Number(exchange.base_shipping_amount)} />
            </SeparatedField>
        </PaddedLine>
        <PaddedLine classes={classes}>
            <SeparatedField title={translate('resources.exchange.fields.discount_amount')}>
                <div>
                    <SimpleCurrencyField amount={Number(exchange.base_discount_amount)} />
                    {' '}
                    <DiscountDescriptionField description={exchange.discount_description} />
                </div>
            </SeparatedField>
        </PaddedLine>
        <PaddedLine classes={classes}>
            <SeparatedField title={translate('resources.exchange.fields.vat')}>
                <SimpleCurrencyField amount={Number(exchange.base_tax_amount)} />
            </SeparatedField>
        </PaddedLine>
        <PaddedLine classes={classes}>
            <SeparatedField title={translate('resources.exchange.fields.total')}>
                <SimpleCurrencyField amount={Number(exchange.base_grand_total)} />
            </SeparatedField>
        </PaddedLine>
    </div>
);

OrderAmounts.propTypes = {
    translate: PropTypes.func,
    classes: PropTypes.object,
    exchange: PropTypes.object,
};

export default OrderAmounts;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import compose from 'recompose/compose';
import { translate } from 'react-admin';
import { searchAction } from './searchActions';


class SearchButton extends Component {
    handleClick = () => {
        const { search, formData, updateParent } = this.props;
        const request = this.buildRequest(formData);

        search(request);
        updateParent();
    }

    buildRequest = (formData) => {
        const address = this.getAddressSearchTerm(formData);

        return {
            address,
            componentRestrictions: {
                country: formData.country,
            },
        };
    }

    getAddressSearchTerm = ({ address, city }) => [address, city].filter(Boolean).join(', ');

    render() {
        const { label = 'Find address' } = this.props;
        return <Button variant="outlined" onClick={this.handleClick}>{label}</Button>;
    }
}


SearchButton.propTypes = {
    search: PropTypes.func,
    updateParent: PropTypes.func,
    record: PropTypes.object,
    formData: PropTypes.object,
    label: PropTypes.string,
};

SearchButton.defaultProps = {
    updateParent: () => {},
};

const enhance = compose(
    translate,
    connect(null, {
        search: searchAction,
    }),
);


export default enhance(SearchButton);

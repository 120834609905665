import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import FirestoreDateField from './FirestoreDateField';

const style = {
    noWrap: {
        whiteSpace: 'nowrap',
    },
};

const SimpleFirestoreDateField = ({ date = null, classes }) => (
    <FirestoreDateField record={{ source: date }} source="source" className={classes.noWrap} />
);

SimpleFirestoreDateField.propTypes = {
    date: PropTypes.object,
    classes: PropTypes.object,
};

export default withStyles(style)(SimpleFirestoreDateField);

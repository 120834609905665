import React from 'react';
import PropTypes from 'prop-types';
import { Warning } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import geolib from 'geolib';

const DataFieldWarning = ({
    record, notice,
}) => ('position' in record && record.position != null && geolib.validate(record.position)
    ? null
    : (
        <Tooltip
            title={notice}
            placement="top"
        >
            <Warning />
        </Tooltip>
    ));

DataFieldWarning.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string,
    notice: PropTypes.string.isRequired,
};

export default DataFieldWarning;

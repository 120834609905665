import { OMNI_ORDER_STATUSES } from 'omni-shared/constants';
import { Filter, SelectInput, TextInput } from 'react-admin';
import PropTypes from 'prop-types';
import React from 'react';

export const Filters = ({
    translate, ...props
}) => {
    const filters = [
        { id: OMNI_ORDER_STATUSES.NEW, name: translate('resources.omni_orders.data.new') },
        { id: OMNI_ORDER_STATUSES.LOCKED, name: translate('resources.omni_orders.data.locked') },
        { id: OMNI_ORDER_STATUSES.CONFIRMED, name: translate('resources.omni_orders.data.confirmed') },
        { id: OMNI_ORDER_STATUSES.ON_HOLD, name: translate('resources.omni_orders.data.onHold') },
    ];

    return (
        <Filter {...props}>
            <SelectInput
                label={translate('resources.omni_orders.fields.status')}
                source="status"
                choices={filters}
            />
            <TextInput
                label={translate('resources.omni_orders.fields.order_number')}
                source="orderId"
            />
        </Filter>
    );
};

Filters.propTypes = {
    translate: PropTypes.func,
};

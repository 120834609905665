import {
    CREATE, DELETE, DELETE_MANY, GET_LIST, GET_MANY, GET_MANY_REFERENCE, GET_ONE, UPDATE,
} from 'react-admin';
import sortBy from 'sort-by';
import { RESOURCES } from 'omni-shared/resources';
import { compose } from 'recompose';
import firebase from 'firebase';
import { STATUSES } from 'omni-shared/constants';
import { getResourcePath } from '../framework/resources';
import { getPermissions } from '../store/selectors';
import { clearUndefinedProps, stripIdProperty } from '../utils/functions';
import {
    assignTimestamp,
    clientFiltering,
    getDocDataWithId,
    processSnapshotMultiple,
    processSnapshotOne,
} from '../utils/firebase';
import { getSettings } from './database';
import { getCompanyId } from '../components/companies';
import { getIsArchiveEnabled } from '../components/orders/list/archiveReducer';
import { saveStoreImageToStorage } from '../components/stores';

export default (store) => {
    const resourcePath = getResourcePath(store);

    const withPermissions = (ref, permissions) => {
        if (permissions.roles.owner === false && permissions.roles.admin === false) {
            return ref.where('userIds', 'array-contains', firebase.auth().currentUser.uid);
        }
        return ref;
    };

    const ordersQuery = (ref, permissions, filters) => {
        const activeStatuses = [
            STATUSES.NEW,
            STATUSES.PICKING,
            STATUSES.DELIVERED_PARTLY,
            STATUSES.WAITING_PICKUP,
            STATUSES.ON_HOLD,
        ];
        const archiveStatuses = [
            STATUSES.CANCELLED,
            STATUSES.PICKEDUP,
        ];

        const isArchiveEnabled = getIsArchiveEnabled(store.getState());
        const path = resourcePath(GET_LIST, 'click_and_collect/orders');

        if (permissions.roles.admin === false && permissions.roles.owner === false) {
            const storesPath = resourcePath(GET_LIST, 'storefinder/stores');
            const storesRef = firebase.firestore()
                .collection(storesPath)
                .where('userIds', 'array-contains', firebase.auth().currentUser.uid);

            return storesRef.get() // get all stores to which user belongs
                .then(processSnapshotOne)
                .then((stores) => {
                    const storeSnapshots = [];
                    stores.map(({ id }) => {
                        const getSnapshot = statusFilterValue => firebase.firestore()
                            .collection(path)
                            .where('storeId', '==', id)
                            .where('status', '==', statusFilterValue)
                            .get();

                        // TODO: refactor
                        if (isArchiveEnabled) {
                            if (filters.status === '' || !filters.status) {
                                storeSnapshots.push(...archiveStatuses.map(val => getSnapshot(val)));
                            } else if (filters.status) {
                                storeSnapshots.push(getSnapshot(filters.status));
                            }
                        } else if (filters.status === '' || !filters.status) {
                            storeSnapshots.push(...activeStatuses.map(val => getSnapshot(val)));
                        } else if (filters.status) {
                            storeSnapshots.push(getSnapshot(filters.status));
                        }
                    });
                    return Promise.all(storeSnapshots);
                })
                .then(processSnapshotMultiple);
        }

        const snapshots = [];
        const getSnapshot = statusFilterValue => firebase.firestore()
            .collection(path)
            .where('status', '==', statusFilterValue)
            .get();

        // TODO: refactor
        if (isArchiveEnabled) {
            if (filters.status === '' || !filters.status) {
                snapshots.push(...archiveStatuses.map(val => getSnapshot(val)));
            } else if (filters.status) {
                snapshots.push(getSnapshot(filters.status));
            }
        } else if (filters.status === '' || !filters.status) {
            snapshots.push(...activeStatuses.map(val => getSnapshot(val)));
        } else if (filters.status) {
            snapshots.push(getSnapshot(filters.status));
        }

        return Promise.all(snapshots).then(processSnapshotMultiple);
    };

    const getList = (resource, params, permissions) => {
        const resPath = resourcePath(GET_LIST, resource);
        const collectionRef = firebase.firestore().collection(resPath);
        let query;
        if (resource === 'storefinder/stores') {
            query = withPermissions(collectionRef, permissions).get().then(processSnapshotOne);
        } else if (resource === 'click_and_collect/orders') {
            query = ordersQuery(collectionRef, permissions, params.filter);
        } else {
            query = collectionRef.get().then(processSnapshotOne);
        }

        return query
            // .orderBy('customer.email', 'desc') -- removed, because
            // if some prop is missing, the whole record is excluded from results
            .then((entries) => {
                if (params.filter) {
                    entries = entries.filter(clientFiltering(params));
                }

                if (params.sort) {
                    entries.sort(sortBy(`${params.sort.order === 'ASC' ? '-' : ''}${params.sort.field}`));
                }
                // TODO: pagination should not be done on the client-side!
                // firestore has only .limit, but no .offset (not good for pagination, because even if you know
                // which slice of results you need, it is not enough to retrieve it)
                // https://github.com/firebase/firebase-js-sdk/issues/479
                const { page, perPage } = params.pagination;
                const startIndex = (page - 1) * perPage;
                const endIndex = page * perPage;
                const paginatedStores = entries.slice(startIndex, endIndex);

                return {
                    data: paginatedStores,
                    total: entries.length,
                };
            });
    };

    const getManyReference = (resource, params) => {
        const resPath = resourcePath(GET_MANY_REFERENCE, resource, params.id);

        return firebase.firestore()
            .collection(resPath)
            .get()
            .then((snapshot) => {
                const entries = [];
                snapshot.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    data.relatedTo = params.id;
                    entries.push(data);
                });
                if (params.sort) {
                    entries.sort(sortBy(`${params.sort.order === 'ASC' ? '-' : ''}${params.sort.field}`));
                }

                return {
                    data: entries,
                    total: entries.length,
                };
            });
    };

    const getOne = (resource, { id, parentId }) => {
        const resPath = resourcePath(GET_ONE, resource, parentId);

        return firebase.firestore()
            .collection(resPath)
            .doc(id)
            .get()
            .then((doc) => {
                const data = getDocDataWithId(doc);
                const { image } = data;

                if (parentId) {
                    data.relatedTo = parentId;
                }

                if (image && image.src) {
                    data.files = { src: image.src, title: image.title };
                }

                return {
                    data,
                };
            });
    };

    const getMany = async (resourceName, { ids }) => {
        const queries = ids.map(id => getOne(resourceName, { id }));
        const records = await Promise.all(queries)
            .then(documents => documents.map(({ data }) => data));

        return { data: records };
    };

    const update = (resource, params) => {
        const { id, parentId } = params;
        const resPath = resourcePath(UPDATE, resource, parentId);

        const enhanceData = compose(clearUndefinedProps, assignTimestamp('updatedAt'), stripIdProperty);

        return saveStoreImageToStorage(params, store)
            .then(data => firebase.firestore()
                .collection(resPath)
                .doc(id)
                .set(enhanceData(data), { merge: true }))
            .then(() => getOne(resource, params));
    };

    const create = (resource, { data, parentId }) => {
        const resPath = resourcePath(CREATE, resource, parentId);
        const enhanceData = compose(clearUndefinedProps, assignTimestamp('createdAt'));

        return firebase.firestore()
            .collection(resPath)
            .add(enhanceData(data))
            .then(({ id }) => getOne(resource, { id, parentId }));
    };

    const remove = (resource, params) => {
        const resPath = resourcePath(DELETE, resource);
        const { id, previousData } = params;

        return firebase.firestore()
            .collection(resPath)
            .doc(id)
            .delete()
            .then(() => ({ id, data: { ...clearUndefinedProps(previousData) } }));
    };

    const removeMany = async (resource, params) => {
        try {
            const { ids } = params;
            const resPath = resourcePath(CREATE, resource);

            const deleteDoc = id => firebase.firestore()
                .collection(resPath)
                .doc(id)
                .delete();
            const promises = ids.map(deleteDoc);
            await Promise.all(promises);

            return { data: ids };
        } catch (e) {
            console.log('DELETE_MANY', e.message);
        }
    };

    const getListRtdb = (resource, params) => {
        const resPath = resourcePath(GET_LIST, resource, params.id);
        return firebase.database()
            .ref(resPath)
            .once('value')
            .then((snapshot) => {
                const rawData = snapshot.val() || {};
                console.log(rawData);
                const data = Object.keys(rawData)
                    .map(key => ({ id: key, data: rawData[key], relatedTo: params.id }));
                return {
                    data,
                    total: data.length,
                };
            });
    };

    const fetchOrder = (url, id, accessToken) => {
        const endpoint = new URL(`${url}/V1/omniexchange/order`);
        const params = { incrementId: id };
        endpoint.search = new URLSearchParams(params);

        return window.fetch(endpoint.href,
            {
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                return Promise.reject('Failed to fetch store data');
            })
            .then(data => ({ id, ...data[0] }))
            .catch((error) => {
                throw new Error(error.message);
            });
    };

    const getOneRest = (resource, params) => {
        const companyId = getCompanyId(store.getState());
        return getSettings(companyId, 'exchange')
            .then(settings => fetchOrder(settings.apiUrl, params.id, settings.accessToken))
            .then(data => ({ data }));
    };

    const getHandlers = (resource) => {
        const rtdbExceptions = [
            RESOURCES.COUNTERS_EMAIL_DAILY,
            RESOURCES.COUNTERS_EMAIL_MONTHLY,
            RESOURCES.COUNTERS_SMS_DAILY,
            RESOURCES.COUNTERS_SMS_MONTHLY,
        ];

        const restExceptions = [
            RESOURCES.EXCHANGE,
        ];

        if (rtdbExceptions.includes(resource)) {
            return {
                [GET_LIST]: getListRtdb,
            };
        }

        if (restExceptions.includes(resource)) {
            return {
                [GET_ONE]: getOneRest,
            };
        }
        return {
            [GET_MANY_REFERENCE]: getManyReference,
            [GET_LIST]: getList,
            [GET_ONE]: getOne,
            [UPDATE]: update,
            [CREATE]: create,
            [DELETE]: remove,
            [DELETE_MANY]: removeMany,
            [GET_MANY]: getMany,
        };
    };

    return (type, resource, params) => {
        console.log('request', type, resource, params);
        const state = store.getState();
        const permissions = getPermissions(state);

        const handlers = getHandlers(resource);

        const handler = handlers[type];
        if (!handler) {
            throw new Error(`Dataprovider does not support the "${type}" operation`);
        }

        return handler(resource, params, permissions);
    };
};

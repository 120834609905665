import { searchReducer } from '../components/stores';
import { companiesReducer } from '../components/companies';
import { permissions } from './reducers/permissionsReducer';
import { ui } from './reducers/uiReducer';
import { account } from './reducers/accountReducer';
import { archive } from '../components/orders/list/archiveReducer';


export default {
    account,
    ui,
    permissions,
    archive,
    search: searchReducer,
    company: companiesReducer,
};

import { createMuiTheme } from '@material-ui/core/styles';

const omniTheme = createMuiTheme({
    palette: {
        secondary: {
            light: '#6ec6ff',
            main: '#046abb',
            dark: '#0069c0',
            contrastText: '#fff',
            grey: '#e0e0e0',
            black: 'rgba(0, 0, 0, 0.87)',
        },
        green: { light: '#9CCC65', medium: '#4CAF50', dark: '#43A646' },
        orange: { light: '#FFB74D', medium: '#FF9800', dark: '#EF6C00' },
    },
    typography: {
        title: {
            fontWeight: 400,
        },
    },
    overrides: {
    // increase and bolden table header font size
        MuiTableCell: {
            root: {
                padding: '0',
                fontFamily: 'sans-serif',
            },
            head: {
                fontSize: '15px',
                fontWeight: '700',
            },
            body: {
                fontSize: '16px',
            },
        },
        MuiMenuItem: {
            root: {
                fontSize: '15px',
                color: '#000!important',
            },
        },
        MuiSvgIcon: {
            root: {
                fontSize: '20px',
            },
        },
        MuiSnackbarContent: {
            root: {
                backgroundColor: '#64c545',
                marginTop: '90px',
            },
        },
    },
});

export default omniTheme;

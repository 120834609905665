import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import React from 'react';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { SeparatedField, PaddedLine, SimpleCurrencyField } from '../../fields';

const isItemRegistered = (itemsDb = [], item = {}) => itemsDb.filter(dbItem => dbItem.sku === item.sku).length > 0;

const Items = ({
    items = [], translate, itemsDb, registerClick, classes,
}) => (
    <Table padding="none">
        <TableHead>
            <TableRow>
                <TableCell className={classes.rowCell}>{translate('resources.exchange.fields.items')}</TableCell>
                <TableCell numeric className={classes.rowCell}>
                    {translate('resources.exchange.fields.quantity')}
                </TableCell>
                <TableCell numeric className={classes.rowCell}>
                    {translate('resources.exchange.fields.item_total')}
                </TableCell>
                <TableCell numeric className={classes.rowCell}>
                    {translate('resources.exchange.fields.discount_amount')}
                </TableCell>
                <TableCell />
            </TableRow>
        </TableHead>
        <TableBody>
            {items.map(item => (
                <TableRow key={item.sku}>
                    <TableCell component="th" scope="row" className={classes.rowCell}>
                        <div>
                            <PaddedLine>{item.name}</PaddedLine>
                            <PaddedLine>{item.sku}</PaddedLine>
                            {item.attributes && item.attributes.map(attribute => (
                                <PaddedLine key={attribute.label}>
                                    <SeparatedField
                                        title={attribute.label}
                                        separator=":"
                                    >
                                        {attribute.value}
                                    </SeparatedField>
                                </PaddedLine>
                            ))}
                        </div>
                    </TableCell>
                    <TableCell numeric className={classes.rowCell}>
                        {Number(item.qty_ordered)}
                    </TableCell>
                    <TableCell numeric className={classes.rowCell}>
                        <SimpleCurrencyField amount={Number(item.row_total_incl_tax)} />
                    </TableCell>
                    <TableCell numeric className={classes.rowCell}>
                        <SimpleCurrencyField amount={Number(item.base_discount_amount)} />
                    </TableCell>
                    <TableCell>
                        {
                            isItemRegistered(itemsDb, item)
                                ? translate('resources.exchange.data.itemRegistered')
                                : (
                                    <Button variant="outlined" onClick={() => registerClick(item.sku)}>
                                        {translate('resources.exchange.actions.register')}
                                    </Button>
                                )

                        }
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>
);

Items.propTypes = {
    translate: PropTypes.func,
    classes: PropTypes.object,
    itemsDb: PropTypes.array,
    registerClick: PropTypes.func,
    items: PropTypes.array,
};

export default Items;

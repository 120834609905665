import { manualMessageSaga, statusChangeMessageSaga } from '../components/orders';
import { newPasswordSaga, passwordResetSaga } from '../components/password';
import { emailVerifySaga } from '../components/emailVerify';
import { signUpSaga } from '../components/signup';
import { createStoreUserSaga } from '../components/users';
import { searchSaga } from '../components/stores';
import { userLoginSaga, userLogoutSaga } from '../components/login';
import { omniOrderToggleLockSaga, omniOrderConfirmSaga, restartOrderSaga } from '../pages/omniorders';

export default [
    manualMessageSaga,
    statusChangeMessageSaga,
    passwordResetSaga,
    createStoreUserSaga,
    signUpSaga,
    searchSaga,
    newPasswordSaga,
    emailVerifySaga,
    userLoginSaga,
    userLogoutSaga,
    omniOrderToggleLockSaga,
    omniOrderConfirmSaga,
    restartOrderSaga,
];

import React from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';
import Typography from '@material-ui/core/Typography';
import { OMNI_ORDER_STATUSES } from 'omni-shared/constants';
import { getDeadlineDate } from '../../../utils/firebase';
import getCountdownRenderer from './CountdownRenderer';

function resolveDeadline(record) {
    switch (record.status) {
        case OMNI_ORDER_STATUSES.NEW:
            return record.lockDeadline;
        case OMNI_ORDER_STATUSES.LOCKED:
            return record.confirmDeadline;
        default:
            return null;
    }
}

const CountdownField = ({
    record, translate,
}) => {
    const deadline = resolveDeadline(record);

    return (
        <Typography>
            {deadline
                ? (
                    <strong>
                        <Countdown
                            date={getDeadlineDate(deadline)}
                            renderer={getCountdownRenderer(translate('resources.omni_orders.data.ran_out'))}
                        />
                    </strong>
                )
                : ''}
        </Typography>
    );
};

CountdownField.propTypes = {
    record: PropTypes.object,
    translate: PropTypes.func,
};

export default CountdownField;

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getIsLoading } from '../../store/selectors';

export const componentLoading = (WrappedComponent) => {
    const Comp = class ComponentLoading extends React.Component {
        state = {
            isComponentLoading: false,
        }

        static getDerivedStateFromProps(props, state) {
            if (!props.isLoading && state.isComponentLoading) {
                return { isComponentLoading: false };
            }
            return null;
        }

        render() {
            const { isComponentLoading } = this.state;

            return (
                <WrappedComponent
                    setComponentLoading={() => this.setState({ isComponentLoading: true })}
                    isComponentLoading={isComponentLoading}
                    {...this.props}
                />
            );
        }
    };
    Comp.propTypes = {
        isLoading: PropTypes.bool,
    };

    return connect(state => ({ isLoading: getIsLoading(state) }))(Comp);
};

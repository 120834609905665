import React from 'react';
import {
    Datagrid, List, TextField, translate, Edit, BooleanInput, TabbedForm, FormTab, Title,
} from 'react-admin';
import SelectCompanyButton from './SelectCompanyButton';
import { AppBarTitle } from '../title';
import { SaveButtonToolbar } from '../toolbar';

const CompanyList = translate(({ translate, ...props }) => (
    <List
        {...props}
        sort={{ field: 'name', order: 'DESC' }}
        title={translate('resources.companies.name', { smart_count: 2 })}
    >
        <Datagrid rowClick="edit">
            <TextField source="id" label="ID" />
            <TextField source="name" />
            <TextField source="contact.email" />
            <SelectCompanyButton />
        </Datagrid>
    </List>
));


const CompanyEdit = translate(({ translate, ...props }) => (
    <Edit {...props} title={<AppBarTitle translate={translate} />}>
        <TabbedForm toolbar={<SaveButtonToolbar />}>
            <FormTab label={translate('resources.companies.tabs.visible_features')}>
                <BooleanInput
                    source="features.click_and_collect"
                    label={translate('resources.cac.name')}
                />
                <BooleanInput
                    source="features.storefinder"
                    label={translate('resources.storefinder.name')}
                />
                <BooleanInput
                    source="features.exchange"
                    label={translate('resources.exchange.name')}
                />
            </FormTab>
        </TabbedForm>
    </Edit>
));


export { CompanyList, CompanyEdit };

import { REFRESH_VIEW } from 'react-admin';
import { RENDER_APP, DO_NOT_RENDER_APP } from '../actions';

const initialState = {
    renderApp: false,
    lastRefresh: 0,
};

export const ui = (prevState = initialState, { type }) => {
    switch (type) {
    case REFRESH_VIEW:
        return { ...prevState, lastRefresh: new Date().getTime() };
    case RENDER_APP:
        return { ...prevState, renderApp: true };
    case DO_NOT_RENDER_APP:
        return { ...prevState, renderApp: false };
    }
    return prevState;
};

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const SeparatedField = ({ title, children, separator = '' }) => (
    <Fragment>
        <strong>{title}</strong>
        {separator}
        {' '}
        {children}
    </Fragment>
);

SeparatedField.propTypes = {
    title: PropTypes.node,
    separator: PropTypes.node,
};

export default SeparatedField;

import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-admin';
import * as Redux from 'react-redux';
import * as ReduxForm from 'redux-form';
import compose from 'recompose/compose';
import * as MaterialStyles from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Geocoder from './geocode';

const styles = theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    group: {
        margin: `${theme.spacing.unit}px 0`,
    },
});

class SearchResultRadio extends React.Component {
    state = {
        selectedLocation: null,
    };

    selectLocation = location => (event) => {
        const { changeFormField } = this.props;
        const position = Geocoder.getPosition(location);

        const locationFields = {
            address: Geocoder.getFullStreet(location),
            city: Geocoder.getCity(location),
            region: Geocoder.getRegion(location),
            postcode: Geocoder.getPostcode(location),
            'position.lat': position.lat,
            'position.lng': position.lng,
        };

        Object.keys(locationFields).forEach((key) => {
            const value = locationFields[key];

            if (value) {
                changeFormField(key, value);
            }
        });

        if (locationFields.region == null) {
            const promise = Geocoder.getLocationData(location);
            promise.then((data) => {
                const region = Geocoder.getRegionFromLocationData(data);
                if (region) {
                    changeFormField('region', region);
                }
            });
        }

        this.setState({ selectedLocation: location });
    }

    render() {
        const { classes, searchResult, translate } = this.props;
        const { selectedLocation } = this.state;


        if (searchResult === null) {
            return null;
        }

        const radioButtons = searchResult.map(location => (
            <FormControlLabel
                control={<Radio />}
                label={location.formatted_address}
                value={location.place_id}
                key={location.place_id}
                onChange={this.selectLocation(location)}
            />
        ));

        return (
            <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">
                        {translate('resources.storefinder.action.sel_location')}
                    </FormLabel>
                    <RadioGroup
                        aria-label={translate('resources.storefinder.fields.location')}
                        name="location"
                        value={selectedLocation ? selectedLocation.place_id : null}
                        className={classes.group}
                    >
                        {radioButtons}
                    </RadioGroup>
                </FormControl>
            </div>
        );
    }
}


SearchResultRadio.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => ({
    searchResult: state.search,
});

const mapDispatchToProps = {
    changeFormField: (field, value) => ReduxForm.change('record-form', field, value),
};


export default compose(
    translate,
    MaterialStyles.withStyles(styles),
    Redux.connect(mapStateToProps, mapDispatchToProps),
)(SearchResultRadio);

import { createAppReducer, defaultI18nProvider, USER_LOGOUT } from 'react-admin';
import { applyMiddleware, compose, createStore } from 'redux';


export default ({
    middlewares,
    customReducers = {},
    locale = 'en',
    i18nProvider = defaultI18nProvider,
}) => {
    const messages = i18nProvider(locale);
    const appReducer = createAppReducer(customReducers, locale, messages);

    const resettableAppReducer = (state, action) => appReducer([USER_LOGOUT].includes(action.type) ? undefined : state, action);

    return createStore(
        resettableAppReducer,
        {},
        compose(
            applyMiddleware(...middlewares),
            typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__
                ? window.__REDUX_DEVTOOLS_EXTENSION__()
                : f => f,
        ),
    );
};

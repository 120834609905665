import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'ra-ui-materialui';
import { firestoreDateToMillis, defaultDateOptions } from '../../utils/firebase';
import moment from 'moment';
import 'moment/locale/nb';

const FromDateField = ({
        record, source, options, ...rest
    }) => {
    moment.locale('nb');
    const timestamp = firestoreDateToMillis(record[source]);
    const dateToWords = moment(timestamp).fromNow();
    const simpleDate = new Date(timestamp);
    const dateString = simpleDate.toLocaleString('nb-NO', {...options, ...defaultDateOptions});

    return (
        <TextField
            title={ dateString }
            record={{ source: dateToWords }}
            source="source"
        />
    );
};

FromDateField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.node,
    rest: PropTypes.object,
};

export default FromDateField;

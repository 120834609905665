import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { ButtonSpinner } from './ButtonSpinner';


const SubmitButton = ({ isLoading, title }) => (
    <Button
        variant="raised"
        type="submit"
        color="primary"
        disabled={isLoading}
        fullWidth
    >
        {isLoading && <ButtonSpinner />}
        {title}
    </Button>
);

SubmitButton.propTypes = {
    isLoading: PropTypes.bool,
    title: PropTypes.string,
};

export default SubmitButton;

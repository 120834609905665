import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Button from '@material-ui/core/Button';

class ApiKeyInput extends Component {
    getApiKey = () => {
        let d = new Date().getTime();

        if (window.performance && typeof window.performance.now === 'function') {
            d += performance.now();
        }

        const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            const r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });

        return uuid;
    }

    generate = (onChange) => {
        const key = this.getApiKey();
        return onChange(key);
    }

    onCopy = () => {
        this.props.showNotification('resources.settings.notification.copied_success');
    }

    render() {
        const {
            input, label, meta: { touched, error }, translate, ...custom
        } = this.props;

        return (
            <div className="input-row">
                <TextField
                    label={label}
                    error={!!(touched && error)}
                    helperText={touched && error}
                    {...input}
                    {...custom}
                    style={{ width: '400px' }}
                />
                <Button
                    onClick={() => this.generate(input.onChange)}
                    variant="contained"
                    style={{ marginRight: '10px', marginLeft: '10px' }}
                >
                    {translate('utilities.generate')}
                </Button>
                <CopyToClipboard text={input.value} onCopy={this.onCopy}>
                    <Button
                        variant="contained"
                    >
                        {translate('utilities.copy_to_clipboard')}
                    </Button>
                </CopyToClipboard>
            </div>
        );
    }
}

export default ApiKeyInput;

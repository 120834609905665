var RESOURCES = {
  ORDERS: 'click_and_collect/orders',
  ORDERS_MESSAGES: 'click_and_collect/messages',
  ORDERS_ITEMS: 'click_and_collect/orders_items',
  ORDERS_STATUSES: 'click_and_collect/statuses',
  STORES: 'storefinder/stores',
  SETTINGS: 'settings',
  COMPANIES: 'companies',
  USERS: 'users',
  COUNTERS_SMS_DAILY: 'counters/sms/daily',
  COUNTERS_SMS_MONTHLY: 'counters/sms/monthly',
  COUNTERS_EMAIL_DAILY: 'counters/email/daily',
  COUNTERS_EMAIL_MONTHLY: 'counters/email/monthly',
  EXCHANGE: 'exchange',
  EXCHANGE_ORDERS_ITEMS: 'exchange/orders_items',
  OMNI_ORDERS: 'omni_orders',
  OMNI_ORDERS_ITEMS: 'omni_orders/orders_items'
};

var getPathBuilder = function getPathBuilder(companyId, documentId) {
  var companies = function companies() {
    return '/companies';
  };

  var settings = function settings() {
    return "/companies/".concat(companyId, "/settings");
  };

  var users = function users() {
    return "/companies/".concat(companyId, "/users");
  };

  var counters = function counters() {
    return "/companies/".concat(companyId, "/counters");
  };

  var storefinder = function storefinder() {
    return "/companies/".concat(companyId, "/apps/storefinder/stores");
  };

  var cac = function cac() {
    return "/companies/".concat(companyId, "/apps/click_and_collect/orders");
  };

  var ordersItems = function ordersItems() {
    return [cac(), documentId, 'items'].join('/');
  };

  var ordersMessages = function ordersMessages() {
    return [cac(), documentId, 'messages'].join('/');
  };

  var ordersStatuses = function ordersStatuses() {
    return [cac(), documentId, 'statuses'].join('/');
  };

  var countersSmsDaily = function countersSmsDaily() {
    return [companies(), companyId, 'counters', 'sms', 'daily'].join('/');
  };

  var countersSmsMonthly = function countersSmsMonthly() {
    return [companies(), companyId, 'counters', 'sms', 'monthly'].join('/');
  };

  var countersEmailDaily = function countersEmailDaily() {
    return [companies(), companyId, 'counters', 'email', 'daily'].join('/');
  };

  var countersEmailMonthly = function countersEmailMonthly() {
    return [companies(), companyId, 'counters', 'email', 'monthly'].join('/');
  };

  var exchangeOrderItems = function exchangeOrderItems() {
    return "/companies/".concat(companyId, "/apps/exchange/orders/").concat(documentId, "/items");
  };

  var omniOrders = function omniOrders() {
    return "/companies/".concat(companyId, "/apps/omni_orders/orders");
  };

  var omniOrdersItems = function omniOrdersItems() {
    return [omniOrders(), documentId, 'items'].join('/');
  };

  return {
    companies: companies,
    settings: settings,
    users: users,
    counters: counters,
    storefinder: storefinder,
    cac: cac,
    ordersItems: ordersItems,
    ordersMessages: ordersMessages,
    ordersStatuses: ordersStatuses,
    countersSmsDaily: countersSmsDaily,
    countersSmsMonthly: countersSmsMonthly,
    countersEmailDaily: countersEmailDaily,
    countersEmailMonthly: countersEmailMonthly,
    exchangeOrderItems: exchangeOrderItems,
    omniOrders: omniOrders,
    omniOrdersItems: omniOrdersItems
  };
};

module.exports = {
  RESOURCES: RESOURCES,
  getPathBuilder: getPathBuilder
};
import React from 'react';
import { Route } from 'react-router-dom';
import { counter } from '../utils/functions';
import Instructions from '../components/stores/Instructions';
import { SignUpForm } from '../components/signup';
import { NewPasswordForm, PasswordResetForm } from '../components/password';
import { EmailVerifyForm } from '../components/emailVerify';
import { SimpleLayoutContainer } from '../components/simpleLayout';


export const ROUTES = {
    SIGNUP: '/signup',
    RESET_PASSWORD: '/reset-password',
    USER_MGMT: '/usermgmt',
    INSTRUCTIONS: '/instructions',
    LOGIN: '/login',
};

const routes = id => ([
    <Route
        key={counter()}
        exact
        path={ROUTES.SIGNUP}
        component={() => <SimpleLayoutContainer form={<SignUpForm redirectTo={ROUTES.LOGIN} />} />}
        noLayout
    />,
    <Route
        key={counter()}
        exact
        path={ROUTES.RESET_PASSWORD}
        component={() => <SimpleLayoutContainer form={<PasswordResetForm />} />}
        noLayout
    />,
    <Route
        key={counter()}
        exact
        path={ROUTES.USER_MGMT}
        component={props => withMode(props.location.search, SimpleLayoutContainer)}
        noLayout
    />,
    <Route
        key={counter()}
        exact
        path={ROUTES.INSTRUCTIONS}
        component={() => <Instructions id={id} />}
    />,
]);

const withMode = (search, Component) => {
    if (!search) {
        return <div>Something went wrong ...</div>;
    }
    const params = new URLSearchParams(search);
    const mode = params.get('mode');
    const actionCode = params.get('oobCode');

    const formHandler = getFormHandler(mode);

    return <Component form={formHandler(actionCode)} />;
};

function getFormHandler(mode) {
    const forms = {
        resetPassword: actionCode => <NewPasswordForm actionCode={actionCode} />,
        verifyEmail: actionCode => <EmailVerifyForm actionCode={actionCode} />,
    };

    const formHandler = forms[mode];
    if (!formHandler) {
        return () => <div>Something went wrong ...</div>;
    }
    return formHandler;
}

export default routes;

var NOTIFICATION = {
  INFO: 'info',
  WARNING: 'warning'
};
var STATUSES = {
  NEW: 'new',
  PICKING: 'picking',
  DELIVERED_PARTLY: 'deliveredPartly',
  WAITING_PICKUP: 'waitingForPickUp',
  PICKEDUP: 'pickedup',
  CANCELLED: 'cancelled',
  ON_HOLD: 'onHold'
};
var MESSAGES = {
  MANUAL: 'manual',
  RETRIEVAL: 'retrieval',
  CANCELLATION: 'cancellation',
  REMINDER: 'reminder'
};
var CHANNELS = {
  ALL: 'all',
  SMS: 'sms',
  EMAIL: 'email'
};
var PERMISSIONS = {
  OWNER: 'owner',
  ADMIN: 'admin'
};
var OMNI_ORDER_STATUSES = {
  NEW: 'new',
  LOCKED: 'locked',
  CONFIRMED: 'confirmed',
  ON_HOLD: 'onHold'
};
module.exports = {
  NOTIFICATION: NOTIFICATION,
  STATUSES: STATUSES,
  MESSAGES: MESSAGES,
  CHANNELS: CHANNELS,
  PERMISSIONS: PERMISSIONS,
  OMNI_ORDER_STATUSES: OMNI_ORDER_STATUSES
};
import React from 'react';
import PropTypes from 'prop-types';

const ItemImage = ({ imageUrl }) => (
    imageUrl && (
        <div>
            <img src={imageUrl} width={40} alt="" />
        </div>
    )
);

ItemImage.propTypes = {
    imageUrl: PropTypes.string,
};

export default ItemImage;

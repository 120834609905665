import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/es/Typography/Typography';
import { priceRenderer } from './PriceRenderer';
import ItemAttributeColumn from './ItemAttributeColumn';


const ItemInfo = ({
    data, classes, translate, children,
}) => (
    <div className={classes.infoContainer}>
        <Typography variant="headline" className={classes.itemName}>{data.name}</Typography>
        <div>
            <ItemAttributeColumn property="SKU">
                {data.sku}
            </ItemAttributeColumn>
            <ItemAttributeColumn property={translate('resources.omni_orders.fields.quantity')}>
                {data.qtyOrdered}
            </ItemAttributeColumn>
            {data.attributes.map(attribute => (
                <ItemAttributeColumn
                    key={attribute.name}
                    property={attribute.name}
                >
                    {attribute.value}
                </ItemAttributeColumn>
            ))}
            <ItemAttributeColumn property={translate('resources.omni_orders.fields.price')}>
                {priceRenderer(data.priceInclTax, data.originalPrice)}
            </ItemAttributeColumn>
            <ItemAttributeColumn
                property={data.availableInWarehouse
                    ? translate('resources.omni_orders.fields.inWarehouse', { quantity: data.stockInWarehouse })
                    : translate('resources.omni_orders.fields.emptyOnline')
                }
            >
                {children}
            </ItemAttributeColumn>
        </div>
    </div>
);

ItemInfo.propTypes = {
    data: PropTypes.object,
    classes: PropTypes.object,
    translate: PropTypes.func,
    children: PropTypes.object,
};

export default ItemInfo;

import {
    Datagrid, EmailField, NumberField, TextField,
} from 'react-admin';
import React from 'react';
import TranslatedGridField from '../../grid/TranslatedGridField';
import { FirestoreDateField } from '../../fields';


export const gridMedium = (translate, classes) => (
    <Datagrid rowClick="edit">
        <TextField source="externalOrderId" label={translate('resources.orders.fields.order_id')} />
        <TextField source="storeName" label={translate('resources.orders.fields.store')} className="breakWord" />
        <EmailField
            source="customer.email"
            label={translate('resources.orders.fields.email')}
            cellClassName={classes.truncateText100}
        />
        <TranslatedGridField source="status" translate={translate} pathPart="resources.orders.data" />
        <FirestoreDateField
            source="createdAt"
            label={translate('resources.orders.fields.createdAt')}
            options={{ month: 'numeric' }}
        />
        <NumberField
            source="total"
            locales="nb-NO"
            label={translate('resources.orders.fields.total')}
            options={{
                style: 'currency',
                currency: 'NOK',
            }}
        />
    </Datagrid>
);

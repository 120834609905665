/* global google */
// global annotation is needed because Google maps is loaded from index.html


const lookup = (request) => {
    const geocoder = new google.maps.Geocoder();

    return new Promise(((resolve, reject) => {
        geocoder.geocode(request, (res, status) => {
            if (status === 'OK') {
                resolve(res);
            } else {
                reject({ result: res, status });
            }
        });
    }));
};

const getComponentLongName = (result, componentType) => {
    const addresses = result.address_components.filter(x => x.types.indexOf(componentType) !== -1);

    if (addresses.length > 0) {
        return addresses[0].long_name;
    }

    return null;
};

const getStreetName = result => getComponentLongName(result, 'route');

const getCity = result => getComponentLongName(result, 'postal_town');

const getPostcode = result => getComponentLongName(result, 'postal_code');

// TODO: take country as argument
const getRegion = result => getComponentLongName(result, 'administrative_area_level_1');

const getLocationData = async result => lookup({ location: result.geometry.location });

const getRegionFromLocationData = (result) => {
    let region = '';
    Object.keys(result).forEach((key) => {
        if (!result[key].types.includes('administrative_area_level_1')) {
            return;
        }

        region = getComponentLongName(result[key], 'administrative_area_level_1');
    });

    return region;
};

const getStreetNumber = result => getComponentLongName(result, 'street_number');

const getFullStreet = (result) => {
    const street = getStreetName(result);
    const number = getStreetNumber(result);

    if (street && number) {
        return `${street} ${number}`;
    }

    return street;
};

const getPosition = result => ({
    lat: result.geometry.location.lat(),
    lng: result.geometry.location.lng(),
});


export default {
    lookup,
    getCity,
    getPostcode,
    getRegion,
    getLocationData,
    getRegionFromLocationData,
    getFullStreet,
    getPosition,
};

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import React from 'react';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBox from '@material-ui/icons/CheckBox';

const ItemCheckbox = ({
    data, onChange, checked, disabled, classes, label,
}) => {
    const { availableInWarehouse } = data;

    if (availableInWarehouse) {
        return (
            <FormControlLabel
                classes={{ root: classes.label }}
                control={(
                    <Checkbox
                        className={classes.checkbox}
                        checked={checked}
                        icon={<CheckBoxOutlineBlank className={classes.iconSize} />}
                        checkedIcon={<CheckBox className={classes.iconSize} />}
                        onChange={onChange}
                        disabled={disabled}
                    />
                )}
                label={label}
            />

        );
    }
    return (
        <FormControlLabel
            classes={{ root: classes.label }}
            control={(
                <Checkbox
                    checked
                    disabled={disabled}
                    className={classes.checkbox}
                    icon={<CheckBoxOutlineBlank className={classes.iconSize} />}
                    checkedIcon={<CheckBox className={classes.iconSize} />}
                />
            )}
            label={label}
        />

    );
};

ItemCheckbox.propTypes = {
    data: PropTypes.object,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    classes: PropTypes.object,
    label: PropTypes.string,
};

export default ItemCheckbox;

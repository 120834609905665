import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import NumericInput from 'react-numeric-input';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { translate } from 'react-admin';
import isEmpty from 'lodash/isEmpty';
import withStyles from '@material-ui/core/styles/withStyles';
import { orderItemPick, orderItemUnpick } from '../ordersActions';
import { quantityPicker } from '../../../services/orders';
import { componentLoading } from '../../componentLoading';
import { ToggleButton } from '../../input';

const style = {
    wd6: {
        minWidth: '6em', boxShadow: 'none', height: '38px',
    },
    wrap: { verticalAlign: 'middle' },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
};

const numericInputStyle = {
    input: {
        maxWidth: '75px',
        height: '38px',
        textAlign: 'center',
        borderRadius: '4px',
    },
};

const sanitizeQuantityProps = Comp => ({ record, ...rest }) => (
    isEmpty(record) ? null
        : (
            <Comp {...{
                ...rest,
                record: {
                    ...record,
                    qtyAvailable: parseFloat(record.qtyAvailable),
                    qtyOrdered: parseFloat(record.qtyOrdered),
                    qtyPicked: parseFloat(record.qtyPicked),

                },
            }}
            />
        )
);

export class QuantityInputField extends React.Component {
    state = {
        inputValue: this.props.record.qtyAvailable,
    }

    pick = () => {
        const {
            record, order, orderItemPick, setComponentLoading,
        } = this.props;
        const { inputValue } = this.state;

        const picker = quantityPicker(record);
        const recordAfterPicking = picker.pick(inputValue);

        orderItemPick(this.formatActionObject(recordAfterPicking, order));
        setComponentLoading();

        this.setState({ inputValue: recordAfterPicking.qtyAvailable });
    }

    unpick = () => {
        const {
            record, order, orderItemUnpick, setComponentLoading,
        } = this.props;

        const picker = quantityPicker(record);

        orderItemUnpick(this.formatActionObject(picker.reset(), order));
        setComponentLoading();

        this.setState({ inputValue: record.qtyOrdered });
    }

    formatActionObject = (record, order) => (
        {
            data: {
                ...record,
            },
            id: record.id,
            parentId: order.id,
        }
    )

    handleInputChange = value => this.setState({ inputValue: parseFloat(value) })

    renderButtonToPick = () => {
        const { translate, isComponentLoading, classes } = this.props;

        return (
            <ToggleButton
                onClick={() => this.pick()}
                isLoading={isComponentLoading}
                title={translate('resources.orders.actions.pick')}
                color="secondary"
                className={['remove-from-print', classes.wd6]}
            />
        );
    }

    renderButtonToRemove = () => {
        const { translate, isComponentLoading, classes } = this.props;

        return (
            <ToggleButton
                onClick={() => this.unpick()}
                isLoading={isComponentLoading}
                title={translate('resources.orders.actions.remove')}
                className={[['remove-from-print', classes.wd6]]}
            />
        );
    }

    render() {
        // record is injected by react-admin
        const { record, order, classes } = this.props;
        const { inputValue } = this.state;
        const { picked, qtyAvailable } = record;

        if (!record || !order) {
            return null;
        }

        return (
            <Fragment>
                <div className={classes.container}>
                    <NumericInput
                        min={0}
                        max={qtyAvailable}
                        value={inputValue}
                        onChange={this.handleInputChange}
                        style={numericInputStyle}
                        size={4}
                        strict
                    />
                    {picked ? this.renderButtonToRemove() : this.renderButtonToPick()}
                </div>
            </Fragment>
        );
    }
}

QuantityInputField.defaultProps = {
    record: {
        qtyAvailable: 0,
        qtyPicked: 0,
        qtyOrdered: 0,
    },
};

QuantityInputField.propTypes = {
    record: PropTypes.object,
    order: PropTypes.object,
    orderItemUnpick: PropTypes.func,
    orderItemPick: PropTypes.func,
    translate: PropTypes.func,
    isComponentLoading: PropTypes.bool,
    setComponentLoading: PropTypes.func,
    classes: PropTypes.object,
};

const enhance = compose(
    withStyles(style),
    componentLoading,
    translate,
    connect(null, { orderItemPick, orderItemUnpick }),
    sanitizeQuantityProps,
);

export default enhance(QuantityInputField);

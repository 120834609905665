import norwegianMessages from 'ra-language-norwegian';

export default {
    ...norwegianMessages,
    utilities: {
        copy_to_clipboard: 'kopiere til utklippstavle',
        generate: 'generere',
        select: 'Velg',
        edit: 'Redigere',
        add: 'Legg til',
        print: 'Skriv ut',
        or: 'eller',
        loading: 'Vent...',
    },
    resources: {
        auth: {
            fields: {
                email: 'Epost',
                companyName: 'Selskapsnavn',
                mobile: 'Telefon',
                fullName: 'Fullt navn',
            },
            actions: {
                or: 'eller',
                signIn: 'Logg inn',
                submit: 'Send inn',
                create_account: 'Opprett konto',
                forgot_pass: 'Glemt passordet?',
                reset_pass: 'Skriv inn epost-adressen din for å tilbakestille passordet ditt',
                change_password: 'Change password',
            },
            notification: {
                inbox_verification: 'Vennligst sjekk innboksen din for bekreftelses-e-post',
                inbox_reset: 'Vennligst sjekk innboksen din for tilbakestilt e-post',
                new_password: 'Password changed. You can now sign in with your new password',
            },
            data: {
                reset_password: 'Reset your password for email:',
                pass_verified: 'Email has been verified',
                can_login: 'You can login now',
                pass_not_verified: 'Email has not been verified.',
                verification_error: 'Verification link is expired or the request is bad.',
                retry: 'prøve på nytt',
            },
        },
        orders: {
            name: 'Ordre |||| Bestillinger',
            fields: {
                store: 'Butikk',
                createdAt: 'Opprettet',
                pickup_deadline: 'Hentefrist',
                notice: 'Notis',
                customer_information: 'Kundeinformasjon',
                order_id: 'Ordre ID',
                email: 'Epost',
                phone: 'Telefon',
                status: 'Status',
                total: 'Total',
                name: 'Navn',
                qtyOrdered: 'Antall bestilt',
                qtyAvailable: 'Antall tilgjengelig',
                qtyPicked: 'Antall plukket',
                price: 'Pris',
                picked: 'Plukket',
                available: 'Antall',
                size: 'Størrelse',
                color: 'Farge',
                attributes: 'Egenskaper',
            },
            actions: {
                pick: 'Plukk',
                remove: 'Angre',
                send_retrieval_msg: 'Send hentemelding',
                picked_up: 'Hentet',
                cancel_order: 'Kanseller ordre',
                on_hold: 'Sett på vent',
                showActive: 'Vis aktiv',
                showArchived: 'Vis arkivert',
            },
            tabs: {
                items: 'Elementer',
                notifications: 'Varslinger',
                notif_history: 'Historikk',
                status_history: 'Statushistorikk',
            },
            page: {
                delete: 'Slett',
            },
            data: {
                new: 'Ny',
                picking: 'Plukkes',
                deliveredPartly: 'Levert delvis',
                waitingForPickUp: 'Venter på henting',
                pickedup: 'Hentet',
                cancelled: 'Avbrutt',
                onHold: 'På vent',
            },
            notification: {
                picked_success: 'Plukket',
                picked_failure: 'Feil plukking element',
                unpicked_success: 'Fjernet',
                unpicked_failure: 'Feil ved fjerning av element',
                pickedup_success: 'Order hentet',
                action_failure: 'Handlingsfeil',
                cancelled_success: 'Order er kansellert',
                onHold_success: 'Order er på vent',
            },
        },
        messages: {
            name: 'Innhold |||| Meldinger',
            fields: {
                date: 'Dato',
                type: 'Type',
                recipient: 'Mottaker',
                channel: 'Medium',
                status: 'Status',
                content: 'Status',
            },
            actions: {
                send: 'Send',
                send_retrieval: 'Send hentemelding',
                send_as: 'Send som',
                only_email: 'Epost',
                only_sms: 'SMS',
                all: 'Begge',
                sent: 'Sendt',
                delivered: 'Levert',
                bounced: 'Sprette',
                error: 'Feil',
                pending: 'Overhengende',
                not_sent: 'Ikke sendt',
            },
            notification: {
                channel_error: 'Vennligst velg kanal',
                channels_disabled: 'All notification sending methods are disabled. Please check notifications settings.',
                sent: 'Melding sendt',
                sending_failure: 'Kunne ikke sende melding',
                empty: 'Empty',
            },
            data: {
                lastRetrievalSent: 'Sist hentemelding sendt på',
                new_order: 'ny bestilling',
                retrieval: 'hente',
                manual: 'manuell',
                cancellation: 'kansellering',
                email: 'e-post',
                sms: 'tekstmelding',
                reminder: 'påminnelse',
            },
        },
        users: {
            name: 'Bruker |||| Brukere',
            fields: {
                fullName: 'Fullt navn',
                email: 'Epost',
                mobile: 'Telefon',
                createdAt: 'Opprettet',
                storeIds: 'Butikker',
                owner: 'Eieren',
            },
            notification: {
                created: 'Bruker opprettet',
                create_failure: 'Feil å opprette bruker',
            },
        },
        settings: {
            name: 'Innstillinger',
            fields: {
                templateId: 'Mal alias',
                apiUrl: 'API Url',
                accessToken: 'Access token',
                cancelAfter: 'Hentefrist (timer)',
                cancelAfterDeadline: 'Avbestille etter tidsfrist',
                newOrderCustomer: {
                    email: {
                        enabled: 'Informer kunden via epost',
                        templateId: 'Mal alias',
                    },
                    sms: {
                        enabled: 'Informer kunden via SMS',
                        template: 'SMS melding',
                    },
                },
                newOrderStore: {
                    email: {
                        enabled: 'Varsle butikk via epost',
                        templateId: 'Mal alias',
                    },
                    sms: {
                        enabled: 'Varsle butikk via SMS',
                        template: 'SMS melding',
                    },
                },
                retrievalMessage: {
                    email: {
                        enabled: 'Send hentemelding som epost',
                        templateId: 'Mal alias',
                    },
                    sms: {
                        enabled: 'Send hentemelding som SMS',
                        template: 'SMS budskap',
                    },
                },
                manualMessage: {
                    email: {
                        templateId: 'Epost mal alias',
                    },
                    sms: {
                        template: 'SMS melding',
                    },
                },
                cancellationMessage: {
                    email: {
                        enabled: 'Send avbestillingsmelding som epost',
                        templateId: 'Mal alias',
                    },
                    sms: {
                        enabled: 'Send avbestillingsmelding som SMS',
                        template: 'SMS melding',
                    },
                },
                pickupReminder: {
                    email: {
                        enabled: 'Send påminnelse som epost',
                        templateId: 'Mal alias',
                    },
                    sms: {
                        enabled: 'Send påminnelse som SMS',
                        template: 'SMS melding',
                    },
                },
                smsService: {
                    senderName: 'Avsenders navn',
                    username: 'Brukernavn',
                    password: 'Passord',
                },
                api: {
                    key: 'API nøkkel',
                },
                sms_credentials: 'SMS-tjeneste',
                sms_variables: 'SMS-malvariabler',
            },
            omni_orders: {
                notify_stores: 'Varsle butikker',
                notify_recipients: 'Varsle mottakere',
                recipients: 'Mottakere (kommaseparert)',
            },
            data: {
                click_and_collect: 'Klikk og hent',
                omni_orders: 'Omni Orders',
                exchange: 'Byttelapp',
                store_api: 'Store API',
                storefinder: 'Storefinder',
            },
            notification: {
                copied_success: 'Kopiert. Vennligst ikke glem å lagre',
            },
            tabs: {
                general: 'Generell',
                new_order: 'Ny bestilling',
                retrieval_message: 'Hentemelding',
                manual_message: 'Manuell melding',
                cancellation_message: 'Avbestillingsmelding',
                overdue_orders: 'Forfalte ordrer',
                pickup_reminder: 'Påminnelse',
            },
        },
        companies: {
            name: 'Firma |||| Firmaer',
            fields: {
                name: 'Navn',
                contact: {
                    email: 'E-post',
                },
            },
            tabs: {
                visible_features: 'Aktiverte funksjoner',
            },
        },
        dashboard: {
            name: 'Dashbord',
            welcome: 'Velkommen til',
            description: 'OmniCloud - connecting Commerce and eCommerce',
        },
        cac: {
            name: 'Klikk og hent',
            fields: {
                customers: 'Kunder',
                name: 'Navn',
            },
        },
        statuses: {
            name: 'Statushistorikk',
            fields: {
                date: 'Dato',
                status: 'Status',
            },
        },
        instructions: {
            name: 'Bruksanvisning',
            data: {
                paste: 'Lim inn denne markeringen i Raw HTML-elementet til WPBakery-siden byggeren:',
                checkboxes: 'Fordi kartforhandler-plugin tar opp hele bredden på skjermen, må marginene nullstilles. For å gjøre det, sjekk følgende bokser:\n',
                city: 'For å gruppere butikker bare etter by, sett inn ovenstående oppføring:',
                store: 'Ikke gruppeliste',
                countries: 'Det er mulig å overstyre det ved å notere land som dette',
                default_countries: 'Standardlinjens søkefelt er begrenset til landet som er definert i lokalområdet (nb_NO -> NO)',
                zoom: 'Angi ekstra zoomnivå. 0 er standard:',
            },
        },
        storefinder: {
            name: 'Butikker',
            fields: {
                name: 'Navn',
                address: 'Adresse',
                city: 'By',
                created: 'Opprettet',
                showOnMap: 'Synlig på kartet',
                country: 'Land',
                type: 'Type',
                region: 'Fylke',
                postcode: 'Postnr',
                location: 'Plassering',
                latitude: 'Breddegrad',
                longitude: 'Lengdegrad',
                phone: 'Telefon SMS Klikk&Hent',
                phoneToDisplay: 'Telefon som vises på “Finn butikk”',
                email: 'Epost',
                printerId: 'Skriver-ID',
                openingHours: {
                    monday: 'Mandag',
                    tuesday: 'Tirsdag',
                    wednesday: 'Onsdag',
                    thursday: 'Torsdag',
                    friday: 'Fredag',
                    saturday: 'Lørdag',
                    sunday: 'Søndag',
                    additionalInformation: 'Tilleggsinformasjon',
                },
                organizationNumber: 'Organisasjonsnummer',
                externalId: 'Eksternt ID',
                externalUrl: 'Ekstern nettadresse',
                isCacEnabled: 'CAC aktivert',
                isStorepickupEnabled: 'Storepickup aktivert',
                googleInventoryAdsId: 'Google Local Inventory Ads ID',
                image: 'Bilde',
                richInfo: 'Formatert informasjon',
            },
            tabs: {
                store: 'Butikk',
                openingHours: 'Åpningstider',
                misc: 'Diverse',
            },
            data: {
                norway: 'Norge',
                sweden: 'Sverige',
                denmark: 'Danmark',
                ireland: 'Irland',
                germany: 'Tyskland',
                great_britain: 'Storbritannia',
            },
            action: {
                sel_location: 'Velg sted',
                new_store: 'Opprett ny butikk',
                find_address: 'Finn adresse',
                verify_address: 'Bekreft adressen',
            },
            notification: {
                latLng: 'Sørg for at butikken har gyldige koordinater',
            },
        },
        exchange: {
            name: 'Byttelapp',
            fields: {
                exchange_code: 'Byttelapp-kode',
                order_number: 'Ordrenummer',
                order: 'Ordre',
                created_at: 'Bestilt',
                valid_until: 'Gyldig til',
                customer: 'Kunde',
                items: 'Vare',
                quantity: 'Antall',
                item_total: 'Betalt',
                items_sum: 'Sum, varer',
                shipping_amount: 'Frakt og levering',
                discount_amount: 'Rabatt',
                vat: 'MVA',
                total: 'Totalt',
                time: 'Tidspunkt',
                place: 'Sted',
                action: 'Handling',
                comment: 'Kommentar',
                reason: 'Årsak',
                date: 'Dato',
            },
            actions: {
                change: 'Hent',
                register: 'Registrer',
                exchange: 'Bytte',
                return: 'Retur',
            },
            tabs: {
                form: 'Hent opp byttelapp:',
                history: 'Historikk',
                registerItem: 'Registrering',
            },
            data: {
                valid: 'gyldig',
                invalid: 'UTGÅTT',
                exchange: 'Bytte',
                return: 'Retur',
                itemRegistered: 'Allerede registrert. Se historikk',
                history_empty: 'Historien er tom',
            },
            notification: {
                item_registered: 'Lagret',
                register_failure: 'Kunne ikke registrere varen',
                fetch_failed: 'Kunne ikke hente ordren',
            },
        },
        omni_orders: {
            name: 'OmniOrders',
            fields: {
                order_number: 'Ordrenummer',
                order: 'Ordre',
                created_at: 'Opprettet',
                customer: 'Kunde',
                quantity: 'Antall',
                status: 'Status',
                pick_deadline: 'Plukkefrist',
                region: 'Fylke',
                name: 'Navn',
                price: 'Pris',
                store: 'Butikk',
                size: 'Størrelse',
                color: 'Farge',
                available: 'tilgjengelig på sentrallager',
                phone: 'Telefon',
                quantityInWarehouse: 'Tilgjengelig i sentrallager',
                emptyOnline: 'Tomt på nettlager',
                inWarehouse: '%{quantity} stk. på nettlager',
                packagesQty: 'Antall kolli',
                totalWeight: 'Totalvekt',
                deliver: 'Vi leverer denne!',
                locked_by: 'Låst av',
            },
            tabs: {
            },
            data: {
                open: 'Åpne',
                lock: 'Ta denne ordren',
                unlock: 'Gi fra deg denne',
                confirm_and_print: 'Bekreft og skriv ut fraktetikette',
                reprint: 'Skriv ut fraktetikett',
                new: 'Ny',
                locked: 'Låst',
                on_hold: 'På vent',
                onHold: 'På vent',
                confirmed: 'Bekreftet',
                restart: 'Omstart',
                ran_out: 'Tiden rant ut',
                confirm: 'Bekreft',
            },
            notification: {
                lock_failure: 'Failed to lock order',
                confirm_failure: 'Failed to confirm order',
                print_failure: 'Failed to generate shipment label order',
                restart_failure: 'Failed to restart order',
                confirmed_info: 'Forsendelsen ble overført til Consignor for behandling',
                confirmed_notice: 'Kontakt sentrallageret hvis du opplever problemer med skriver- eller forsendelsesetiketter',
            },
        },
    },
};

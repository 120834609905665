const isoCountries = require('i18n-iso-countries');
isoCountries.registerLocale(require('i18n-iso-countries/langs/no.json'));

const allCountries = isoCountries.getNames('no');

const countries = [];

const keys = Object.keys(allCountries);
const values = Object.values(allCountries);
for (let i = 0; i < keys.length; i += 1) {
    countries.push({ id: keys[i], name: values[i] });
}

export default countries;

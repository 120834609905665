import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchEnd, fetchStart } from 'react-admin';
import { applyActionCode } from '../../services/auth';
import { EMAIL_VERIFY_START, emailVerifyFailure, emailVerifySuccess } from './emailVerifyActions';

export function* handleVerify({ payload: { actionCode } }) {
    try {
        yield put(fetchStart());
        yield call(applyActionCode, actionCode);
        yield put(emailVerifySuccess());
    } catch (e) {
        yield put(emailVerifyFailure());
    } finally {
        yield put(fetchEnd());
    }
}


export default function* () {
    yield takeEvery(EMAIL_VERIFY_START, handleVerify);
}

import { call, put, takeEvery } from 'redux-saga/effects';
import geocoder from './geocode';

function* geocodeLookup(action) {
    try {
        const result = yield call(geocoder.lookup, action.payload);
        yield put({ type: 'GEOCODE_LOOKUP_SUCCEEDED', payload: result });
    } catch (e) {
        console.log('failure', e);
        yield put({ type: 'GEOCODE_LOOKUP_FAILED', message: e.message });
    }
}

export default function* search() {
    yield takeEvery('GEOCODE_LOOKUP_REQUESTED', geocodeLookup);
}

import React from 'react';
import PropTypes from 'prop-types';
import {
    Datagrid, Edit, NumberField, ReferenceManyField, TextField, translate,
} from 'react-admin';
import QuantityInputField from './QuantityInputField';
import { OrderItemAttributesField } from '../../fields/OrderItemAttributeField';


const CommandTitle = translate(({ record, translate }) => (
    <span>
        {translate('resources.orders.name', { smart_count: 1 })}
        &nbsp;#
        {record.externalOrderId ? record.externalOrderId.toUpperCase() : 'N/A'}
    </span>
));


const sanitizeRestProps = ({
    lastRefresh, lastRetrievalMessage, orderId, ...rest
}) => rest;

const OrdersItemGrid = ({
    translate, order, ...rest
}) => (
    <Edit title={<CommandTitle />} {...sanitizeRestProps(rest)}>
        <ReferenceManyField
            reference="click_and_collect/orders_items"
            target="relatedTo"
            label="Items"
        >
            <Datagrid>
                <TextField source="sku" className="breakWord" />
                <TextField source="name" label={translate('resources.orders.fields.name')} className="breakWord" />
                <OrderItemAttributesField
                    source="customAttributes"
                    label={translate('resources.orders.fields.attributes')}
                />
                <NumberField
                    source="qtyOrdered"
                    label={translate('resources.orders.fields.qtyOrdered')}
                />
                <NumberField
                    source="price"
                    label={translate('resources.orders.fields.price')}
                    locales="nb-NO"
                    options={{
                        style: 'currency',
                        currency: 'NOK',
                    }}
                    className="status"
                />
                <NumberField
                    source="total"
                    label={translate('resources.orders.fields.total')}
                    locales="nb-NO"
                    options={{
                        style: 'currency',
                        currency: 'NOK',
                    }}
                    className="status"
                />
                <NumberField
                    source="qtyPicked"
                    label={translate('resources.orders.fields.qtyPicked')}
                />
                <QuantityInputField
                    label={translate('resources.orders.fields.qtyAvailable')}
                    order={order}
                />
            </Datagrid>
        </ReferenceManyField>
    </Edit>
);

OrdersItemGrid.propTypes = {
    translate: PropTypes.func,
    order: PropTypes.object,
};

export default translate(OrdersItemGrid);

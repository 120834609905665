export const CODE_VERIFY_START = 'CODE_VERIFY_START';
export const CODE_VERIFY_SUCCESS = 'CODE_VERIFY_SUCCESS';
export const CODE_VERIFY_FAILURE = 'CODE_VERIFY_FAILURE';


export const codeVerifyStart = actionCode => ({
    type: CODE_VERIFY_START,
    payload: actionCode,
});

export const codeVerifySuccess = emailAddress => ({
    type: CODE_VERIFY_SUCCESS,
    payload: emailAddress,
});

export const codeVerifyFailure = errorMessage => ({
    type: CODE_VERIFY_FAILURE,
    payload: errorMessage,
});

export const PASSWORD_CHANGE_START = 'PASSWORD_CHANGE_START';
export const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS';

export const passwordChangeStart = password => ({
    type: PASSWORD_CHANGE_START,
    payload: password,
});

export const passwordChangeSuccess = () => ({
    type: PASSWORD_CHANGE_SUCCESS,
});

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import compose from 'recompose/compose';
import { createStructuredSelector } from 'reselect';
import { translate } from 'react-admin';
import { setUserCompanyId } from './companiesActions';
import { getCompanyId } from './companiesSelectors';

class SelectCompanyButton extends Component {
    handleClick = (e) => {
        e.stopPropagation();
        const { setUserCompanyId, record: { id } } = this.props;
        setUserCompanyId({ id });
    }

    isDisabled = () => {
        const { record: { id }, companyId } = this.props;
        return companyId === id;
    }

    render() {
        const { translate } = this.props;
        return (
            <Button variant="outlined" size="small" onClick={this.handleClick} disabled={this.isDisabled()}>
                {translate('utilities.select')}
            </Button>
        );
    }
}

SelectCompanyButton.propTypes = {
    setUserCompanyId: PropTypes.func,
    record: PropTypes.object,
    translate: PropTypes.func,
    companyId: PropTypes.string,
};

const enhance = compose(
    translate,
    connect(createStructuredSelector({
        companyId: getCompanyId,
    }),
    { setUserCompanyId }),
);

export default enhance(SelectCompanyButton);

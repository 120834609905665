import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SaveButton } from 'react-admin';
import uniq from 'lodash/uniq';
import { createStoreUser } from './usersActions';
import { getCompanyId } from '../companies';

class SaveWithStoreFilter extends Component {
    handleClick = () => {
        const {
            basePath, handleSubmit, redirect, createStoreUser, companyId,
        } = this.props;


        return handleSubmit((values) => {
            createStoreUser({
                ...values,
                storeIds: uniq(values.storeIds),
                companyId,
            });
        });
    };

    render() {
        const { handleSubmitWithRedirect, createStoreUser, ...props } = this.props;

        return (
            <SaveButton
                handleSubmitWithRedirect={this.handleClick}
                {...props}
            />
        );
    }
}

const mapStateToProps = state => ({
    companyId: getCompanyId(state),
});

export default connect(
    mapStateToProps,
    { createStoreUser },
)(SaveWithStoreFilter);

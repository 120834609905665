import React from 'react';
import PropTypes from 'prop-types';
import {
    List, Responsive, translate,
} from 'react-admin';
import compose from 'recompose/compose';
import { Filters } from './Filters';
import { gridLarge } from './gridLarge';

const OrdersList = (
    props,
) => {
    const { translate } = props;
    return (
        <Responsive
            medium={(
                <List
                    {...props}
                    title={translate('resources.omni_orders.name', { smart_count: 2 })}
                    sort={{ field: 'orderId', order: 'DESC' }}
                    filters={<Filters translate={translate} />}
                    perPage={5}
                    bulkActionButtons={false}
                >
                    {gridLarge(props)}
                </List>
            )}
            large={(
                <List
                    {...props}
                    title={translate('resources.omni_orders.name', { smart_count: 2 })}
                    sort={{ field: 'orderId', order: 'DESC' }}
                    filters={<Filters translate={translate} />}
                    perPage={5}
                    bulkActionButtons={false}
                >
                    {gridLarge(props)}
                </List>
            )}
        />

    );
};

OrdersList.propTypes = {
    translate: PropTypes.func,
};

const enhance = compose(
    translate,
);

export default enhance(OrdersList);

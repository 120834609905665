import { CREATE, UPDATE } from 'react-admin';
import { RESOURCES } from 'omni-shared/resources';
import { NOTIFICATION, STATUSES } from 'omni-shared/constants';

export const ORDER_ITEM_PICK = 'ORDER_ITEM_PICK';
export const ORDER_ITEM_UNPICK = 'ORDER_ITEM_UNPICK';
export const SEND_STATUS_CHANGE_MESSAGE = 'SEND_STATUS_CHANGE_MESSAGE';
const ORDER_STATUS_CANCELED = 'ORDER_STATUS_CANCELED';
const ORDER_STATUS_PICKEDUP = 'ORDER_STATUS_PICKEDUP';
const ORDER_STATUS_ON_HOLD = 'ORDER_STATUS_ON_HOLD';
const ORDER_STATUS_WAITING_PICKUP = 'ORDER_STATUS_WAITING_PICKUP';
const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_MANUAL_MESSAGE = 'SEND_MANUAL_MESSAGE';

export const sendStatusChangeMessage = ({ id, type }) => ({
    type: SEND_STATUS_CHANGE_MESSAGE,
    payload: { id, type },
});

export const sendManualMessage = ({ orderId, channel, content }) => ({
    type: SEND_MANUAL_MESSAGE,
    payload: { orderId, channel, content },
});

export const orderItemPick = payload => ({
    type: ORDER_ITEM_PICK,
    payload: { ...payload },
    meta: {
        fetch: UPDATE,
        resource: RESOURCES.ORDERS_ITEMS,
        onSuccess: {
            notification: {
                body: 'resources.orders.notification.picked_success',
                level: NOTIFICATION.INFO,
            },
        },
        onFailure: {
            notification: {
                body: 'resources.orders.notification.action_failure',
                level: NOTIFICATION.WARNING,
            },
        },
    },
});

export const orderItemUnpick = payload => ({
    type: ORDER_ITEM_UNPICK,
    payload: { ...payload },
    meta: {
        fetch: UPDATE,
        resource: RESOURCES.ORDERS_ITEMS,
        onSuccess: {
            notification: {
                body: 'resources.orders.notification.unpicked_success',
                level: NOTIFICATION.INFO,
            },
        },
        onFailure: {
            notification: {
                body: 'resources.orders.notification.action_failure',
                level: NOTIFICATION.WARNING,
            },
        },
    },
});

export const setOrderStatusPickedup = ({ id, data }) => ({
    type: ORDER_STATUS_PICKEDUP,
    payload: { id, data: { ...data, status: STATUSES.PICKEDUP } },
    meta: {
        fetch: UPDATE,
        resource: RESOURCES.ORDERS,
        onSuccess: {
            notification: {
                body: 'resources.orders.notification.pickedup_success',
                level: NOTIFICATION.INFO,
            },
        },
        onFailure: {
            notification: {
                body: 'resources.orders.notification.action_failure',
                level: NOTIFICATION.WARNING,
            },
        },
        redirectTo: `/${RESOURCES.ORDERS}`,
    },
});

export const setOrderStatusCanceled = ({ id, data }) => ({
    type: ORDER_STATUS_CANCELED,
    payload: { id, data: { ...data, status: STATUSES.CANCELLED } },
    meta: {
        fetch: UPDATE,
        resource: RESOURCES.ORDERS,
        onFailure: {
            notification: {
                body: 'resources.orders.notification.action_failure',
                level: 'warning',
            },
        },
    },
});

export const setOrderStatusWaitingPickup = ({ id, data }) => ({
    type: ORDER_STATUS_WAITING_PICKUP,
    payload: { id, data: { ...data, status: STATUSES.WAITING_PICKUP } },
    meta: {
        fetch: UPDATE,
        resource: RESOURCES.ORDERS,
        onFailure: {
            notification: {
                body: 'resources.orders.notification.action_failure',
                level: 'warning',
            },
        },
    },
});

export const setOrderStatusOnHold = ({ id, data }) => ({
    type: ORDER_STATUS_ON_HOLD,
    payload: { id, data: { ...data, status: STATUSES.ON_HOLD } },
    meta: {
        fetch: UPDATE,
        resource: RESOURCES.ORDERS,
        onSuccess: {
            notification: {
                body: 'resources.orders.notification.onHold_success',
                level: NOTIFICATION.INFO,
            },
        },
        onFailure: {
            notification: {
                body: 'resources.orders.notification.action_failure',
                level: NOTIFICATION.WARNING,
            },
        },
        redirectTo: `/${RESOURCES.ORDERS}`,
    },
});

export const sendMessage = ({ data, parentId }) => ({
    type: SEND_MESSAGE,
    payload: { data, parentId },
    meta: {
        fetch: CREATE,
        resource: RESOURCES.ORDERS_MESSAGES,
    },
});

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, propTypes, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { translate, userLogin } from 'react-admin';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import LockIcon from '@material-ui/icons/LockOutline';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { email } from '../../utils/validation';
import { formTextInput, SubmitButton } from '../input';
import { getIsLoading } from '../../store/selectors';
import { ROUTES } from '../../framework/routes';


const styles = theme => ({
    input: {
        marginTop: '1em',
        marginBottom: '1em',
    },
    link: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary[500],
    },
});


const login = (auth, dispatch, { redirectTo }) => dispatch(userLogin(auth, redirectTo));

const LoginForm = ({
    classes, isLoading, handleSubmit, translate,
}) => (
    <div>
        <div className={classes.avatar}>
            <Avatar className={classes.icon}>
                <LockIcon />
            </Avatar>
        </div>
        <form onSubmit={handleSubmit(login)}>
            <div className={classes.form}>
                <div className={classes.input}>
                    <Field
                        id="username"
                        name="username"
                        component={formTextInput}
                        label={translate('ra.auth.username')}
                        disabled={isLoading}
                        autoFocus
                    />
                </div>
                <div className={classes.input}>
                    <Field
                        id="password"
                        name="password"
                        component={formTextInput}
                        label={translate('ra.auth.password')}
                        type="password"
                        disabled={isLoading}
                    />
                </div>
            </div>
            <SubmitButton {...{ isLoading }} title={translate('ra.auth.sign_in')} />
        </form>
        <Fragment>
            <List dense>
                <ListItem style={{ justifyContent: 'center' }}>
                    <Link to={{ pathname: ROUTES.SIGNUP }} className={classes.link}>
                        {translate('resources.auth.actions.create_account')}
                    </Link>
                </ListItem>

                <ListItem style={{ justifyContent: 'center' }}>
                    <Link to={{ pathname: ROUTES.RESET_PASSWORD }} className={classes.link}>
                        {translate('resources.auth.actions.forgot_pass')}
                    </Link>
                </ListItem>
            </List>
        </Fragment>
    </div>
);
LoginForm.propTypes = {
    ...propTypes,
    classes: PropTypes.object,
    redirectTo: PropTypes.string,
};

const mapStateToProps = state => ({ isLoading: getIsLoading(state) });

const enhance = compose(
    withStyles(styles),
    translate,
    connect(mapStateToProps),
    reduxForm({
        form: 'signIn',
        validate: (values, props) => {
            const errors = {};
            const { translate } = props;
            if (!values.username) {
                errors.username = translate('ra.validation.required');
            }
            if (!values.password) {
                errors.password = translate('ra.validation.required');
            }
            if (email(values.username) !== undefined) {
                errors.username = translate('ra.validation.email');
            }
            return errors;
        },
    }),
);

export default enhance(LoginForm);

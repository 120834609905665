import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Admin, Layout, Resource, translate,
} from 'react-admin';
import compose from 'recompose/compose';
import {
    Business, LocalMall, People, Settings, Store, Sync, ShoppingCart,
} from '@material-ui/icons';
import { RESOURCES } from 'omni-shared/resources';
import { AppBar } from './components/appbar';
import { SnackBarNotification } from './components/notifications';
import { Dashboard } from './pages/dashboard';
import { StoreCreate, StoreEdit, StoreList } from './components/stores';
import { OrdersList, OrderView } from './components/orders';
import { CompanyList, CompanyEdit, getCompanyId } from './components/companies';
import { UserCreate, UserEdit, UsersList } from './components/users';
import {
    ClickAndCollect, SettingsList, Exchange as ExchangeSettings, OmniOrders, StoreApi,
} from './pages/settings';
import { SimpleLayoutContainer } from './components/simpleLayout';
import { counter } from './utils/functions';
import withAutoRefresh from './components/autoRefresh/withAutoRefresh';
import customRoutes from './framework/routes';
import omniTheme from './framework/theme';
import { PrintButton } from './components/print';
import Menu from './components/menu';
import { Exchange, Order } from './components/exchange';
import { OrdersList as OmniOrdersList } from './pages/omniorders';
import { arePermissionsElevated } from './utils/authorization';

const MyLayout = props => (
    <Layout {...props} title="title" appBar={AppBar} notification={SnackBarNotification} menu={Menu} />);

class App extends Component {
    render() {
        const {
            authProvider, history, companyId, translate,
        } = this.props;

        return (
            <Admin
                dashboard={() => <Dashboard />}
                customRoutes={customRoutes(companyId)}
                authProvider={authProvider}
                history={history}
                appLayout={MyLayout}
                theme={omniTheme}
                loginPage={SimpleLayoutContainer}
            >
                {
                    permissions => [
                        permissions.features.click_and_collect ? (
                            <Resource
                                key={counter()}
                                name={RESOURCES.ORDERS}
                                options={{ label: translate('resources.cac.name') }}
                                list={withAutoRefresh(OrdersList)}
                                edit={props => (
                                    <PrintButton render={
                                        printRef => <OrderView {...props} ref={printRef} />
                                    }
                                    />
                                )}
                                icon={LocalMall}
                            />
                        ) : null,
                        <Resource
                            key={counter()}
                            name={RESOURCES.ORDERS_ITEMS}
                        />,
                        <Resource
                            key={counter()}
                            name={RESOURCES.ORDERS_MESSAGES}
                        />,
                        <Resource
                            key={counter()}
                            name={RESOURCES.ORDERS_STATUSES}
                        />,
                        <Resource
                            key={counter()}
                            name={RESOURCES.COUNTERS_EMAIL_DAILY}
                        />,
                        <Resource
                            key={counter()}
                            name={RESOURCES.COUNTERS_EMAIL_MONTHLY}
                        />,
                        <Resource
                            key={counter()}
                            name={RESOURCES.COUNTERS_SMS_DAILY}
                        />,
                        <Resource
                            key={counter()}
                            name={RESOURCES.COUNTERS_SMS_MONTHLY}
                        />,
                        <Resource
                            key={counter()}
                            name={RESOURCES.EXCHANGE_ORDERS_ITEMS}
                        />,
                        <Resource
                            key={counter()}
                            name={RESOURCES.OMNI_ORDERS_ITEMS}
                        />,
                        permissions.features.storefinder ? (
                            <Resource
                                key={counter()}
                                name={RESOURCES.STORES}
                                options={{ label: translate('resources.storefinder.name') }}
                                list={StoreList}
                                edit={StoreEdit}
                                create={StoreCreate}
                                icon={Store}
                            />
                        ) : null,
                        permissions.features.exchange ? (
                            <Resource
                                key={counter()}
                                name={RESOURCES.EXCHANGE}
                                options={{ label: translate('resources.exchange.name') }}
                                list={props => <Exchange {...props} />}
                                edit={props => <Order {...props} />}

                                icon={Sync}
                            />
                        ) : null,
                        permissions.features.omni_orders ? (
                            <Resource
                                key={counter()}
                                name={RESOURCES.OMNI_ORDERS}
                                options={{ label: translate('resources.omniOrders.name') }}
                                list={withAutoRefresh(OmniOrdersList, 1000 * 60 * 9)}
                                icon={ShoppingCart}
                            />
                        ) : null,
                        arePermissionsElevated(permissions)
                            ? (
                                <Resource
                                    key={counter()}
                                    name={RESOURCES.USERS}
                                    options={{ label: translate('resources.users.name', { smart_count: 1 }) }}
                                    list={UsersList}
                                    edit={UserEdit}
                                    create={UserCreate}
                                    icon={People}
                                />
                            ) : null,
                        arePermissionsElevated(permissions)
                            ? (
                                <Resource
                                    key={counter()}
                                    name={RESOURCES.SETTINGS}
                                    icon={Settings}
                                    edit={(props) => {
                                        switch (props.id) {
                                            case 'exchange':
                                                return <ExchangeSettings {...props} />;
                                            case 'click_and_collect':
                                                return <ClickAndCollect {...props} />;
                                            case 'omni_orders':
                                                return <OmniOrders {...props} />;
                                            case 'store_api':
                                                return <StoreApi {...props} />;
                                            default:
                                                return null;
                                        }
                                    }}
                                    list={SettingsList}
                                />
                            ) : (
                                <Resource
                                    key={counter()}
                                    name={RESOURCES.SETTINGS}
                                />
                            ),
                        permissions.roles.admin === true
                            ? (
                                <Resource
                                    key={counter()}
                                    name={RESOURCES.COMPANIES}
                                    options={{ label: translate('resources.companies.name', { smart_count: 2 }) }}
                                    list={CompanyList}
                                    edit={CompanyEdit}
                                    icon={Business}
                                />
                            )
                            : (
                                <Resource
                                    key={counter()}
                                    name={RESOURCES.COMPANIES}
                                />
                            ),
                    ]
                }
            </Admin>
        );
    }
}

function mapStateToProps(state) {
    const companyId = getCompanyId(state);
    return {
        companyId,
    };
}

const enhance = compose(
    translate,
    connect(mapStateToProps),
);

App.propTypes = {
    authProvider: PropTypes.func,
    history: PropTypes.object,
    companyId: PropTypes.string,
    translate: PropTypes.func,
};

export default enhance(App);

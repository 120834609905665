import React from 'react';
import {
    AutocompleteArrayInput,
    BooleanInput,
    DisabledInput,
    email,
    FormDataConsumer,
    ReferenceArrayInput,
    required,
    SimpleForm,
    TextInput,
    translate,
} from 'react-admin';

const validateRequired = [required()];
const validateEmail = [email(), required()];


const UserForm = ({ disableEmail, translate, ...props }) => (
    <SimpleForm redirect="/users" {...props}>
        <BooleanInput source="owner" />
        <TextInput source="fullName" validate={validateRequired} />
        {disableEmail
            ? <DisabledInput source="email" /> : <TextInput source="email" validate={validateEmail} />}
        <TextInput source="mobile" />
        <FormDataConsumer>
            {({ formData, ...rest }) => !formData.owner
            && (
                <ReferenceArrayInput
                    source="storeIds"
                    reference="storefinder/stores"
                    label={translate('resources.users.fields.storeIds')}
                    perPage={5000}
                    sort={{ field: 'name', order: 'DESC' }}
                >
                    <AutocompleteArrayInput />
                </ReferenceArrayInput>
            )
            }
        </FormDataConsumer>
    </SimpleForm>
);

export default translate(UserForm);

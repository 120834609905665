import {
    Datagrid, TextField, DatagridBody, ChipField,
} from 'react-admin';
import React from 'react';
import PropTypes from 'prop-types';
import { FromDateField } from '../../../components/fields';
import OrderView from '../view/OrderView';
import DatagridRowExpanded from './DatagridRowExpanded';
import TranslatedGridField from '../../../components/grid/TranslatedGridField';
import CountdownField from './CountdownField';
import rowStyle from './rowStyle';

const CustomDatagridBody = props => <DatagridBody {...props} row={<DatagridRowExpanded />} />;
const CustomDatagrid = props => <Datagrid {...props} body={<CustomDatagridBody />} />;

export const gridLarge = props => (
    <CustomDatagrid expand={<OrderView {...props} />} rowStyle={rowStyle}>
        <TranslatedGridField
            source="status"
            translate={props.translate}
            pathPart="resources.omni_orders.data"
            component={ChipField}
        />
        <FromDateField
            source="createdAt"
            label={props.translate('resources.omni_orders.fields.created_at')}
            sortable={false}
        />
        <CountdownField
            source="actionDeadline"
            translate={props.translate}
            label={props.translate('resources.omni_orders.fields.pick_deadline')}
            sortable={false}
        />
        <TextField
            source="customer.fullName"
            label={props.translate('resources.omni_orders.fields.customer')}
            sortable={false}
        />
        <TextField
            source="customer.phone"
            label={props.translate('resources.omni_orders.fields.phone')}
            sortable={false}
        />
        <TextField
            source="orderId"
            label={props.translate('resources.omni_orders.fields.order')}
        />
        <TextField
            source="lockedByStoreName"
            label={props.translate('resources.omni_orders.fields.locked_by')}
            sortable={false}
        />
    </CustomDatagrid>
);

gridLarge.propTypes = {
    translate: PropTypes.func,
};

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { fade } from '@material-ui/core/styles/colorManipulator';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoOutlineoIcon from '@material-ui/icons/InfoOutline';
import compose from 'recompose/compose';
import withStyles from '@material-ui/core/styles/withStyles';
import classnames from 'classnames';
import { OMNI_ORDER_STATUSES } from 'omni-shared/constants';
import { Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import ActionButton from './ActionButton';


const style = theme => ({
    listItem: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    successButton: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.green.medium,
        '&:hover': {
            backgroundColor: fade(theme.palette.green.dark, 0.99),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
    dangerButton: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            backgroundColor: fade(theme.palette.error.dark, 0.99),
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
    warningButton: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.orange.light,
        '&:hover': {
            backgroundColor: fade(theme.palette.orange.dark, 0.99),
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
    simpleButton: {
        color: theme.palette.secondary.black,
        backgroundColor: theme.palette.secondary.grey,
        '&:hover': {
            backgroundColor: fade(theme.palette.secondary.grey, 0.99),
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
    ctaButton: {
        minWidth: '200px',
        margin: '10px',
        textTransform: 'none',
        padding: '12px 16px',
    },
    icon: {
        paddingRight: '5px',
    },
});

const Buttons = ({
    record,
    onLockOrder,
    onConfirmOrder,
    buttonsDisabled,
    translate,
    classes,
}) => {
    switch (record.status) {
        case OMNI_ORDER_STATUSES.NEW:
            return (
                <ActionButton
                    deadline={record.lockDeadline}
                    onClick={onLockOrder}
                    icon={<LockIcon className={classes.icon} />}
                    title={translate('resources.omni_orders.data.lock')}
                    classname={classnames(classes.successButton, classes.ctaButton)}
                    {...{
                        buttonsDisabled,
                    }}
                />

            );
        case OMNI_ORDER_STATUSES.LOCKED:
            return (
                <Fragment>
                    <ActionButton
                        deadline={record.confirmDeadline}
                        onClick={onLockOrder}
                        icon={<LockOpenIcon className={classes.icon} />}
                        title={translate('resources.omni_orders.data.unlock')}
                        classname={classnames(classes.ctaButton)}
                        {...{
                            buttonsDisabled,
                        }}
                    />
                    <ActionButton
                        deadline={record.confirmDeadline}
                        onClick={onConfirmOrder}
                        icon={<CheckCircleIcon className={classes.icon} />}
                        title={translate('resources.omni_orders.data.confirm_and_print')}
                        classname={classnames(classes.successButton, classes.ctaButton)}
                        {...{
                            buttonsDisabled,
                        }}
                    />
                </Fragment>
            );
        case OMNI_ORDER_STATUSES.CONFIRMED:
            return (
                <Tooltip title={translate('resources.omni_orders.notification.confirmed_notice')}>
                    <div style={{ display: 'flex', marginRight: '10px', marginBottom: '10px' }}>
                        <Typography>
                            {translate('resources.omni_orders.notification.confirmed_info')}
                        </Typography>
                        <InfoOutlineoIcon style={{ marginLeft: '5px' }} />
                    </div>
                </Tooltip>
            );
        default:
            return '';
    }
};

Buttons.propTypes = {
    classes: PropTypes.object,
    record: PropTypes.object,
    translate: PropTypes.func,
};

const enhance = compose(
    withStyles(style),
);

export default enhance(Buttons);

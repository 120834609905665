import {
    Datagrid, EmailField, NumberField, ShowButton, TextField,
} from 'react-admin';
import React from 'react';
import TranslatedGridField from '../../grid/TranslatedGridField';
import { FirestoreDateField } from '../../fields';


export const gridLarge = translate => (
    <Datagrid rowClick="edit">
        <TextField source="externalOrderId" label={translate('resources.orders.fields.order_id')} />
        <TextField source="storeName" label={translate('resources.orders.fields.store')} />
        <EmailField source="customer.email" label={translate('resources.orders.fields.email')} />
        <TextField source="customer.mobile" label={translate('resources.orders.fields.phone')} />
        <TranslatedGridField source="status" translate={translate} pathPart="resources.orders.data" />
        <FirestoreDateField
            source="createdAt"
            label={translate('resources.orders.fields.createdAt')}
        />
        <FirestoreDateField
            source="pickupDeadline"
            label={translate('resources.orders.fields.pickup_deadline')}
            options={{ year: 'numeric', month: 'short', day: 'numeric' }}
        />
        <NumberField
            source="total"
            locales="nb-NO"
            label={translate('resources.orders.fields.total')}
            options={{
                style: 'currency',
                currency: 'NOK',
            }}
        />
        <ShowButton />
    </Datagrid>
);

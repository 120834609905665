import { USER_SET_PERMISSIONS } from '../actions';

const initialState = {
    roles: {
        admin: false,
        owner: false,
    },
    features: {
        storefinder: false,
        click_and_collect: false,
        exchange: false,
    },
};

export const permissions = (state = initialState, action) => {
    switch (action.type) {
    case USER_SET_PERMISSIONS:
        return { ...state, ...action.payload };
    default:
        return state;
    }
};

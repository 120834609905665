import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import CardActions from '@material-ui/core/CardActions/index';
import { RadioButtonGroupInput, translate } from 'react-admin';
import TextField from '@material-ui/core/TextField/index';
import Button from '@material-ui/core/Button/index';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withStyles from '@material-ui/core/styles/withStyles';
import { CHANNELS } from 'omni-shared/constants';
import { sendManualMessage } from '../ordersActions';

const renderInput = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        multiline
        rows={3}
        rowsMax={4}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const style = {
    radioGroupInline: {
        '& > div': {
            flexWrap: 'noWrap',
            flexDirection: 'row',
        },
    },
};

class MessageForm extends Component {
    submitMessage = (data) => {
        const { handleMessageSubmit, orderId } = this.props;
        const { channel, content } = data;
        return handleMessageSubmit({ orderId, channel, content });
    }

    render() {
        const {
            handleSubmit, classes, isLoading, translate,
        } = this.props;
        // TODO: use settings to determine if all sending methods are set up. If not, do not show them, or disable
        return (
            <form onSubmit={handleSubmit(this.submitMessage)}>
                <CardActions>
                    <Field
                        name="content"
                        id="message"
                        component={renderInput}
                        label={translate('resources.messages.name', { smart_count: 1 })}
                        disabled={isLoading}
                    />
                    <div>
                        <RadioButtonGroupInput
                            label={translate('resources.messages.actions.send_as')}
                            source="channel"
                            className={classes.radioGroupInline}
                            choices={[
                                { id: CHANNELS.ALL, name: translate('resources.messages.actions.all') },
                                { id: CHANNELS.EMAIL, name: translate('resources.messages.actions.only_email') },
                                { id: CHANNELS.SMS, name: translate('resources.messages.actions.only_sms') },
                            ]}
                        />
                    </div>
                    <div>
                        <Button
                            variant="raised"
                            type="submit"
                            color="primary"
                            disabled={isLoading}
                        >
                            {translate('resources.messages.actions.send')}
                        </Button>
                    </div>
                </CardActions>
            </form>
        );
    }
}

MessageForm.propTypes = {
    handleSubmit: PropTypes.func,
    classes: PropTypes.object,
    isLoading: PropTypes.bool,
    settings: PropTypes.object,
    translate: PropTypes.func,
    handleMessageSubmit: PropTypes.func,
    orderId: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
    handleMessageSubmit: value => dispatch(sendManualMessage(value)),
});

const enhance = compose(
    translate,
    withStyles(style),
    connect(null, mapDispatchToProps),
    reduxForm({
        form: 'MessageForm',
        initialValues: {
            channel: 'all',
        },
        validate: (values, props) => {
            const errors = {};
            const { translate } = props;
            if (!values.content || values.content.trim() === '') {
                errors.content = translate('ra.validation.required');
            }
            if (!values.channel) {
                errors.channel = translate('resources.messages.notification.channel_error');
            }
            return errors;
        },
    }),
);

export default enhance(MessageForm);

import { RESOURCES } from 'omni-shared/resources';
import { MESSAGES } from 'omni-shared/constants';
import values from 'lodash/values';
import { sortByDate } from './utilities';
import { buildResourceDataGetter } from '../../store/selectors';
// TODO: test selectors and mapStateToProps in the same test

export const getOrderMessages = (state, id) => values(buildResourceDataGetter(state)(RESOURCES.ORDERS_MESSAGES))
    .filter(message => message.relatedTo === id);

export const getOrderStatuses = (state, id) => values(buildResourceDataGetter(state)(RESOURCES.ORDERS_STATUSES))
    .filter(status => status.relatedTo === id);

export const getOrder = (state, id) => buildResourceDataGetter(state)(RESOURCES.ORDERS, id);

export const getSettings = state => buildResourceDataGetter(state)(RESOURCES.SETTINGS);

export const getLastRetrievalMessage = (state, id) => getOrderMessages(state, id)
    .filter(({ type, sentAt }) => type === MESSAGES.RETRIEVAL && sentAt != undefined)
    .sort(sortByDate('sentAt'))
    .slice()
    .shift();

const isUndefined = obj =>
    obj === undefined;

export const isNullOrUndefined = obj =>
    obj == null;

export const isFalse = obj =>
    obj === false;

export const defaultValue = (defaultVal, comparator = isUndefined) =>
    function (obj) {
        return comparator(obj) ? defaultVal : obj;
    };

export const hasKey = function (key) {
    const defObj = defaultValue({}, isNullOrUndefined);
    return function (obj) {
        return key in defObj(obj);
    };
};

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line

export const email = value =>
    (value && !EMAIL_REGEX.test(value)
        ? 'Invalid email address' : undefined);

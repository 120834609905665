import get from 'lodash/get';
import cleanDeep from 'clean-deep';
import { defaultValue } from './validation';

export const counter = (() => {
    const count = 0;
    return () =>
        count + 1;
})();

export const getObjectProp = (object, propPath) =>
    defaultValue({})(get(object, propPath));

export const formatErrorMessage = e =>
    (typeof e === 'string'
        ? e
        : typeof e === 'undefined' || !e.message
            ? 'ra.auth.sign_in_error'
            : e.message);

export const clearUndefinedProps = obj =>
    cleanDeep(
        obj,
        {
            emptyArrays: false, emptyObjects: false, emptyStrings: false, nullValues: false, undefinedValues: true,
        },
    );
export const stripIdProperty = ({ id, ...data }) =>
    data;
export const getFormattedTimePart = number =>
    (number.toString().length === 1 ? `0${number}` : number.toString());

import React from 'react';
import {
    List, TextField, Datagrid, BooleanField, EditButton, translate, Filter, SearchInput
} from 'react-admin';
import { FirestoreDateField } from '../../fields';

const UsersList = translate(({ translate, ...props }) => (
    <List
        title={translate('resources.users.name', { smart_count: 2 })}
        {...props}
        sort={{ field: 'createdAt', order: 'DESC' }}
        filters={<CommandFilter translate={translate} />}
    >
        <Datagrid rowClick="edit">
            <TextField source="fullName" />
            <TextField source="email" />
            <TextField source="mobile" />
            <FirestoreDateField
                source="createdAt"
                locales="nb-NO"
                options={{
                    year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric',
                }}
            />
            <BooleanField source="owner" />
            <EditButton />
        </Datagrid>
    </List>
));

const CommandFilter = ({ classes, translate, ...props }) => (
    <Filter {...props}>
        <SearchInput label={translate('resources.users.fields.fullName')} source="fullName" />
        <SearchInput label={translate('resources.users.fields.email')} source="email" />
    </Filter>
);

export default UsersList;

import React from 'react';
import { translate } from 'react-admin';
import PropTypes from 'prop-types';
import { Field, propTypes, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { userPasswordReset } from './passwordResetActions';
import { email } from '../../utils/validation';
import { formTextInput, SubmitButton } from '../input';
import { getIsLoading } from '../../store/selectors';

const styles = () => ({
    input: {
        marginTop: '1em',
        marginBottom: '1em',
    },
});

const reset = (auth, dispatch, { redirectTo }) => dispatch(userPasswordReset(auth, redirectTo));

const PasswordResetForm = ({
    classes, isLoading, handleSubmit, translate,
}) => (
    <form onSubmit={handleSubmit(reset)}>
        <Typography variant="title" gutterBottom>{translate('resources.auth.actions.forgot_pass')}</Typography>
        <Typography variant="body2" gutterBottom>{translate('resources.auth.actions.reset_pass')}</Typography>
        <div className={classes.input}>
            <Field
                id="email"
                name="email"
                component={formTextInput}
                label={translate('resources.auth.fields.email')}
                disabled={isLoading}
                autoFocus
            />
        </div>
        <SubmitButton {...{ isLoading, classes }} title={translate('resources.auth.actions.submit')} />
    </form>
);
PasswordResetForm.propTypes = {
    ...propTypes,
    classes: PropTypes.object,
    redirectTo: PropTypes.string,
};

const mapStateToProps = state => ({ isLoading: getIsLoading(state) });

const enhance = compose(
    withStyles(styles),
    translate,
    connect(mapStateToProps),
    reduxForm({
        form: 'signIn',
        validate: (values, props) => {
            const errors = {};
            const { translate } = props;
            if (email(values.email) !== undefined) {
                errors.email = translate('ra.validation.email');
            }
            if (!values.email) {
                errors.email = translate('ra.validation.required');
            }
            return errors;
        },
    }),
);

export default enhance(PasswordResetForm);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import compose from 'recompose/compose';
import {
    crudGetManyReference as crudGetManyReferenceAction,
    crudGetOne as crudGetOneAction,
    translate,
    nameRelatedTo,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import withStyles from '@material-ui/core/styles/withStyles';
import { RESOURCES } from 'omni-shared/resources';
import { MESSAGES } from 'omni-shared/constants';
import {
    sendStatusChangeMessage,
    setOrderStatusCanceled,
    setOrderStatusOnHold,
    setOrderStatusPickedup,
    setOrderStatusWaitingPickup,
} from '../ordersActions';
import { UppercaseHeadline } from '../utilities';
import OrderTable from './OrderSummary';
import * as selectors from '../ordersSelectors';
import { getIsLoading } from '../../../store/selectors/adminSelectors';
import { getLastRefresh } from '../../../store/selectors';
import History from './History';
import OrderItems from './OrderItems';


const editStyles = {
    flex: { display: 'flex' },
    column: { flexDirection: 'column' },
    right: { alignItems: 'flex-end' },
    end: { justifyContent: 'flex-end' },
    card: { marginBottom: '2em' },
    cardActions: {
        justifyContent: 'space-between',
        padding: '8px 24px',
    },
    historyContainer: { maxHeight: '500px', overflowY: 'auto' },
    noWrap: { whiteSpace: 'noWrap' },
};

const REFRESH_INTERVAL = 1000 * 60 * 10 - 1000; // 10 minutes - 1 second

class OrderView extends React.Component {
    state = {
        lastComponentRefresh: new Date(),
    }

    componentDidUpdate(prevProps, prevState) {
        const refreshButtonClicked = prevProps.lastRefresh !== this.props.lastRefresh;
        const refreshIntervalExpired = prevState.lastComponentRefresh - this.state.lastComponentRefresh !== 0;

        if (refreshButtonClicked || refreshIntervalExpired) {
            this.fetchData();
            this.fetchOrdersItems();
        }
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        const timeSinceLastUpdate = new Date() - prevState.lastComponentRefresh;
        const shouldRefreshComponent = timeSinceLastUpdate > REFRESH_INTERVAL;

        if (shouldRefreshComponent) {
            this.setState({ lastComponentRefresh: new Date() });
        }
        return null;
    }

    fetchOrdersItems = () => {
        const { crudGetManyReferenceAction, orderId } = this.props;

        crudGetManyReferenceAction(
            RESOURCES.ORDERS_ITEMS,
            'relatedTo',
            orderId,
            nameRelatedTo(RESOURCES.ORDERS_ITEMS, orderId, RESOURCES.ORDERS, 'relatedTo'),
            null,
            // { order: 'desc', field: 'message' },
        );
    }

    fetchData = () => {
        const { orderId, crudGetOneAction, crudGetManyReferenceAction } = this.props;

        const getMany = resource => crudGetManyReferenceAction(
            resource,
            'relatedTo',
            orderId,
            nameRelatedTo(resource, orderId, RESOURCES.ORDERS, 'relatedTo'),
            null,
            { order: 'ASC', field: 'createdAt' },
        );

        crudGetOneAction(RESOURCES.ORDERS, orderId);
        getMany(RESOURCES.ORDERS_MESSAGES);
        getMany(RESOURCES.ORDERS_STATUSES);
    }

    cancel = () => {
        const { order, setOrderStatusCanceled, sendStatusChangeMessage } = this.props;
        const { id, ...rest } = order;

        sendStatusChangeMessage({ id: order.id, type: MESSAGES.CANCELLATION });
        setOrderStatusCanceled({ id, data: rest });
    }

    pickUp = () => {
        const { order, setOrderStatusPickedup } = this.props;
        const { id, ...rest } = order;

        setOrderStatusPickedup({ id, data: rest });
    }

    onHold = () => {
        const { order, setOrderStatusOnHold } = this.props;
        const { id, ...rest } = order;

        setOrderStatusOnHold({ id, data: rest });
    }

    sendRetrievalMessage = () => {
        const { sendStatusChangeMessage, setOrderStatusWaitingPickup, order } = this.props;
        const { id, ...rest } = order;

        setOrderStatusWaitingPickup({ id, data: rest });
        sendStatusChangeMessage({ id, type: MESSAGES.RETRIEVAL });
    }

    renderOrderInfo = () => {
        const { classes, order, translate } = this.props;

        return (
            <Card className={classes.card}>
                <CardHeader
                    title={(
                        <UppercaseHeadline
                            text={`${translate('resources.orders.name', { smart_count: 1 })}
                         #${(order.externalOrderId || '')} -
                         ${order.status && translate(`resources.orders.data.${order.status}`, { smart_count: 1 })}`}
                        />
                    )}
                />
                <CardContent>
                    <OrderTable order={order} classes={classes} />
                </CardContent>
            </Card>
        );
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classnames(classes.flex, classes.column)}>
                {this.renderOrderInfo()}
                <OrderItems
                    {...{
                        ...this.props,
                        pickUp: this.pickUp,
                        onHold: this.onHold,
                        cancel: this.cancel,
                        sendRetrievalMessage: this.sendRetrievalMessage,

                    }}
                />
                <History
                    {...this.props}
                />
            </div>
        );
    }
}


const mapStateToProps = (state, { id }) => ({
    orderId: id,
    order: selectors.getOrder(state, id),
    messages: selectors.getOrderMessages(state, id),
    lastRetrievalMessage: selectors.getLastRetrievalMessage(state, id),
    statuses: selectors.getOrderStatuses(state, id),
    settings: selectors.getSettings(state),
    isLoading: getIsLoading(state),
    lastRefresh: getLastRefresh(state),
});

const mapDispatchToProps = {
    crudGetOneAction,
    crudGetManyReferenceAction,
    setOrderStatusWaitingPickup,
    setOrderStatusPickedup,
    setOrderStatusCanceled,
    setOrderStatusOnHold,
    sendStatusChangeMessage,
};

OrderView.propTypes = {
    classes: PropTypes.object,
    handleMessageSubmit: PropTypes.func,
    order: PropTypes.object,
    orderId: PropTypes.string,
    messages: PropTypes.array,
    lastRetrievalMessage: PropTypes.object,
    statuses: PropTypes.array,
    isLoading: PropTypes.bool,
    lastRefresh: PropTypes.number,
    settings: PropTypes.object,
    translate: PropTypes.func,
    crudGetOneAction: PropTypes.func,
    crudGetManyReferenceAction: PropTypes.func,
    setOrderStatusWaitingPickup: PropTypes.func,
    setOrderStatusPickedup: PropTypes.func,
    setOrderStatusCanceled: PropTypes.func,
    setOrderStatusOnHold: PropTypes.func,
    sendStatusChangeMessage: PropTypes.func,
};

const enhance = compose(
    translate,
    withStyles(editStyles),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
);

export default enhance(OrderView);

import React from 'react';
import { Edit } from 'react-admin';
import UserForm from '../UserForm';

const UserEdit = props => (
    <Edit {...props}>
        <UserForm disableEmail />
    </Edit>
);

export default UserEdit;

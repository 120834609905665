export const EMAIL_VERIFY_START = 'EMAIL_VERIFY_START';
export const EMAIL_VERIFY_SUCCESS = 'EMAIL_VERIFY_SUCCESS';
export const EMAIL_VERIFY_FAILURE = 'EMAIL_VERIFY_FAILURE';

export const emailVerifyStart = payload => ({
    type: EMAIL_VERIFY_START,
    payload,
});

export const emailVerifySuccess = () => ({
    type: EMAIL_VERIFY_SUCCESS,
});

export const emailVerifyFailure = () => ({
    type: EMAIL_VERIFY_FAILURE,
});

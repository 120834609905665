import { call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import {
    FETCH_ERROR, fetchEnd, fetchStart, showNotification,
} from 'react-admin';
import { RESOURCES } from 'omni-shared/resources';
import { CREATE_STORE_USER } from '../usersActions';
import { createStoreUser } from '../../../services/auth';
import { formatErrorMessage } from '../../../utils/functions';

function* handleUserCreation({ payload }) {
    try {
        yield put(fetchStart());
        yield call(createStoreUser, payload);
        yield put(fetchEnd());
        yield put(push(`/${RESOURCES.USERS}`));
        yield put(showNotification('resources.users.notification.created'));
    } catch (e) {
        yield put({
            type: FETCH_ERROR,
            error: e,
        });
        console.log(e);
        const errorMessage = formatErrorMessage(e);
        yield put(showNotification(errorMessage, 'resources.users.notification.create_failure'));
    }
}

export default function* watchUserCreation() {
    yield takeEvery(CREATE_STORE_USER, handleUserCreation);
}

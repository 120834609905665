import {
    call, put, takeEvery, takeLatest,
} from 'redux-saga/effects';
import { fetchEnd, fetchStart, showNotification } from 'react-admin';
import { NOTIFICATION } from 'omni-shared/constants';
import { verifyEmail, confirmResetPassword } from '../../services/auth';
import {
    CODE_VERIFY_START,
    codeVerifySuccess,
    PASSWORD_CHANGE_START,
    passwordChangeSuccess,
    codeVerifyFailure,
} from './newPasswordActions';

export function tryChangePassword(actionCode) {
    return function* (action) {
        try {
            yield put(fetchStart());
            yield call(confirmResetPassword, actionCode, action.payload.password);
            yield put(passwordChangeSuccess());
        } catch (e) {
            yield put(showNotification(e.message, NOTIFICATION.WARNING));
        } finally {
            yield put(fetchEnd());
        }
    };
}

export function* prepareForPasswordChange({ payload: actionCode }) {
    try {
        yield put(fetchStart());

        // Verify the password reset code is valid.
        const emailAddress = yield call(verifyEmail, actionCode);
        yield put(codeVerifySuccess(emailAddress));
        yield put(fetchEnd());

        yield takeEvery(
            PASSWORD_CHANGE_START,
            tryChangePassword(actionCode),
        );
    } catch (e) {
        yield put(codeVerifyFailure(e.message));
        yield put(fetchEnd());
    }
}


export default function* watchPasswordChange() {
    yield takeLatest(CODE_VERIFY_START, prepareForPasswordChange);
}

import CardActions from '@material-ui/core/CardActions';
import { Field } from 'redux-form';
import Button from '@material-ui/core/Button';
import React from 'react';
import PropTypes from 'prop-types';
import { formTextInput } from '../input';

export const idSubmitForm = ({
    handleSubmit, classes, isLoading, translate, submitAction, label, pristine,
}) => (
    <form onSubmit={handleSubmit(submitAction)} className={classes.form}>
        <CardActions classes={{ root: classes.cardActionsRoot }}>
            <Field
                name="id"
                component={formTextInput}
                label={label}
                disabled={isLoading}
            />
            <div>
                <Button
                    variant="raised"
                    type="submit"
                    color="primary"
                    disabled={pristine || isLoading}
                >
                    {translate('resources.exchange.actions.change')}
                </Button>
            </div>
        </CardActions>
    </form>
);

idSubmitForm.propTypes = {
    handleSubmit: PropTypes.func,
    classes: PropTypes.object,
    isLoading: PropTypes.bool,
    translate: PropTypes.func,
    submitAction: PropTypes.func,
    label: PropTypes.string,
    pristine: PropTypes.bool,
};

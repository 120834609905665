import React from 'react';
import { ReferenceManyField, TextField, Datagrid } from 'react-admin';
import { RESOURCES } from 'omni-shared/resources';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { FirestoreDateField } from '../../fields';
import TranslatedGridField from '../../grid/TranslatedGridField';

const EmptyGrid = props => (
    get(props, 'ids.length') > 0 ? (
        <Datagrid {...props}>
            <FirestoreDateField
                source="createdAt"
                label={props.translate('resources.exchange.fields.date')}
                className="noWrap"
            />
            <TextField
                source="sku"
                label="Sku"
            />
            <TranslatedGridField
                source="reason"
                translate={props.translate}
                label={props.translate('resources.exchange.fields.reason')}
                pathPart="resources.exchange.actions"
            />
            <TextField
                source="comment"
                label={props.translate('resources.exchange.fields.comment')}
            />
        </Datagrid>
    ) : (
        <div>
            {props.translate('resources.exchange.data.history_empty')}
        </div>
    )
);

const RegisterHistory = ({ exchangeId, ...props }) => (
    <ReferenceManyField
        reference={RESOURCES.EXCHANGE_ORDERS_ITEMS}
        label="Messages"
        target="relatedTo"
        source="id"
        record={{ id: exchangeId }}
        basePath="/exchange"
        resource={RESOURCES.EXCHANGE}
        sort={{ field: 'createdAt', order: 'ASC' }}
    >
        <EmptyGrid {...props} />
    </ReferenceManyField>
);

RegisterHistory.propTypes = {
    exchangeId: PropTypes.string,
    translate: PropTypes.func,
};

export default RegisterHistory;

import firebase from 'firebase';

function createStoreUser(payload) {
    const create = firebase.functions().httpsCallable('createUserFromPrivate');
    return create(payload);
}

function createUserAndCompany(data) {
    const {
        email,
        password,
        fullName,
        mobile,
        companyName,
    } = data;

    return firebase.auth().createUserWithEmailAndPassword(email, password)
        .then((authUser) => {
            const contactData = {
                fullName,
                mobile,
                email,
                companyName,
                uid: authUser.user.uid,
            };
            const createUser = firebase.functions().httpsCallable('createUserFromPublic');
            return createUser(contactData);
        });
}

function sendVerificationEmail() {
    const user = firebase.auth().currentUser;

    return user.sendEmailVerification()
        .then(() => {
            console.log('email verification', 'email sent');
        }).catch((error) => {
            console.log('email verification', 'An error happened');
        });
}

function verifyEmail(actionCode) {
    return firebase.auth().verifyPasswordResetCode(actionCode);
}

function confirmResetPassword(actionCode, password) {
    return firebase.auth().confirmPasswordReset(actionCode, password);
}

function resetPassword(email) {
    return firebase.auth().sendPasswordResetEmail(email);
}

function applyActionCode(actionCode) {
    return firebase.auth().applyActionCode(actionCode);
}

function signInWithEmailAndPassword(email, password) {
    return firebase.auth().signInWithEmailAndPassword(email, password)
        .then((res) => {
            if (!res.user.emailVerified) {
                return Promise.reject({ message: 'Please verify your email and try to signup again' });
            }
            return getClaims(res.user);
        });
}

function signOut() {
    return firebase.auth().signOut();
}

function currentUser() {
    return firebase.auth().currentUser;
}

function getClaims(user) {
    return user.getIdTokenResult()
        .then(token =>
            token.claims);
}

export {
    applyActionCode,
    resetPassword,
    confirmResetPassword,
    verifyEmail,
    sendVerificationEmail,
    createUserAndCompany,
    createStoreUser,
    signInWithEmailAndPassword,
    signOut,
    currentUser,
    getClaims,
};

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

const styles = {
    column: {
        flexBasis: '160px',
    },
    header: {
        marginBottom: '7px',
        fontWeight: '700',
    },
};

const ItemAttributeColumn = ({ property, children, classes }) => (
    <div className={classes.column}>
        <div className={classes.header}>
            {property}
        </div>
        <div>{children}</div>
    </div>
);

ItemAttributeColumn.propTypes = {
    property: PropTypes.node,
    children: PropTypes.node,
    classes: PropTypes.object,
};

export default withStyles(styles)(ItemAttributeColumn);

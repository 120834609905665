import React from 'react';
import PropTypes from 'prop-types';
import { Field, propTypes, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { userSignUp } from './signUpActions';
import { formTextInput, SubmitButton } from '../input';
import { email } from '../../utils/validation';
import { ROUTES } from '../../framework/routes';


const styles = () => ({
    inline: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    input: {
        marginTop: '1em',
        marginBottom: '1em',
    },
    link: {
        color: '#2895F1',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
});

const signUp = (auth, dispatch, { redirectTo }) => dispatch(userSignUp(auth, redirectTo));

const SignUpForm = ({
    classes, isLoading, handleSubmit, translate,
}) => (
    <form onSubmit={handleSubmit(signUp)}>
        <div className={classes.inline}>
            <Typography variant="title">{translate('resources.auth.actions.create_account')}</Typography>
            <Typography variant="body1">
                {translate('resources.auth.actions.or')} &nbsp;
                <Link to={{ pathname: ROUTES.LOGIN }} className={classes.link}>
                    {translate('resources.auth.actions.signIn')}
                </Link>
            </Typography>
        </div>
        <div className={classes.input}>
            <Field
                id="companyName"
                name="companyName"
                component={formTextInput}
                label={translate('resources.auth.fields.companyName')}
                disabled={isLoading}
            />
        </div>
        <div className={classes.input}>
            <Field
                id="mobile"
                name="mobile"
                component={formTextInput}
                label={translate('resources.auth.fields.mobile')}
                disabled={isLoading}
            />
        </div>
        <div className={classes.input}>
            <Field
                id="fullName"
                name="fullName"
                component={formTextInput}
                label={translate('resources.auth.fields.fullName')}
                disabled={isLoading}
            />
        </div>
        <div className={classes.input}>
            <Field
                id="email"
                name="email"
                component={formTextInput}
                label={translate('resources.auth.fields.email')}
                disabled={isLoading}
            />
        </div>
        <div className={classes.input}>
            <Field
                id="password"
                name="password"
                component={formTextInput}
                label={translate('ra.auth.password')}
                type="password"
                disabled={isLoading}
            />
        </div>
        <SubmitButton {...{ isLoading, classes }} title={translate('resources.auth.actions.create_account')} />
    </form>
);
SignUpForm.propTypes = {
    ...propTypes,
    redirectTo: PropTypes.string,
};

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });

const enhance = compose(
    withStyles(styles),
    translate,
    connect(mapStateToProps),
    reduxForm({
        form: 'signIn',
        validate: (values, props) => {
            const errors = {};
            const { translate } = props;

            if (email(values.email) !== undefined) {
                errors.email = translate('ra.validation.email');
            }
            if (!values.companyName) {
                errors.companyName = translate('ra.validation.required');
            }
            if (!values.mobile) {
                errors.mobile = translate('ra.validation.required');
            }
            if (!values.fullName) {
                errors.fullName = translate('ra.validation.required');
            }
            if (!values.email) {
                errors.email = translate('ra.validation.required');
            }
            if (!values.password) {
                errors.password = translate('ra.validation.required');
            }
            return errors;
        },
    }),
);

export default enhance(SignUpForm);

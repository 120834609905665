import firebase from 'firebase';
import { getCompanyId } from '../companies';

function getFilePath(companyId, id, title) {
    return `storefinder/data/${companyId}/${id}/images/${title}`;
}

function getStorageURL() {
    const project = process.env.REACT_APP_ENV === 'production' ? 'prod' : 'dev';
    return `https://storage.googleapis.com/omnicloud-${project}.appspot.com`;
}

const saveStoreImageToStorage = function (params, store) {
    const { files, id } = params.data;
    return new Promise(((resolve, reject) => {
        const image = {
            src: null,
            title: null,
        };
        if (!files || !files.rawFile) {
            params.data.image = image;
            resolve(params.data);
        }
        const companyId = getCompanyId(store.getState());
        const storageRef = firebase.storage().ref();
        const imageRef = storageRef.child(getFilePath(companyId, id, files.title));
        imageRef.put(files.rawFile).then((snapshot) => {
            const image = {
                src: `${getStorageURL()}/${getFilePath(companyId, id, files.rawFile.name)}`,
                title: files.rawFile.name,
            };
            params.data.image = image;
            delete params.data.files;
            resolve(params.data);
        });
    }));
};

export default saveStoreImageToStorage;

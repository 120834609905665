import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withStyles from '@material-ui/core/styles/withStyles';
import { translate, Title } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { loadOrder } from './exchangeActions';
import { idSubmitForm } from './IdSubmitForm';


const style = {
    container: {
        display: 'flex',
        justifyContent: 'center',
    },
    form: {
        border: '1px solid grey',
        padding: '30px',
        marginTop: '10px',
        minWidth: '400px',
    },
    text: {
        paddingLeft: '10px',
        marginTop: '10px',
    },
    cardActionsRoot: {
        alignItems: 'flex-end',
    },
};

class Exchange extends React.Component {
    submitIncrementId = (data) => {
        const { loadOrder, basePath } = this.props;
        const { id } = data;
        return loadOrder({ id, basePath });
    }

    submitExchangeId = (data) => {
        const { id } = data;
        const decimalId = parseInt(id, 36);
        return this.submitIncrementId({ id: decimalId });
    }

    render() {
        const { classes, translate } = this.props;
        const ExchangeIdForm = reduxForm()(idSubmitForm);
        const IncrementIdForm = reduxForm()(idSubmitForm);

        return (
            <Card>
                <Title title={translate('resources.exchange.name')} />
                <CardContent>
                    <div className={classes.container}>
                        <div>
                            <Typography variant="title">{translate('resources.exchange.tabs.form')}</Typography>
                            <ExchangeIdForm
                                submitAction={this.submitExchangeId}
                                classes={classes}
                                label={translate('resources.exchange.fields.exchange_code')}
                                translate={translate}
                                form="ExchangeIdForm"
                            />
                            <div className={classes.text}>{translate('utilities.or')}</div>
                            <IncrementIdForm
                                submitAction={this.submitIncrementId}
                                classes={classes}
                                label={translate('resources.exchange.fields.order_number')}
                                translate={translate}
                                form="IncrementIdForm"
                            />
                        </div>
                    </div>
                </CardContent>
            </Card>
        );
    }
}

Exchange.propTypes = {
    classes: PropTypes.object,
    translate: PropTypes.func,
    loadOrder: PropTypes.func,
    basePath: PropTypes.node,
};

const enhance = compose(
    translate,
    withStyles(style),
    connect(null, { loadOrder }),
);


export default enhance(Exchange);

import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import {
    Datagrid, ReferenceManyField, TextField,
} from 'react-admin';
import { UppercaseHeadline } from '../utilities';
import MessageForm from './SendMessage';
import FirestoreDateField from '../../fields/FirestoreDateField';
import TranslatedGridField from '../../grid/TranslatedGridField';

const History = ({
    classes, isLoading, handleMessageSubmit, orderId, messages, statuses, settings, translate, ...rest
}) => (
    <Card className={classes.card}>
        <CardHeader
            className="remove-from-print"
            title={<UppercaseHeadline text={translate('resources.orders.tabs.notifications')} />}
            disableTypography
        />
        {/* weird bug: if the MessageForm is set to display:none in print mode, whole pdf is broken */}
        <CardContent className="hide-from-print">
            <MessageForm
                onSubmit={values => handleMessageSubmit(values)}
                isLoading={isLoading}
                orderId={orderId}
                settings={settings}
            />
        </CardContent>
        <CardHeader
            title={<UppercaseHeadline text={translate('resources.orders.tabs.notif_history')} />}
            disableTypography
        />
        <CardContent className={[classes.historyContainer, 'remove-scroll'].join(' ')}>
            {/* <MessagesTable messages={messages} /> */}
            <ReferenceManyField
                reference="click_and_collect/messages"
                label="Messages"
                target="relatedTo"
                source="id"
                record={{ id: orderId }}
                basePath="/click_and_collect/orders"
                resource="click_and_collect/orders"
                sort={{ field: 'createdAt', order: 'ASC' }}
            >
                <Datagrid>
                    <FirestoreDateField
                        source="createdAt"
                        label={translate('resources.messages.fields.date')}
                        className="noWrap"
                    />
                    <TranslatedGridField
                        source="type"
                        translate={translate}
                        pathPart="resources.messages.data"
                        label={translate('resources.messages.fields.type')}
                        className="noWrap"
                    />
                    <TextField
                        source="recipient"
                        label={translate('resources.messages.fields.recipient')}
                    />
                    <TranslatedGridField
                        source="channel"
                        translate={translate}
                        pathPart="resources.messages.data"
                        label={translate('resources.messages.fields.channel')}
                    />
                    <TextField
                        source="content"
                        label={translate('resources.messages.name', { smart_count: 1 })}
                    />
                    <TranslatedGridField
                        source="status"
                        translate={translate}
                        pathPart="resources.messages.actions"
                        label={translate('resources.messages.fields.status')}
                    />
                </Datagrid>
            </ReferenceManyField>
        </CardContent>
        <CardHeader
            title={<UppercaseHeadline text={translate('resources.orders.tabs.status_history')} />}
            disableTypography
        />
        <CardContent className={[classes.historyContainer, 'remove-scroll'].join(' ')}>
            {/* <StatusesHistory statuses={statuses} orderId={orderId} /> */}
            <ReferenceManyField
                reference="click_and_collect/statuses"
                label="Statuses"
                target="relatedTo"
                record={{ id: orderId }}
                basePath="/click_and_collect/orders"
                resource="click_and_collect/orders"
                sort={{ field: 'createdAt', order: 'ASC' }}
            >
                <Datagrid>
                    <FirestoreDateField
                        source="createdAt"
                        label={translate('resources.statuses.fields.date')}
                    />
                    <TranslatedGridField
                        source="status"
                        translate={translate}
                        pathPart="resources.orders.data"
                        label={translate('resources.statuses.fields.status')}
                    />
                </Datagrid>
            </ReferenceManyField>
        </CardContent>
    </Card>
);

History.propTypes = {
    classes: PropTypes.object,
    handleMessageSubmit: PropTypes.func,
    orderId: PropTypes.string,
    messages: PropTypes.array,
    statuses: PropTypes.array,
    isLoading: PropTypes.bool,
    settings: PropTypes.object,
    translate: PropTypes.func,
};


export default History;

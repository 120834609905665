import IconCancel from '@material-ui/core/SvgIcon/SvgIcon';
import React from 'react';
import {
    Dialog, DialogTitle, DialogActions, DialogContent,
} from '@material-ui/core';
import {
    SimpleForm,
    SaveButton,
    Button,
    NumberInput,
} from 'react-admin';
import PropTypes from 'prop-types';

const validateUserCreation = (values) => {
    const errors = {};
    console.log(values);
    if (!values.packagesQty || values.packagesQty < 0) {
        errors.packagesQty = ['Please enter a positive number'];
    }

    if (!values.totalWeight || values.totalWeight < 0) {
        errors.totalWeight = ['Please enter a positive number'];
    }

    return errors;
};

const ShipmentSubmitDialog = ({
    translate, formName, handleCloseClick, handleSaveClick, isOpen, handleSubmit, title,
}) => (
    <Dialog
        fullWidth
        open={isOpen}
        onClose={handleCloseClick}
    >
        <DialogTitle>
            {title}
        </DialogTitle>
        <DialogContent>
            <SimpleForm
                validate={validateUserCreation}
                // We override the redux-form name to avoid collision with the react-admin main form
                form={formName}
                // We override the redux-form onSubmit prop to handle the submission ourselves
                onSubmit={handleSubmit}
                // We want no toolbar at all as we have our modal actions
                toolbar={null}
            >
                <NumberInput
                    source="packagesQty"
                    defaultValue={1}
                    inputProps={{
                        step: 1,
                        min: 1,
                    }}
                    label={translate('resources.omni_orders.fields.packagesQty')}
                />
                <NumberInput
                    source="totalWeight"
                    defaultValue={0.25}
                    inputProps={{
                        step: 0.05,
                        min: 0.05,
                    }}
                    label={translate('resources.omni_orders.fields.totalWeight')}
                />
            </SimpleForm>
        </DialogContent>
        <DialogActions>
            <Button
                label="ra.action.cancel"
                onClick={handleCloseClick}
            >
                <IconCancel />
            </Button>
            <SaveButton
                label={'resources.omni_orders.data.confirm'}
                onClick={handleSaveClick}
            />
        </DialogActions>
    </Dialog>
);

ShipmentSubmitDialog.propTypes = {
    formName: PropTypes.node,
    translate: PropTypes.func,
    handleCloseClick: PropTypes.func,
    handleSaveClick: PropTypes.func,
    isOpen: PropTypes.bool,
    handleSubmit: PropTypes.func,
    title: PropTypes.node,
};

export default ShipmentSubmitDialog;

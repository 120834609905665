import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/functions';


const init = (config) => {
    if (!firebase.apps.length) {
        firebase.initializeApp(config);
    }

    const firestore = firebase.firestore();
    const auth = firebase.auth();
    const functions = firebase.functions();
    const database = firebase.database();

    return {
        firestore,
        auth,
        functions,
        database,
    };
};

export default init;

import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { createLogger } from 'redux-logger';
import { routerMiddleware } from 'react-router-redux';
import createHistory from 'history/createHashHistory';
import { adminSaga, formMiddleware } from 'react-admin';
import englishMessages from '../i18n/en';
import norwegianMessages from '../i18n/nb';
import initFirebase from '../database/firebase';
import createAuthProvider from '../database/authProvider';
import createAdminStore from './createAdminStore';
import createDataProvider from '../database/dataProvider';
import customSagas from './sagas';
import reducers from './reducers';
import withConfig from './firebaseConfigs';


export const firebase = withConfig(initFirebase);

export const history = createHistory();
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, routerMiddleware(history), formMiddleware];
const loggerMiddleware = createLogger({
    predicate: () => process.env.REACT_APP_ENV === 'development',
    collapsed: true,
});
middlewares.push(loggerMiddleware);

const customReducers = reducers;

const i18nProvider = (locale) => {
    if (locale === 'nb') {
        return norwegianMessages;
    }

    // Always fallback on english
    return englishMessages;
};

export const store = createAdminStore({
    middlewares,
    customReducers,
    locale: 'nb',
    i18nProvider,
});

const dataProvider = createDataProvider(store);
export const authProvider = createAuthProvider(store);

const saga = function* rootSaga() {
    yield all(
        [
            adminSaga(dataProvider, authProvider, i18nProvider),
            ...customSagas,
        ].map(fork),
    );
};

sagaMiddleware.run(saga);

import { GET_LIST, GET_ONE } from 'react-admin';
import firebase from 'firebase';
import { getCompanyId } from '../components/companies/index';

export default (store) => {
    const getOne = (resource, params) => {
        const func = firebase.functions().httpsCallable('omniOrdersFetch');
        const data = { companyId: getCompanyId(store.getState()), type: 'one', orderId: params.id };

        return func(data).then(({ data: order }) => ({
            data: { ...order, id: order.orderId, firestoreDocId: order.id },
        }));
    };

    const getList = (resource, params) => {
        const func = firebase.functions().httpsCallable('omniOrdersFetch');
        const data = { companyId: getCompanyId(store.getState()), type: 'list', params };

        return func(data).then(result => ({
            data: result.data.orders.map(order => ({ ...order, id: order.orderId, firestoreDocId: order.id })),
            total: result.data.total,
        }));
    };

    return (type, resource, params) => {
        const handlers = {
            [GET_ONE]: getOne,
            [GET_LIST]: getList,
        };
        const handler = handlers[type];
        if (!handler) {
            throw new Error(`OmniOrdersDataProvider does not support the "${type}" operation`);
        }

        return handler(resource, params);
    };
};

import * as React from 'react';
import * as RA from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Button } from 'ra-ui-materialui';
import SearchButton from './SearchButton';
import SearchResultRadio from './SearchResultRadio';
import { ListActions } from '../listActions';
import DataFieldWarning from './DataFieldWarning';
import StoreEditFormMainTab from './StoreEditFormMainTab';
import { FirestoreDateField } from '../fields';
import countries from './countries';
import { ROUTES } from '../../framework/routes';


const InstructionsButton = ({ translate }) => (
    <Button
        component={Link}
        to={ROUTES.INSTRUCTIONS}
        label={translate('resources.instructions.name')}
    />
);

export const StoreList = RA.translate(({ translate, ...props }) => (
    <RA.List
        title={translate('resources.storefinder.name')}
        sort={{ field: 'createdAt', order: 'ASC' }}
        actions={<ListActions CustomActionComponent={InstructionsButton} />}
        filters={<CommandFilter translate={translate} />}
        {...props}
    >
        <RA.Datagrid rowClick="edit">
            <RA.TextField source="name" label={translate('resources.storefinder.fields.name')} />
            <RA.TextField source="address" label={translate('resources.storefinder.fields.address')} />
            <RA.TextField source="city" label={translate('resources.storefinder.fields.city')} />
            <RA.TextField source="country" label={translate('resources.storefinder.fields.country')} />
            <RA.TextField source="region" label={translate('resources.storefinder.fields.region')} />
            <RA.TextField source="externalId" label={translate('resources.storefinder.fields.externalId')} />
            <FirestoreDateField
                source="createdAt"
                locales="nb-NO"
                options={{
                    year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric',
                }}
                label={translate('resources.storefinder.fields.created')}
            />
            <DataFieldWarning notice={translate('resources.storefinder.notification.latLng')} />
            <RA.BooleanField source="isCacEnabled" label={translate('resources.storefinder.fields.isCacEnabled')} />
            <RA.EditButton />
        </RA.Datagrid>
    </RA.List>
));

const CommandFilter = ({ classes, translate, ...props }) => (
    <RA.Filter {...props}>
        <RA.SearchInput label={translate('resources.storefinder.fields.name')} source="name" />
        <RA.SearchInput label={translate('resources.storefinder.fields.address')} source="address" />
        <RA.SearchInput label={translate('resources.storefinder.fields.city')} source="city" />
        <RA.SearchInput label={translate('resources.storefinder.fields.country')} source="country" />
        <RA.SearchInput label={translate('resources.storefinder.fields.externalId')} source="externalId" />
    </RA.Filter>
);


export const StoreEdit = props => (
    <StoreEditFormMainTab {...props} />
);

export const StoreCreate = RA.translate(({ translate, ...props }) => (
    <RA.Create {...props} title={translate('resources.storefinder.action.new_store')}>
        <RA.SimpleForm toolbar={<StoreSubmitToolbar translate={translate} />}>
            <RA.BooleanInput
                source="showOnMap"
                label={translate('resources.storefinder.fields.showOnMap')}
                defaultValue
            />
            <RA.BooleanInput
                source="isCacEnabled"
                label={translate('resources.storefinder.fields.isCacEnabled')}
                defaultValue={false}
            />
            <RA.TextInput source="name" label={translate('resources.storefinder.fields.name')} />
            <RA.SelectInput
                source="country"
                choices={countries}
                label={translate('resources.storefinder.fields.country')}
            />
            <RA.TextInput source="region" label={translate('resources.storefinder.fields.region')} />
            <RA.TextInput source="postcode" label={translate('resources.storefinder.fields.postcode')} />
            <RA.TextInput source="city" label={translate('resources.storefinder.fields.city')} />
            <RA.TextInput source="address" label={translate('resources.storefinder.fields.address')} />
            <SearchResultRadio {...props} />
        </RA.SimpleForm>
    </RA.Create>
));

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    },
});


export const StoreSubmitToolbar = withStyles(styles)(
    ({ translate, classes, ...props }) => (
        <RA.Toolbar {...props}>
            <RA.FormDataConsumer>
                {({ formData, ...rest }) => (
                    <SearchButton
                        className={classes.button}
                        formData={formData}
                        label={translate('resources.storefinder.action.find_address')}
                    />
                )}
            </RA.FormDataConsumer>

            <RA.SaveButton
                className={classes.button}
                label={translate('ra.action.save')}
                redirect="edit"
                submitOnEnter={false}
            />
        </RA.Toolbar>
    ),
);

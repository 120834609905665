import {
    CREATE, DELETE, GET_LIST, GET_MANY, GET_MANY_REFERENCE, GET_ONE, UPDATE,
} from 'react-admin';
import { getPathBuilder, RESOURCES } from 'omni-shared/resources';
import { getIsLoggedIn } from '../store/selectors/adminSelectors';
import { getCompanyId } from '../components/companies';


export const getResourcePath = store => (requestType, resourceName, relatedDocId) => {
    const state = store.getState();
    validateUserHasCompany(state);

    const buildPath = getPathBuilder(getCompanyId(state), relatedDocId);

    const resources = getResourcesWithPathsFuncs(buildPath);
    const pathFunc = getPathFuncByResourceName(resources, resourceName);
    const path = pathFunc(requestType);

    return validatePath(path, resourceName, requestType);
};

function validateUserHasCompany(state) {
    if (!getCompanyId(state) && getIsLoggedIn(state)) {
        throw new Error('User is not assigned to a company');
    }
}

const getResourcesWithPathsFuncs = buildPath => ({
    [RESOURCES.STORES]:
        getResultIfItemInArray([CREATE, UPDATE, DELETE, GET_ONE, GET_LIST, GET_MANY], buildPath.storefinder),
    [RESOURCES.ORDERS]:
        getResultIfItemInArray([CREATE, UPDATE, DELETE, GET_LIST, GET_ONE], buildPath.cac),
    [RESOURCES.ORDERS_ITEMS]:
        getResultIfItemInArray([UPDATE, DELETE, GET_ONE, GET_MANY_REFERENCE], buildPath.ordersItems),
    [RESOURCES.ORDERS_MESSAGES]:
        getResultIfItemInArray([GET_ONE, CREATE, GET_MANY_REFERENCE], buildPath.ordersMessages),
    [RESOURCES.ORDERS_STATUSES]:
        getResultIfItemInArray([GET_MANY_REFERENCE], buildPath.ordersStatuses),
    [RESOURCES.SETTINGS]:
        getResultIfItemInArray([UPDATE, GET_ONE, GET_LIST], buildPath.settings),
    [RESOURCES.COMPANIES]:
        getResultIfItemInArray([GET_ONE, GET_LIST, UPDATE], buildPath.companies),
    [RESOURCES.USERS]:
        getResultIfItemInArray([UPDATE, DELETE, GET_ONE, GET_LIST], buildPath.users),
    [RESOURCES.COUNTERS_SMS_DAILY]:
        getResultIfItemInArray([GET_LIST], buildPath.countersSmsDaily),
    [RESOURCES.COUNTERS_SMS_MONTHLY]:
        getResultIfItemInArray([GET_LIST], buildPath.countersSmsMonthly),
    [RESOURCES.COUNTERS_EMAIL_DAILY]:
        getResultIfItemInArray([GET_LIST], buildPath.countersEmailDaily),
    [RESOURCES.COUNTERS_EMAIL_MONTHLY]:
        getResultIfItemInArray([GET_LIST], buildPath.countersEmailMonthly),
    [RESOURCES.EXCHANGE_ORDERS_ITEMS]:
        getResultIfItemInArray([GET_MANY_REFERENCE, CREATE, GET_ONE], buildPath.exchangeOrderItems),
    [RESOURCES.OMNI_ORDERS]:
        getResultIfItemInArray([CREATE, UPDATE, DELETE, GET_LIST, GET_ONE, GET_MANY_REFERENCE], buildPath.omniOrders),
    [RESOURCES.OMNI_ORDERS_ITEMS]:
        getResultIfItemInArray([UPDATE, DELETE, GET_ONE, GET_MANY_REFERENCE], buildPath.omniOrdersItems),
});

function getResultIfItemInArray(arr, resultFunc) {
    return function (item) {
        if (arr.includes(item)) {
            return resultFunc();
        }
        return undefined;
    };
}

function getPathFuncByResourceName(resources, resourceName) {
    const pathFunc = resources[resourceName];
    if (!pathFunc) {
        throw new Error(`Unsupported resource "${resourceName}"`);
    }
    return pathFunc;
}

function validatePath(path, resource, requestType) {
    if (!path) {
        throw new Error(`Unsupported operation "${requestType}" for "${resource}"`);
    }
    return path;
}

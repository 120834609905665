import {
    all, call, put, takeEvery, select,
} from 'redux-saga/effects';
import { crudGetOne, USER_LOGIN_SUCCESS } from 'react-admin';
import { RESOURCES } from 'omni-shared/resources';
import { getUser, getCompany } from '../../database/database';
import { currentUser, getClaims } from '../../services/auth';
import { renderApp, stopRenderingApp, userSetPermissions } from '../../store/actions';
import { setUserCompanyId } from '../companies';


export function* handleLogin() {
    try {
        yield put(stopRenderingApp());
        const authUser = currentUser();
        const claims = yield call(getClaims, authUser);
        const [user, company] = yield all([
            call(getUser, claims.company, authUser.uid),
            call(getCompany, claims.company),
            put(setUserCompanyId({ id: claims.company })),
        ]);

        if (authUser.emailVerified) {
            yield put(crudGetOne(RESOURCES.COMPANIES, claims.company));
        }
        const mergedFeatures = { ...company.features, ...user.features };

        const completePermissions = { roles: { ...user, admin: claims.isAdmin || false }, features: mergedFeatures };
        yield put(userSetPermissions(completePermissions));
    } catch (e) {
        console.log(e);
    } finally {
        yield put(renderApp());
    }
}

export default function* () {
    yield takeEvery(USER_LOGIN_SUCCESS, handleLogin);
}

// TODO:
// const user = {
//     permissions: {
//         admin: true,
//     },
//     companyId: '1212313rstrst',
//     id: '223ni3n34uy5n3y4u5',
// };

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardActions from '@material-ui/core/CardActions';
import Block from '@material-ui/icons/Block';
import Done from '@material-ui/icons/Done';
import { Button } from '@material-ui/core';
import { getIsLoading } from '../../store/selectors';
import { getEmailVerificationError, getIsEmailVerified } from './emailVerifySelectors';
import { ROUTES } from '../../framework/routes';
import { emailVerifyStart } from './emailVerifyActions';


const styles = () => ({
    input: {
        marginTop: '1em',
        marginBottom: '1em',
    },
    link: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    alignCenter: { textAlign: 'center' },
    spinner: { display: 'flex', justifyContent: 'center' },
});

const LoginLink = ({ classes, translate }) => (
    <CardActions style={{ justifyContent: 'center' }}>
        <Link to={{ pathname: ROUTES.LOGIN }} className={classes.link}>
            {translate('resources.auth.actions.signIn')}
        </Link>
    </CardActions>
);

class EmailVerifyForm extends Component {
    componentDidMount() {
        this.verify();
    }

    verify = () => {
        const { emailVerifyStart, actionCode } = this.props;

        return emailVerifyStart({ actionCode });
    }

    renderFailure = () => {
        const { classes, translate } = this.props;
        return (
            <div className={classes.alignCenter}>
                <Typography variant="title">
                    <Block color="error" />
                    {translate('resources.auth.data.pass_not_verified')}
                </Typography>
                <Typography>{translate('resources.auth.data.verification_error')}</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.verify()}
                >
                    {translate('resources.auth.data.retry')}
                </Button>
                <LoginLink {...{ classes, translate }} />
            </div>
        );
    }

    renderSuccess = () => {
        const { classes, translate } = this.props;
        return (
            <div className={classes.alignCenter}>
                <Typography variant="title">
                    <Done color="primary" />
                    {translate('resources.auth.data.pass_verified')}
                </Typography>
                <Typography>{translate('resources.auth.data.can_login')}</Typography>
                <LoginLink {...{ classes, translate }} />
            </div>
        );
    }

    renderLoading = () => <div className={this.props.classes.spinner}><CircularProgress /></div>;

    render() {
        const { emailVerified, isLoading, emailVerificationFailure } = this.props;

        if (isLoading) {
            return this.renderLoading();
        } if (emailVerified) {
            return this.renderSuccess();
        } if (emailVerificationFailure) {
            return this.renderFailure();
        }
        return null;
    }
}

EmailVerifyForm.propTypes = {
    redirectTo: PropTypes.string,
    emailVerifyStart: PropTypes.func,
    actionCode: PropTypes.string,
    emailVerified: PropTypes.bool,
    isLoading: PropTypes.bool,
    classes: PropTypes.object,
    translate: PropTypes.func,
    emailVerificationFailure: PropTypes.bool,
};

const mapStateToProps = state => ({
    isLoading: getIsLoading(state),
    emailVerified: getIsEmailVerified(state),
    emailVerificationFailure: getEmailVerificationError(state),
});

const enhance = compose(
    withStyles(styles),
    translate,
    connect(mapStateToProps, { emailVerifyStart }),
);

export default enhance(EmailVerifyForm);

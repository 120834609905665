import {
    AUTH_CHECK, AUTH_GET_PERMISSIONS, AUTH_LOGIN, AUTH_LOGOUT,
} from 'react-admin';
import trimStart from 'lodash/trimStart';
import { currentUser, signInWithEmailAndPassword, signOut } from '../services/auth';
import {
    getIsAdmin, getIsLoggedIn, getIsOwner, getPermissions,
} from '../store/selectors';
import { ROUTES } from '../framework/routes';


export default (store) => {
    const login = ({ username, password }) => {
        return signInWithEmailAndPassword(username, password);
    };

    const authCheck = (params) => {
        if (currentUser()) {
            return Promise.resolve();
        }
        return Promise.reject();
    };
    const getFirstValidHashParam = hash => hash.split('/').pop().split('?')[0];

    const isAccessOpenForAll = (params, urlPath) => params === undefined
        && [ROUTES.SIGNUP, ROUTES.RESET_PASSWORD, ROUTES.USER_MGMT, ROUTES.LOGIN]
            .map(route => trimStart(route, '/'))
            .includes(urlPath);

    const authGetPermissions = (params) => {
        const urlPath = getFirstValidHashParam(window.location.hash);
        const state = store.getState();
        const permissions = getPermissions(state);

        if (isAccessOpenForAll(params, urlPath)) {
            return Promise.resolve(permissions);
        }

        if (!getIsLoggedIn(state)) {
            return Promise.reject();
        }
        if (getIsAdmin(state)) {
            return Promise.resolve(permissions);
        }
        if (getIsOwner(state)) {
            return Promise.resolve(permissions);
        }
        return Promise.resolve(permissions);
    };

    return (type, params) => {
        console.log('authProvider', type, params);

        const handlers = {
            [AUTH_LOGIN]: login,
            [AUTH_LOGOUT]: signOut,
            [AUTH_CHECK]: authCheck,
            [AUTH_GET_PERMISSIONS]: authGetPermissions,
        };

        const handler = handlers[type];
        if (!handler) {
            console.warn(`Authprovider does not support the "${type}" operation`);
            return;
        }

        return handler(params);
    };
};

import { getObjectProp } from '../../utils/functions';

export const getIsLoading = state =>
    state.admin.loading > 0;

export const getIsLoggedIn = state =>
    state.admin.auth.isLoggedIn;

const buildResourceDataPath = (resourceName, id) =>
    (id === undefined ? `admin.resources.${resourceName}.data`
        : `admin.resources.${resourceName}.data.${id}`);

export const buildResourceDataGetter = state =>
    (resourceName, id) =>
        getObjectProp(state, buildResourceDataPath(resourceName, id));

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { refreshView } from 'react-admin';

const REFRESH_INTERVAL = 60 * 1000; // 60 seconds

function withAutoRefresh(WrappedComponent, refreshInterval = REFRESH_INTERVAL) {
    class AutoRefresher extends React.Component {
        componentDidMount() {
            this.refreshInterval = setInterval(this.props.refreshView, refreshInterval);
        }

        componentWillUnmount() {
            if (this.refreshInterval) clearInterval(this.refreshInterval);
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    AutoRefresher.propTypes = {
        refreshView: PropTypes.func,
    };

    const enhance = compose(
        connect(
            null,
            { refreshView },
        ),
    );

    return enhance(AutoRefresher);
}

export default withAutoRefresh;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { SeparatedField, PaddedLine } from '../../fields';

const OrderDetails = ({ translate, exchange }) => (
    <Fragment>
        <PaddedLine>
            <SeparatedField title={translate('resources.exchange.fields.order')} separator=":">
                {`#${exchange.increment_id}`}
            </SeparatedField>
        </PaddedLine>
        <PaddedLine>
            <SeparatedField title={translate('resources.exchange.fields.created_at')} separator=":">
                {exchange.created_at}
            </SeparatedField>
        </PaddedLine>
        <PaddedLine>
            <SeparatedField title={translate('resources.exchange.fields.valid_until')} separator=":">
                {exchange.valid_until}
            </SeparatedField>
        </PaddedLine>
    </Fragment>
);

OrderDetails.propTypes = {
    translate: PropTypes.func,
    exchange: PropTypes.object,
};

export default OrderDetails;

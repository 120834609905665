import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Countdown from 'react-countdown';
import { getDeadlineDate } from '../../../utils/firebase';

const ActionButton = ({
    onClick, icon, title, classname, buttonsDisabled, deadline,
}) => {
    const getButton = ({ completed }) => (
        <Button disabled={buttonsDisabled || completed} className={classname} onClick={onClick}>
            {icon}
            <span style={{ fontWeight: 'bold' }}>{title.toUpperCase()}</span>
            <span>&nbsp;</span>
        </Button>
    );
    return (
        deadline
            ? (
                <Countdown
                    date={getDeadlineDate(deadline)}
                    renderer={getButton}
                />
            )
            : getButton({ completed: true })

    );
};

ActionButton.propTypes = {
    onClick: PropTypes.func,
    icon: PropTypes.object,
    title: PropTypes.node,
    classname: PropTypes.node,
    buttonsDisabled: PropTypes.bool,
    deadline: PropTypes.object,
};

export default ActionButton;

import React from 'react';
import {
    BooleanInput,
    Edit,
    FormDataConsumer,
    FormTab,
    LongTextInput,
    maxLength,
    NumberInput,
    required,
    showNotification,
    TabbedForm,
    TextInput,
    translate,
} from 'react-admin';
import compose from 'recompose/compose';
import { Field } from 'redux-form';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import get from 'lodash/get';
import { connect } from 'react-redux';
import ApiKeyInput from './ApiKeyInput';
import { AppBarTitle } from '../../components/title';
import { SaveButtonToolbar } from '../../components/toolbar';


const CustomInput = ({ label }) => (
    <span>
        <Field
            name="api.key"
            component={translate(connect(null, { showNotification })(ApiKeyInput))}
            label={label}
        />
    </span>
);

const styles = {
    title: { marginTop: '1em' },
    panel: { width: '400px' },
};

function Settings({ classes, translate, ...props }) {
    return (
        <Edit title={<AppBarTitle translate={translate} />} {...props}>
            <TabbedForm
                title={translate('resources.settings.name')}
                toolbar={<SaveButtonToolbar />}
            >
                <FormTab label={translate('resources.settings.tabs.general')}>
                    <BooleanInput
                        source="cancelAfterDeadline"
                    />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => get(formData, 'cancelAfterDeadline')
                            && (
                                <NumberInput
                                    source="cancelAfter"
                                    label={translate('resources.settings.fields.cancelAfter')}
                                    step={1}
                                />
                            )
                        }
                    </FormDataConsumer>
                    <CustomInput
                        label={translate('resources.settings.fields.api.key')}
                        source="api.key"
                        style={{ width: '400px' }}
                        validate={[maxLength(11)]}
                    />
                </FormTab>
                {/* New order */}
                <FormTab label={translate('resources.settings.tabs.new_order')}>
                    <BooleanInput
                        source="newOrderCustomer.email.enabled"
                    />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => get(formData, 'newOrderCustomer.email.enabled')
                            && (
                                <TextInput
                                    validate={[required()]}
                                    source="newOrderCustomer.email.templateId"
                                    style={{ width: '400px' }}
                                    {...rest}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <BooleanInput
                        source="newOrderCustomer.sms.enabled"
                    />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => get(formData, 'newOrderCustomer.sms.enabled')
                            && (
                                <LongTextInput
                                    validate={[required()]}
                                    source="newOrderCustomer.sms.template"
                                    style={{ width: '400px' }}
                                    {...rest}
                                />
                            )
                        }
                    </FormDataConsumer>

                    {/* Store notification */}
                    <BooleanInput
                        source="newOrderStore.email.enabled"
                    />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => get(formData, 'newOrderStore.email.enabled')
                            && (
                                <TextInput
                                    validate={[required()]}
                                    source="newOrderStore.email.templateId"
                                    style={{ width: '400px' }}
                                    {...rest}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <BooleanInput
                        source="newOrderStore.sms.enabled"
                    />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => get(formData, 'newOrderStore.sms.enabled')
                            && (
                                <LongTextInput
                                    validate={[required()]}
                                    source="newOrderStore.sms.template"
                                    style={{ width: '400px' }}
                                    {...rest}
                                />
                            )
                        }
                    </FormDataConsumer>

                </FormTab>
                <FormTab label={translate('resources.settings.tabs.retrieval_message')}>
                    <BooleanInput
                        source="retrievalMessage.email.enabled"
                    />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => get(formData, 'retrievalMessage.email.enabled')
                            && (
                                <TextInput
                                    validate={[required()]}
                                    source="retrievalMessage.email.templateId"
                                    style={{ width: '400px' }}
                                    {...rest}
                                />
                            )
                        }
                    </FormDataConsumer>
                    <BooleanInput
                        source="retrievalMessage.sms.enabled"
                    />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => get(formData, 'retrievalMessage.sms.enabled')
                            && (
                                <LongTextInput
                                    validate={[required()]}
                                    source="retrievalMessage.sms.template"
                                    style={{ width: '400px' }}
                                    {...rest}
                                />
                            )
                        }
                    </FormDataConsumer>
                </FormTab>
                {/* Manual message */}
                <FormTab label={translate('resources.settings.tabs.manual_message')}>
                    <TextInput
                        source="manualMessage.email.templateId"
                        style={{ width: '400px' }}
                    />

                </FormTab>
                {/* Order cancellation */}
                <FormTab label={translate('resources.settings.tabs.cancellation_message')}>
                    <BooleanInput
                        source="cancellationMessage.email.enabled"
                    />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => get(formData, 'cancellationMessage.email.enabled')
                            && (
                                <TextInput
                                    validate={[required()]}
                                    source="cancellationMessage.email.templateId"
                                    style={{ width: '400px' }}
                                    {...rest}
                                />
                            )
                        }
                    </FormDataConsumer>
                    <BooleanInput
                        source="cancellationMessage.sms.enabled"
                    />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => get(formData, 'cancellationMessage.sms.enabled')
                            && (
                                <LongTextInput
                                    validate={[required()]}
                                    source="cancellationMessage.sms.template"
                                    style={{ width: '400px' }}
                                    {...rest}
                                />
                            )
                        }
                    </FormDataConsumer>
                </FormTab>
                {/* Order pickup reminder */}
                <FormTab label={translate('resources.settings.tabs.pickup_reminder')}>
                    <BooleanInput
                        source="pickupReminder.email.enabled"
                    />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => get(formData, 'pickupReminder.email.enabled')
                            && (
                                <TextInput
                                    validate={[required()]}
                                    source="pickupReminder.email.templateId"
                                    style={{ width: '400px' }}
                                    {...rest}
                                />
                            )
                        }
                    </FormDataConsumer>
                    <BooleanInput
                        source="pickupReminder.sms.enabled"
                    />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => get(formData, 'pickupReminder.sms.enabled')
                            && (
                                <LongTextInput
                                    validate={[required()]}
                                    source="pickupReminder.sms.template"
                                    style={{ width: '400px' }}
                                    {...rest}
                                />
                            )
                        }
                    </FormDataConsumer>
                </FormTab>
                {/* SMS service credentials */}
                <FormTab label="SMS service">
                    <TextInput
                        source="smsService.senderName"
                        validate={[maxLength(11)]}
                        style={{ width: '400px' }}
                    />
                    <TextInput
                        source="smsService.username"
                        style={{ width: '400px' }}
                    />
                    <TextInput
                        source="smsService.password"
                        type="password"
                        style={{ width: '400px' }}
                    />
                    <ExpansionPanel className={classes.panel}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>
                                {translate('resources.settings.fields.sms_variables')}
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                <div>{'Store name: {{store_name}}'}</div>
                                <div>{'Address: {{store_address}}'}</div>
                                <div>{'Postcode: {{store_postcode}}'}</div>
                                <div>{'City: {{store_city}}'}</div>
                                <div>{'Phone: {{store_phone}}'}</div>
                                <div>{'Order ID: {{order_id}}'}</div>
                                <div>{'Manual message content: {{content}}'}</div>
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </FormTab>
            </TabbedForm>

        </Edit>
    );
}

const enhance = compose(
    translate,
    withStyles(styles),
);

export default enhance(Settings);

import firebase from 'firebase';

function getUser(companyId, uid) {
    return firebase.firestore()
        .collection(`companies/${companyId}/users`)
        .doc(uid)
        .get()
        .then(doc => doc.data());
}

function getSettings(companyId, settingsPath = 'click_and_collect') {
    return firebase.firestore()
        .collection(`companies/${companyId}/settings`)
        .doc(settingsPath)
        .get()
        .then(doc => doc.data())
        .catch((error) => {
            console.log('email verification', 'An error happened');
        });
}

function getCompany(companyId) {
    return firebase.firestore()
        .collection('companies')
        .doc(companyId)
        .get()
        .then(doc => doc.data())
        .catch((error) => {
            console.log('getting company data', 'An error happened');
        });
}

export {
    getSettings,
    getUser,
    getCompany,
};

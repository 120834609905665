import React from 'react';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import Button from '@material-ui/core/Button';
import { translate } from 'react-admin';

// prints component that is passed to PrintButton as render prop
class PrintButton extends React.Component {
    render() {
        const { translate, render } = this.props;
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <ReactToPrint
                    trigger={() => (
                        <Button
                            variant="outlined"
                            style={{ alignSelf: 'flex-end', marginBottom: '1em' }}
                        >
                            {translate('utilities.print')}
                        </Button>
                    )
                    }
                    pageStyle={`
                        .remove-scroll { overflow-y: unset!important; max-height: none!important; }
                        .remove-from-print { display: none!important; }
                        .hide-from-print { visibility: hidden!important; margin-bottom: -100px; }
                        .react-numeric-input > b { display: none!important; }
                        .react-numeric-input > input { border: none!important; }

                        html
                        {
                            background-color: #FFFFFF;
                            margin: 0px;
                        }

                        header
                        {
                            display:none;
                        }

                        body
                        {
                            margin: 10mm 10mm 10mm 10mm;
                        }
                    `}
                    content={() => this.componentRef}
                />
                {render((el) => { this.componentRef = el; })}
            </div>
        );
    }
}

PrintButton.propTypes = {
    render: PropTypes.func,
    translate: PropTypes.func,
};

export default translate(PrintButton);

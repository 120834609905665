import React from 'react';
import { SaveButton, Toolbar } from 'react-admin';

const SaveButtonToolbar = props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

export default SaveButtonToolbar;

import React from 'react';
import { Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import TableCell from '@material-ui/core/TableCell';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { isNullOrUndefined } from '../../utils/validation';
import { firestoreDateToMillis } from '../../utils/firebase';


export const sortByDate = (field, direction = 'desc') => (x, y) => {
    const fieldX = firestoreDateToMillis(get(x, field));
    const fieldY = firestoreDateToMillis(get(y, field));

    if ([fieldX, fieldY].some(isNullOrUndefined)) {
        return 0;
    }
    if (direction === 'desc') {
        if (fieldX > fieldY) {
            return -1;
        }
        if (fieldX < fieldY) {
            return 1;
        }
    } else {
        if (fieldX < fieldY) {
            return -1;
        }
        if (fieldX > fieldY) {
            return 1;
        }
    }
    return 0;
};

export const UppercaseHeadline = ({ text = '' }) => (
    <Typography variant="headline">
        {text.toUpperCase()}
    </Typography>
);

UppercaseHeadline.propTypes = {
    text: PropTypes.string,
};

export const BorderlessTableCell = withStyles({
    root: {
        borderBottom: 'none',
    },
})(TableCell);

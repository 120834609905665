import { all, put, takeEvery } from 'redux-saga/effects';
import { showNotification } from 'react-admin';
import { CHANNELS, MESSAGES, NOTIFICATION } from 'omni-shared/constants';
import { SEND_MANUAL_MESSAGE, sendMessage } from './ordersActions';

const determineMessageChannels = channel => (channel === CHANNELS.ALL ? [CHANNELS.SMS, CHANNELS.EMAIL] : [channel]);

const buildMessage = ({ content, orderId }) => (channel) => {
    const messagePayload = {
        data: {
            type: MESSAGES.MANUAL,
            channel,
            content,
        },
        parentId: orderId,
    };
    return put(sendMessage(messagePayload));
};

function* handleMessage({ payload }) {
    try {
        const { channel } = payload;
        const channels = determineMessageChannels(channel);

        const sendChannelMessage = buildMessage(payload);
        yield all(channels.map(sendChannelMessage));

        yield put(showNotification('resources.messages.notification.sent'));
    } catch (e) {
        console.log(e);
        yield put(showNotification('resources.messages.notification.sending_failure', NOTIFICATION.WARNING));
    }
}

export default function* messageSaga() {
    yield takeEvery(SEND_MANUAL_MESSAGE, handleMessage);
}

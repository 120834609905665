import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import CtaButtons from './CtaButtons';
import OrdersItemGrid from './OrderItemsGrid';
import { UppercaseHeadline } from '../utilities';
import { CustomNumberField } from '../../fields';

const sanitizeRestProps = ({
    crudGetOneAction,
    crudGetManyReferenceAction,
    setOrderStatusPickedup,
    setOrderStatusCanceled,
    sendStatusChangeMessage,
    sendRetrievalMessage,
    setOrderStatusOnHold,
    setOrderStatusWaitingPickup,
    handleMessageSubmit,
    isComponentLoading,
    classes,
    pickUp,
    cancel,
    onHold,
    ...rest
}) => rest;

const OrderItems = (props) => {
    const {
        classes, order, translate, lastRetrievalMessage, sendRetrievalMessage, pickUp, cancel, onHold,
    } = props;
    return (
        <Card className={classes.card}>
            <CardHeader
                title={<UppercaseHeadline text={translate('resources.orders.tabs.items')} />}
                disableTypography
            />
            <CardContent>
                <OrdersItemGrid {...sanitizeRestProps(props)} />
            </CardContent>
            <CardActions
                className={classnames(classes.cardActions, classes.flex, classes.column, classes.right)}
            >
                <div>
                    {order.total && (
                        <CustomNumberField
                            value={order.total}
                            title="Total"
                        />
                    )}
                </div>
            </CardActions>
            <div className={classnames(classes.flex, classes.end, 'remove-from-print')}>
                <CtaButtons
                    lastRetrievalDate={lastRetrievalMessage ? lastRetrievalMessage.sentAt : null}
                    sendRetrievalMessage={sendRetrievalMessage}
                    pickUp={pickUp}
                    cancel={cancel}
                    onHold={onHold}
                />
            </div>
        </Card>
    );
};

OrderItems.propTypes = {
    classes: PropTypes.object,
    order: PropTypes.object,
    translate: PropTypes.func,
    lastRetrievalMessage: PropTypes.object,
    sendRetrievalMessage: PropTypes.func,
    pickUp: PropTypes.func,
    cancel: PropTypes.func,
    onHold: PropTypes.func,
};

export default OrderItems;

import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import withStyles from '@material-ui/core/styles/withStyles';
import { OMNI_ORDER_STATUSES } from 'omni-shared/constants';
import Paper from '@material-ui/core/Paper';
import ItemInfo from './ItemInfo';
import ItemImage from './ItemImage';
import ItemCheckbox from './ItemCheckbox';

const styles = {
    itemContainer: {
        display: 'flex',
        padding: '1em',
        fontSize: '0.875rem',

    },
    infoContainer: {
        paddingLeft: '1em',
        width: '100%',
        '&> div': {
            display: 'flex',
        },
    },
    attributes: {
        paddingTop: '0.5em',
    },
    spinner: {
        display: 'flex',
        justifyContent: 'center',
    },
    separator: {
        width: '45%',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
    },
    itemName: {
        fontWeight: '700',
        marginBottom: '5px',
    },
    itemAttributes: {
        width: '100%',
    },
    checkbox: {
        width: 'unset!important',
        height: 'unset!important',
        fontSize: '50px',
    },
    iconSize: {
        fontSize: '25px',
    },
    label: {
        marginLeft: '0px',
    },
};

const OrderItems = ({
    classes, translate, record, onChange, checked,
}) => {
    const isCheckboxDisabled = OMNI_ORDER_STATUSES.LOCKED !== record.status;

    return (
        record.items.map((item, index) => (
            <div key={item.sku} style={{ margin: '15px' }}>
                <Paper>
                    <div className={classes.itemContainer}>
                        <ItemImage imageUrl={item.imageUrl} />
                        <ItemInfo data={item} {...{ classes, translate }}>
                            <ItemCheckbox
                                data={item}
                                label={translate('resources.omni_orders.fields.deliver')}
                                disabled={isCheckboxDisabled}
                                {...{ onChange, checked, classes }}
                            />
                        </ItemInfo>
                    </div>
                    {(index < record.items.length - 1) && <div className={classes.separator} />}
                </Paper>
            </div>
        ))
    );
};

OrderItems.propTypes = {
    classes: PropTypes.object,
    translate: PropTypes.func,
    record: PropTypes.object,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
};

const enhance = compose(
    withStyles(styles),
);

export default enhance(OrderItems);

export const quantityPicker = ({ qtyOrdered, qtyPicked, ...rest }) => ({
    pick(numberPicked) {
        const totalPicked = qtyPicked + numberPicked;
        return {
            ...rest,
            qtyPicked: totalPicked,
            qtyAvailable: qtyOrdered - totalPicked,
            picked: qtyOrdered === totalPicked,
        };
    },
    reset() {
        return {
            ...rest,
            qtyAvailable: qtyOrdered,
            qtyPicked: 0,
            picked: false,
        };
    },
});

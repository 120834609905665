import React from 'react';
import PropTypes from 'prop-types';
import { UppercaseHeadline } from '../../orders/utilities';

const IsValidField = ({ title, classname }) => <div className={classname}>{title.toUpperCase()}</div>;

const CardTitle = ({ exchange, translate, classes }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
        <UppercaseHeadline
            text={`${translate('resources.exchange.name')} ${(exchange.exchange_id || '')}`}
        />
        <IsValidField
            title={exchange.is_exchange_valid
                ? translate('resources.exchange.data.valid')
                : translate('resources.exchange.data.invalid')
            }
            classname={exchange.is_exchange_valid ? classes.exchangeValid : classes.exchangeInValid}
        />
    </div>
);

CardTitle.propTypes = {
    translate: PropTypes.func,
    exchange: PropTypes.object,
    classes: PropTypes.object,
};

export default CardTitle;

import { RESOURCES } from 'omni-shared/resources';
import { CREATE, GET_ONE } from 'react-admin';

export const LOAD_ORDER_BY_ID = 'LOAD_ORDER_BY_ID';
export const REGISTER_ITEM = 'REGISTER_ITEM';

export const registerItem = ({ data, parentId, successCallback }) => ({
    type: REGISTER_ITEM,
    payload: { data, parentId },
    meta: {
        fetch: CREATE,
        resource: RESOURCES.EXCHANGE_ORDERS_ITEMS,
        onSuccess: {
            notification: {
                body: 'resources.exchange.notification.item_registered',
                level: 'info',
            },
            callback: () => { successCallback(); },
            refresh: true,
        },
    },
});

export const loadOrder = ({ id, basePath }) => ({
    type: LOAD_ORDER_BY_ID,
    payload: { id },
    meta: {
        fetch: GET_ONE,
        resource: RESOURCES.EXCHANGE,
        onSuccess: {
            redirectTo: `${RESOURCES.EXCHANGE}/${id}/view`,
            basePath,
        },
        onFailure: {
            notification: {
                body: 'resources.exchange.notification.fetch_failed',
                level: 'warning',
            },
        },
    },
});

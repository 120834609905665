import React, { Component } from 'react';
import { TextField } from 'react-admin';
import PropTypes from 'prop-types';

class TranslatedGridField extends Component {
    wordToTranslate = () => {
        const { record, source } = this.props;
        return record[source];
    };

    buildTranslationId = () => {
        const { pathPart } = this.props;
        return `${pathPart}.${this.wordToTranslate()}`;
    };

    isTranslationMissing = translatedField => this.buildTranslationId() === translatedField;

    render() {
        const {
            translate, pathPart, component, ...rest
        } = this.props;
        const termToTranslate = this.wordToTranslate();

        if (termToTranslate == null) {
            return '';
        }

        const translatedField = translate(this.buildTranslationId());
        if (this.isTranslationMissing(translatedField)) {
            return termToTranslate;
        }
        if (component) {
            const Component = component;
            return <Component {...rest} source="source" record={{ source: translatedField }} />;
        }
        return <TextField {...rest} source="source" record={{ source: translatedField }} />;
    }
}

TranslatedGridField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string,
    pathPart: PropTypes.string,
    translate: PropTypes.func,
};


export default TranslatedGridField;
